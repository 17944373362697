import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
// import { selectClient } from '../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Loader from '../components/loader/Loader'
import feather from "feather-icons/dist/feather";
// import { DataGrid, GridRowsProp, GridColDef, GridToolbar  } from '@mui/x-data-grid';
import { DataGrid, GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging,Export, Selection } from 'devextreme-react/data-grid';

import * as GridMainService from '../services/grid_main_dashboard'

import Select from "react-select";

export default function StoreNoLlegados() {

  const userData = useSelector(selectUser)
  const clientSelect = ''
  const [isLoading, setisLoading] = useState(false)
  const [dataGrid, SetDataGrid] = useState([])

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  // const columns = [
  //   <Column dataField: 'name', caption: 'Nombre', width: 150  />
  //   <Column dataField: 'address', caption: 'Dirección', width: 150  />
  //   <Column dataField: 'razon_social', caption: 'Razon Social', width: 150  />
  //   <Column dataField: 'cadena', caption: 'Cadena', width: 150  />
  //   <Column dataField: 'estado', caption: 'Estado', width: 150  />
  //   <Column dataField: 'ciudad', caption: 'Ciudad', width: 150  />
  //   <Column dataField: 'mun', caption: 'Municipio', width: 150  />
  //   <Column dataField: 'urb', caption: 'Urbanización', width: 150  />
  //   <Column dataField: 'nombre_gerente', caption: 'Nombre Gerente', width: 150  />
  //   <Column dataField: 'telefonos', caption: 'Telefonos', width: 150  />
  //   <Column dataField: 'delivery', caption: 'Delivery', width: 150  />
  //   <Column dataField: 'pago_movil', caption: 'Pago Movil', width: 150  />
  //   <Column dataField: 'paypal', caption: 'Paypal', width: 150  />
  //   <Column dataField: 'zelle', caption: 'Zelle', width: 150  />
  //   <Column dataField: 'carniceria', caption: 'Carniceria', width: 150  />
  //   <Column dataField: 'charcuteria', caption: 'Charcuteria', width: 150  />
  //   <Column dataField: 'farmacia', caption: 'Farmacia', width: 150  />
  //   <Column dataField: 'licoreria', caption: 'Licoreria', width: 150  />
  //   <Column dataField: 'panaderia', caption: 'Panaderia', width: 150  />
  //   <Column dataField: 'farmacia', caption: 'Farmacia', width: 150  />
  //   <Column dataField: 'pescaderia', caption: 'Pescaderia', width: 150  />
  //   <Column dataField: 'neveras', caption: 'Neveras', width: 150  />
  // ];

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  // this.onExporting = this.onExporting.bind(this);

  switchSkin(skin);

  useEffect(() => {
    GetDataGridStoreNoLlegados()

    switchSkin(skin);
  }, [skin, clientSelect]);

  const GetDataGridStoreNoLlegados = () => {
    console.log('en el get')
    setisLoading(true)

    let value = clientSelect == null ? userData.cliente[0] : clientSelect

    let info = { client_name: value }

    GridMainService.GetStoreNoLlegados(info).then(res =>{
      let data = res.data
      console.log("🚀 ~ file: StoreNoLlegados.js:82 ~ GridMainService.GetStoreNoLlegados ~ data:", data)
      
      SetDataGrid(data)
      setisLoading(false)

    }) 
  }

  function cellRenderNeveras(data) {
    // console.log(data)
    let valor = data.data.neveras
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderDelivery(data) {
    // console.log(data)
    let valor = data.data.delivery
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderPagoMovil(data) {
    // console.log(data)
    let valor = data.data.pago_movil
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderPaypal(data) {
    // console.log(data)
    let valor = data.data.paypal
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderZelle(data) {
    // console.log(data)
    let valor = data.data.zelle
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderCarniceria(data) {
    // console.log(data)
    let valor = data.data.carniceria
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderCharcuteria(data) {
    // console.log(data)
    let valor = data.data.charcuteria
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderFarmacia(data) {
    // console.log(data)
    let valor = data.data.farmacia
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderLicoreria(data) {
    // console.log(data)
    let valor = data.data.licoreria
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderPanaderia(data) {
    // console.log(data)
    let valor = data.data.panaderia
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

  function cellRenderPescaderia(data) {
    // console.log(data)
    let valor = data.data.pescaderia
    let icon
    if(valor == 'No'){
      icon = <div className="text-center"><i className="ri-close-line"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-check-line"></i></div>
    }
    return icon
  }

 function onExporting(e) {
  console.log(e)
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet('Main sheet');

    // exportDataGrid({
    //   component: e.component,
    //   worksheet,
    //   autoFilterEnabled: true,
    // }).then(() => {
    //   workbook.xlsx.writeBuffer().then((buffer) => {
    //     saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    //   });
    // });
  }

  return (
    <>
    {/* {isLoading && <Loader />} */}
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Establecimientos no Alcanzados</h4>
          </div>
        </div>

        <div className="height-grid-75"> 
          <DataGrid 
            dataSource={dataGrid}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            showRowLines={false}
            showBorders={true}
            rowAlternationEnabled={true}
            displayMode='full'
            style={{ height: '75vh', width: '100%' }}
            >
            {/* <Selection mode="multiple" /> */}
            <GroupPanel visible={true} />
            <ColumnChooser enabled={true} />
            <Scrolling rowRenderingMode='infinity'></Scrolling>
            <ColumnFixing enabled={true} />
            <HeaderFilter visible={true} />
            <Export enabled={true} />
            {/* <Pager
            visible={false}
            allowedPageSizes={false}
            displayMode={false}
            showPageSizeSelector={false}
            showInfo={false}
            showNavigationButtons={false} /> */}

            <Column dataField= 'name' caption= 'Nombre' width={150}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'address' caption= 'Dirección' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'razon_social' caption= 'Razon Social' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'cadena' caption= 'Cadena' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'estado' caption= 'Estado' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'ciudad' caption= 'Ciudad' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'mun' caption= 'Municipio' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'urb' caption= 'Urbanización' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'nombre_gerente' caption= 'Nombre Gerente' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'telefonos' caption= 'Telefonos' width={75}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'delivery' caption= 'Delivery' width={75}  cellRender={cellRenderDelivery}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'pago_movil' caption= 'Pago Movil' width={75}  cellRender={cellRenderPagoMovil}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'paypal' caption= 'Paypal' width={75}  cellRender={cellRenderPaypal}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'zelle' caption= 'Zelle' width={75}  cellRender={cellRenderZelle}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'carniceria' caption= 'Carniceria' width={75}  cellRender={cellRenderCarniceria}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'charcuteria' caption= 'Charcuteria' width={75}  cellRender={cellRenderCharcuteria}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'farmacia' caption= 'Farmacia' width={75}  cellRender={cellRenderFarmacia}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'licoreria' caption= 'Licoreria' width={75}  cellRender={cellRenderLicoreria}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'panaderia' caption= 'Panaderia' width={75}  cellRender={cellRenderPanaderia}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'pescaderia' caption= 'Pescaderia' width={75}  cellRender={cellRenderPescaderia}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'NEVERAS' caption= 'Neveras' width={75} cellRender={cellRenderNeveras}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
          </DataGrid>
        </div>
        {/* <div style={{ height: 600, width: '100%' }}> */}
          {/* <DataGrid rows={dataGrid} columns={columns} localeText={{
            toolbarDensity: 'Size',
            toolbarDensityLabel: 'Size',
            toolbarDensityCompact: 'Small',
            toolbarDensityStandard: 'Medium',
            toolbarDensityComfortable: 'Large',
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          pagination={false} /> */}
        {/* </div> */}

        <Footer />
      </div>
    </React.Fragment>
    </>
  )
}