import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isFilter: false,
    ids_trans: null
}

const dashEncuestasSlice = createSlice({
    name: "dashEncuestas",
    initialState,
    reducers: {
        SET_DATA_FILTER: (state, action) => {
            const { isFilter, ids_trans } = action.payload;
            state.isFilter = isFilter;
            state.ids_trans = ids_trans;
        },
        REMOVE_DATA_FILTER_DASH: (state, action) => {
            state.isFilter = null
            state.ids_trans = null
        }
    }
});

export const { SET_DATA_FILTER, REMOVE_DATA_FILTER_DASH } = dashEncuestasSlice.actions;

export const selectFiltersDashEncuestas = (state) => state.dashEncuestas;

export default dashEncuestasSlice.reducer;