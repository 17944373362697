import React, { useEffect, useState, useCallback, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Row, Col, Nav, ListGroup, Table, ProgressBar, Dropdown, } from "react-bootstrap";
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import ReactApexChart from "react-apexcharts";
import { dp1, dp2, dp3 } from "../data/DashboardData";
import { Doughnut, Bar, getDatasetAtEvent  } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import ReactDatePicker from "react-datepicker";
import { SET_DATA_FILTER, REMOVE_DATA_FILTER, selectFiltersGrid } from '../redux/slice/gridEncuestasSlice';
import { selectFiltersDashEncuestas, REMOVE_DATA_FILTER_DASH } from '../redux/slice/dashEncuestas'
import { selectReportsAuto } from '../redux/slice/reportsAutoSlice';
import moment from "moment";
import { selectFilters } from '../redux/slice/configSlice';
import { selectUser } from '../redux/slice/userSlice'
import html2canvas from 'html2canvas';

import * as QuizDashboardService from '../services/quiz_dashboard'

import crypto1 from "../assets/img/crypto1.jpg";
import crypto2 from "../assets/img/crypto2.jpg";
import crypto3 from "../assets/img/crypto3.jpg";

import "cryptofont/css/cryptofont.css";

import '../style.css'

export default function QuizDashboard() {

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  const seriesTwo = [{
    name: 'series1',
    data: dp2
  }, {
    name: 'series2',
    data: dp1
  }];

  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      sparkline: { enabled: true }
    },
    colors: ['#85b6ff', '#506fd9'],
    grid: {
      borderColor: 'rgba(72, 94, 144, .1)',
      padding: {
        top: -20,
        left: 0
      },
      yaxis: { lines: { show: false } }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      type: 'numeric',
      min: 5,
      max: 90,
      tickAmount: 6,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    },
    yaxis: { max: 180 },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chartRef = useRef();
  const paramsReportsAuto = useSelector(selectReportsAuto); 
  const dashFilters = useSelector(selectFiltersDashEncuestas)
  const gridFilters = useSelector(selectFiltersGrid)
  const filtersSelect = useSelector(selectFilters);
  const userData = useSelector(selectUser)


  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);
  
  const [ListClients, SetListClients] = useState([]);
  const [ListLabels, SetListLabels] = useState({});
  const [IDClient, SetIDClient] = useState(null);
  const [QuizXRegion, SetQuizXRegion] = useState([]);
  const [MarcaConoce, SetMarcaConoce] = useState([]);
  const [OtrasMenciones, SetOtrasMenciones] = useState([]);
  const [DataTotalMenciones, SetDataTotalMenciones] = useState([]);
  const [DataPie, SetDataPie] = useState({});
  const [TotalesMenciones, SetTotalesMenciones] = useState({ totalMarcas: 0, totalMenciones: 0 });
  const [DataPieReady, SetDataPieReady] = useState(false);
  const [PorquePrefiereAll, SetPorquePrefiereAll] = useState([]);
  const [PorquePrefiere, SetPorquePrefiere] = useState([]);
  const [PorquePrefiereTop, SetPorquePrefiereTop] = useState({
    RESPUESTA: '',
    TOTAL_OPTION: '',
    TOTAL: '',
    PORCENTAJE: '',
    color: ''
  });
  const [DisponibleStore, SetDisponibleStore] = useState([]);
  const [ValoresDisponibles, SetValoresDisponibles] = useState([0, 0]);
  const [OptionsDisponible, SetOptionsDisponible] = useState({});
  const [DisponibleStoreReady, SetDisponibleStoreReady] = useState(false);
  const [QueHacer, SetQueHacer] = useState([]);
  const [UltimaCompra, SetUltimaCompra] = useState([]);
  const [DataBar, SetDataBar] = useState({});
  const [DataFrecuenciaCompra, SetDataFrecuenciaCompra] = useState({});
  const [OptionsFrecuenciaCompra, SetOptionsFrecuenciaCompra] = useState({});
  const [DataBarReady, SetDataBarReady] = useState(false);
  const [DataAllMarcaFavo, SetDataAllMarcaFavo] = useState();
  const [DataMarcaFavo, SetDataMarcaFavo] = useState({});
  const [OptionsMarcaFavo, SetOptionsMarcaFavo] = useState({});
  const [MarcaFavoReady, SetMarcaFavoReady] = useState(false);
  const [valueReports, setValueReports] = useState(false);
  let sendEmail = false
  const [DashboardEnable, SetDashboardEnable] = useState(true);
  

  useEffect(() => {
    //console.log("🚀 ~ useEffect ~ useEffect:1")
    //console.log("🚀 ~ useEffect ~ paramsReportsAuto:", paramsReportsAuto)
    //console.log("🚀 ~ useEffect ~ paramsReportsAuto:", IDClient)
    if(paramsReportsAuto && sendEmail == false || paramsReportsAuto != IDClient){

      let fecha = moment(startDate);
      let semana = fecha.week();
      let ano = fecha.year();
      let inicio = moment().year(ano).week(semana).startOf('week');
      let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a');
      let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
      let fecha_newDate = new Date(
        anio,
        mes - 1,
        dia,
        hora % 12 + (ampm.toLowerCase() === 'pm' ? 12 : 0),
        minuto
      );

      setStartDate(new Date(fecha_newDate));

      SetIDClient(paramsReportsAuto)
      sendEmail = true

      setTimeout(() => {
        handleCaptureClick()
      }, 30000);
    }
  }, [paramsReportsAuto]);

  useEffect(() => {
    //console.log("🚀 ~ useEffect ~ useEffect:2")
    switchSkin(skin);
    if(userData?.cliente.length > 0){
      GetListClients()
    }
  }, [skin, userData]);

  useEffect(() => {
    //console.log("🚀 ~ useEffect ~ useEffect:3")
    //console.log("🚀 ~ useEffect ~ dashFilters:", dashFilters)
    if(dashFilters?.isFilter == true){
      setStartDate(new Date(gridFilters?.fecha_ini))
      setEndDate(new Date(gridFilters?.fecha_fin))
      SetIDClient(gridFilters?.id_client)
    } else {
      SearchDate()
    }
  }, [dashFilters]);
  
  useEffect(() => {
    //console.log("🚀 ~ useEffect ~ useEffect:4")
    if(IDClient != null){
      GetLabels()
      SearchDate()
    }
  }, [IDClient]);

  useEffect(() => {
    //console.log("🚀 ~ useEffect ~ useEffect:5")
    GetTotalMenciones();
  }, [MarcaConoce, OtrasMenciones]);

  const GetLabels = () => {

    let info = {
      id_client: IDClient,
    }

    QuizDashboardService.GetLabels(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []
      let data_final = {}
      const entries = Object.entries(data);
      entries.forEach(([key, value]) => {
        data_final[value['TAG']] = value['QUESTION']
      });
      
      SetListLabels(data_final)
    })



  }

  const GetListClients = () => {
    // console.clear()
    QuizDashboardService.GetListClients().then(res => {
      let data = res.data?.length > 0 ? res.data : []

      let list_clients_user = userData.cliente
      let data_clients = []

      list_clients_user.forEach(item => {
        let valor = data.filter(x => x.text == item)
        if (valor.length > 0) data_clients.push(valor[0])
      })

      if (data_clients.length == 1) {
        SetIDClient(data_clients[0].value)
      } else if (data_clients.length == 0){
        SetDashboardEnable(false)
      }

      SetListClients(data_clients)
    })
  }

  const SetPeriodoFecha = (type) => {
    switch (type) {
      case 1:
        setStartDate(new Date())
        setEndDate(new Date())
        // dispatch(SET_DATE({
        //   startDate: new Date(),
        //   endDate: new Date()
        // }))
        break;
      case 2:
        let fecha = moment(new Date())
        let semana = fecha.week()
        let ano = fecha.year()
        let inicio = moment().year(ano).week(semana).startOf('week')
        let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a')
        let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
        let fecha_newDate = new Date(anio, mes - 1, dia, hora % 12 + (ampm.toLowerCase() === "pm" ? 12 : 0), minuto);
        setStartDate(fecha_newDate)
        setEndDate(new Date())
        // dispatch(SET_DATE({
        //   startDate: fecha_newDate,
        //   endDate: new Date()
        // }))
        break;
      case 3:
        let fecha2 = moment(new Date())
        let mes2 = fecha2.month()
        let ano2 = fecha2.year()
        let inicio2 = moment().year(ano2).month(mes2).startOf('month')
        let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
        let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
        let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
        setStartDate(fecha_newDate2)
        setEndDate(new Date())
        // dispatch(SET_DATE({
        //   startDate: fecha_newDate2,
        //   endDate: new Date()
        // }))
        break;
    }
    SearchDate()
  }

  const SearchDate = () => {
    GetQuizXRegion();
    GetMarcaConoce();
    GetOtrasMenciones();
    GetPorquePrefiere();
    GetDisponibleStore();
    GetQueHacer();
    GetUltimaCompra();
    GetFrecuenciaCompra();
    GetMarcaFavo();
  }

  const GetQuizXRegion = () => {
    // let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;

    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }
    //console.log("🚀 ~ GetQuizXRegion ~ info:", info)

    QuizDashboardService.GetQuizXRegion(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []
      SetQuizXRegion(data)
    })
  }

  const GetMarcaConoce = () => {
    // let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;

    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }

    QuizDashboardService.GetMarcaConoce(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []

      let dataPie = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: []
        }]
      };

      if (data.length > 0) {
        data.forEach(item => {
          item.color = generarColorHexadecimal()
          dataPie.labels.push(item.RESPUESTA)
          dataPie.datasets[0].data.push(item.PORCENTAJE)
          dataPie.datasets[0].backgroundColor.push(item.color)
        })

        SetTotalesMenciones(prevState => ({
          ...prevState,
          totalMarcas: parseInt(data[0].TOTAL)
        }));

        SetMarcaConoce(data)
        SetDataPie(dataPie)
        SetDataPieReady(true)

      }
    })
  }

  const GetOtrasMenciones = () => {
    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }

    QuizDashboardService.GetOtrasMenciones(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []

      let menciones = []

      data.forEach(item => {
        if (item.RESPUESTA != 'NINGÚN OTRO' && item.RESPUESTA.includes(';')) {
          let opciones = item.RESPUESTA.split(';')
          menciones.push(...opciones)
        } else {
          menciones.push(item.RESPUESTA)
        }
      })

      let items = countStrings(menciones)
      let total = items.total
      let data_final = []

      const entries = Object.entries(items.data);
      entries.forEach(([key, value]) => {
        let info = { RESPUESTA: key, TOTAL_OPTION: value, TOTAL: total, PORCENTAJE: (value * 100.0 / total).toFixed(2) }
        data_final.push(info)
      });

      SetTotalesMenciones(prevState => ({
        ...prevState,
        totalMenciones: total
      }));

      SetOtrasMenciones(data_final)

    })
  }

  const GetTotalMenciones = () => {
    let total_final = TotalesMenciones.totalMarcas + TotalesMenciones.totalMenciones
    let dataFull = [...MarcaConoce, ...OtrasMenciones]
    let data_limpia = {}
    let data = []

    dataFull.forEach(item => {
      if (data_limpia[item.RESPUESTA]) {
        data_limpia[item.RESPUESTA] = parseInt(data_limpia[item.RESPUESTA]) + parseInt(item.TOTAL_OPTION);
      } else {
        data_limpia[item.RESPUESTA] = parseInt(item.TOTAL_OPTION);
      }
    })

    const entries = Object.entries(data_limpia);
    entries.forEach(([key, value]) => {
      let info = { RESPUESTA: key, TOTAL_OPTION: value, TOTAL: total_final, PORCENTAJE: (value * 100.0 / total_final).toFixed(2) }
      data.push(info)
    });

    data.sort((a, b) => {
      if (a['TOTAL_OPTION'] > b['TOTAL_OPTION']) {
        return -1;
      }
      if (a['TOTAL_OPTION'] < b['TOTAL_OPTION']) {
        return 1;
      }
      return 0;
    });

    SetDataTotalMenciones(data)
  }

  const GetPorquePrefiere = () => {
    // let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;

    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }

    QuizDashboardService.GetPorquePrefiere(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []

      let data_2 = []
      if (data.length > 0) {
        data.forEach((item, index) => {
          item.color = generarColorHexadecimal()
          if (index == 0) {
            SetPorquePrefiereTop(item)
          } else {
            data_2.push(item)
          }
        })
        SetPorquePrefiereAll(data)
        SetPorquePrefiere(data_2)
      }
    })
  }

  const GetDisponibleStore = () => {
    // let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;

    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }
    //console.log("🚀 ~ GetDisponibleStore ~ info:", info)

    QuizDashboardService.GetDisponibleStore(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []
      //console.log("🚀 ~ QuizDashboardService.GetDisponibleStore ~ data:", data)
      
      if (data.length > 0) {

        let optionDonut1 = {
          chart: {
            parentHeightOffset: 0,
            events: {
              dataPointSelection: (event, chartContext, config) => {
                let item = config?.selectedDataPoints[0]
                SelectOption(data[item[0]])
              },
            }
          },
          colors: ['#506fd9', '#e5e9f2'],
          dataLabels: { enabled: false },
          legend: { show: false }
        };
  
        SetDisponibleStore(data)
        SetOptionsDisponible(optionDonut1)

        let valores = [parseFloat(data[0]?.PORCENTAJE), parseFloat(data[1]?.PORCENTAJE)]
        SetValoresDisponibles(valores)
      }
      SetDisponibleStoreReady(true)

    })
  }

  const GetQueHacer = () => {
    // let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;

    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }

    QuizDashboardService.GetQueHacer(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []
      SetQueHacer(data)

    })
  }

  const GetUltimaCompra = () => {
    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }

    QuizDashboardService.GetUltimaCompra(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []

      SetUltimaCompra(data)

    })
  }

  const GetFrecuenciaCompra = () => {
    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }

    QuizDashboardService.GetFrecuenciaCompra(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []

      let labels = []
      let valores = []

      let maximo = 0
      data.forEach(item => {
        maximo = parseFloat(item.TOTAL_OPTION) > maximo ? parseFloat(item.TOTAL_OPTION) : maximo
        labels.push(item.RESPUESTA)
        valores.push(parseFloat(item.TOTAL_OPTION))
      })

      maximo += Math.round(maximo * 0.10);
      
      let optionBar = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            max: maximo,
            grid: {
              borderColor: '#e2e5ec',
              color: '#f3f5f9'
            },
            ticks: {
              font: {
                size: 11
              }
            }
          },
          y: {
            grid: {
              borderWidth: 0,
              color: '#f3f5f9'
            },
            ticks: {
              color: '#212830',
              font: {
                size: 14
              }
            }
          }
        }
      };

      let dataBar = {
        labels: labels,
        datasets: [{
          data: valores,
          backgroundColor: '#506fd9',
          barPercentage: 0.45
        }]
      };

      SetOptionsFrecuenciaCompra(optionBar)
      SetDataFrecuenciaCompra(data)
      SetDataBar(dataBar)
      SetDataBarReady(true)

    })
  }

  const GetMarcaFavo = () => {
    let info = {
      id_client: IDClient,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
      filters_ids: dashFilters?.isFilter == null ? false : dashFilters?.isFilter,
      ids_trans: dashFilters?.isFilter == true ? dashFilters?.ids_trans : []
    }

    QuizDashboardService.GetMarcaFavo(info).then(res => {
      let data = res.data?.length > 0 ? res.data : []

      let seriesPie3 = [];
      let optionPie3 = {
        labels: [],
        chart: {
          width: 200,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              let item = config?.selectedDataPoints[0]
              SelectOption(data[item[0]])
            },
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom"
            }
          }
        }]
      };

      if (data.length > 0) {
        data.forEach(item => {
          optionPie3.labels.push(item.RESPUESTA)
          seriesPie3.push(parseFloat(item.PORCENTAJE))
        })

        SetOptionsMarcaFavo(optionPie3)
        SetDataMarcaFavo(seriesPie3)
        SetDataAllMarcaFavo(data)
        SetMarcaFavoReady(true)

      }
    })
  }

  const countStrings = (strings) => {
    const result = {};
    let total = 0
    strings.forEach(str => {
      total++
      const trimmedStr = str.trim();
      if (result[trimmedStr]) {
        result[trimmedStr]++;
      } else {
        result[trimmedStr] = 1;
      }
    });

    const sortedResult = Object.entries(result).sort((a, b) => b[1] - a[1]);
    const sortedObject = Object.fromEntries(sortedResult);
    let resultado = { data: sortedObject, total: total }
    return resultado;
  }

  const generarColorHexadecimal = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    const colorHex = `#${componenteHexadecimal(r)}${componenteHexadecimal(g)}${componenteHexadecimal(b)}`;

    return colorHex;
  }

  const componenteHexadecimal = (componente) => {
    const hexadecimal = componente.toString(16).toUpperCase();
    return hexadecimal.length === 1 ? "0" + hexadecimal : hexadecimal;
  }

  const SwitchClient = useCallback((e) => {
    const client = e.value;
    SetIDClient(client)
  }, []);

  const SelectOption = (option) => {
    // console.clear()
    if(!dashFilters?.isFilter) {
      //console.log("🚀 ~ SelectOption ~ option:", option)
  
      let info = {
        id_client: IDClient,
        fecha_ini: moment(startDate).format('YYYY/MM/DD'),
        fecha_fin: moment(endDate).format('YYYY/MM/DD'),
        tag: option.TAG,
        pregunta: option.PREGUNTA,
        respuesta: option.RESPUESTA
      }
  
      //console.log(info)
  
      dispatch(SET_DATA_FILTER(info))
  
      navigate(`/Grids/Encuestas`)
    }

  }

  const onClick = (event) => {
    const chart = chartRef.current;
    if (!chart) return;

    const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const label = chart.data.labels[firstPoint.index];
      const value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      let item = DataFrecuenciaCompra.filter(x => x.RESPUESTA == label)[0]
      SelectOption(item)
    }
  }

  const ClearFilters = () => {
    dispatch(REMOVE_DATA_FILTER())
    dispatch(REMOVE_DATA_FILTER_DASH())
  }

  const handleCaptureClick = async () => {
    // const element = document.documentElement;
    const element = document.getElementById('element_main');
    const canvas = await html2canvas(element, {
      scrollY: -window.scrollY,
    });

    const dataUrl = canvas.toDataURL('image/png');
    //console.log(dataUrl);

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client

    let fecha2 = moment(new Date())
    let mes2 = fecha2.month()
    let inicio2 = moment().year(fecha2.year()).month(mes2).startOf('month')
    let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
    let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
    let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);

    let info = {
      client_name: client,
      title: `Capture Analitycs Encuestas. Desde: ${moment(fecha_newDate2).format('YYYY/MM/DD')} hasta: ${moment(new Date()).format('YYYY/MM/DD')}`,
      fecha_send: moment().format('YYYY/MM/DD'),
      fecha_ini: moment(fecha_newDate2).format('YYYY/MM/DD'),
      fecha_fin: moment(new Date()).format('YYYY/MM/DD'),
      type: 'Quiz',
    }

    QuizDashboardService.SaveBase64(info).then(res => {
      //console.log(res)
    })

  }

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className={DashboardEnable ? '' : 'disabled'}>
        <div className="main main-app p-3 p-lg-4"> 
        <div className="d-flex d-sm-flex align-items-center justify-content-between mb-4">
          <div className="col-3">
            <ol className="breadcrumb fs-sm mb-1">
              <li id="example-element-id" className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Encuestas Dasboard</li>
            </ol>
            <h4 className="main-title mb-0">Bienvenido al Dashboard Principal</h4>
          </div>

          <div className="row d-sm-flex col-9 d-flex align-items-center gap-2 mt-3 mt-md-0">
            <div className="row d-sm-flex align-items-center justify-content-between mb-4">
              <div className="col-3">
                {ListClients.length > 1 &&
                  <SelectBox
                    items={ListClients}
                    displayExpr="text"
                    valueExpr="value"
                    value={IDClient}
                    onValueChanged={SwitchClient} />
                }
              </div>
              <div className="d-flex col-4">
                <div style={{ marginRight: '2%' }}>
                  <ReactDatePicker selected={startDate} onChange={(date) => { setStartDate(date) }} className="form-control"  />
                </div>
                <div style={{ marginLeft: '2%' }}>
                  <ReactDatePicker selected={endDate} onChange={(date) => { setEndDate(date) }} className="form-control" />
                </div>
              </div>
              <div className="d-flex col-5">
                <div style={{ marginRight: '2%' }}>
                  <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => SearchDate()}>
                    <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
                  </Button>
                </div>
                <div style={{ marginLeft: '2%', marginRight: '2%'  }}>
                  <Dropdown >
                  <Dropdown.Toggle variant="primary">
                    Filtro Fecha
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Header>Rango de Fecha</Dropdown.Header>
                    <Dropdown.Item onClick={(e) => SetPeriodoFecha(1)}>Hoy</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => SetPeriodoFecha(2)}>Ultima Semana</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => SetPeriodoFecha(3)}>Ultimo Mes</Dropdown.Item>
                  </Dropdown.Menu>
                  </Dropdown>
                </div>
                {dashFilters?.isFilter &&
                <div style={{ marginLeft: '2%' }}>
                  <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => ClearFilters()}>
                    <i className="ri-arrow-go-back-fill fs-18 lh-1"></i>Reset Data<span className="d-none d-sm-inline"></span>
                  </Button>
                </div>
                }
              </div>
            </div>
          </div>
        </div>

        <Row className="g-3" id="element_main">

          <Col xl="12">
            <Card className="card-one">
              <Card.Header className="border-0 pb-2">
                <Card.Title as="h6">{ListLabels?.titulo1}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="pt-0">
                <p className="fs-sm text-secondary mb-4">{ListLabels?.titulo2}</p>

                <ProgressBar className="progress-finance mb-4">
                  {QuizXRegion.map((item, index) => (
                    <ProgressBar now={item.PORCENTAJE} label={item.PORCENTAJE + '%; ' + '(' + item.TOTAL_POR_REGION + ')'} />
                  ))}
                </ProgressBar>

                <Row className="g-3">
                  {QuizXRegion.map((item, index) => (
                    <Col>
                      <label className="card-label fs-sm fw-medium mb-1">{item.REGION}</label>
                      <h2 className="card-value mb-0">{item.PORCENTAJE + '%'}</h2>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{ListLabels?.marcaPrimera} TOM (Top Of Mind)</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="chart-donut-one mb-3">
                  {DataPieReady &&
                    <Doughnut data={DataPie} options={optionPie} />
                  }
                </div>

                <Table className="table-five">
                  <tbody>
                    {MarcaConoce.map((item, index) => (
                      <tr key={index} >
                        <td>
                          <div><span style={{ backgroundColor: item.color }} className={"badge-dot"}></span> <span className="fw-medium">{item.RESPUESTA}</span></div>
                        </td>
                        <td>{item.TOTAL_OPTION}</td>
                        <td>
                          <div className={"justify-content-end text-" + (item.PORCENTAJE > 10 ? "success" : "danger")}>{item.PORCENTAJE}
                          </div>
                        </td>
                        {!dashFilters?.isFilter &&
                          <td onClick={() => SelectOption(item)}><a style={{ cursor: 'pointer' }} ><i class="ri-grid-line"></i></a></td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{ListLabels?.marcaOtros}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <ListGroup as="ul" className="list-group-one">
                  {OtrasMenciones.map((item, index) => (
                    <ListGroup.Item key={index} as="li" className="px-0 d-flex align-items-center gap-2">
                      <div>
                        <h6 className="mb-0">{item.RESPUESTA}</h6>
                      </div>
                      <div className="ms-auto text-end">
                        <h6 className="ff-numerals mb-0">{item.TOTAL_OPTION}</h6>
                        <small className={"text-" + (item.PORCENTAJE > 10 ? "success" : "danger")}>{item.PORCENTAJE}%</small>
                      </div>
                      {!dashFilters?.isFilter &&
                      <div>
                        <a onClick={() => SelectOption(item)} style={{ cursor: 'pointer' }} ><i class="ri-grid-line"></i></a>
                      </div>
                      }  
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Total Menciones</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="position-absolute p-1">
                  <label className="fw-medium fs-sm mb-1">Cantidad total de menciones</label>
                  <h3 className="card-value"><span className="text-secondary"></span> {TotalesMenciones.totalMarcas + TotalesMenciones.totalMenciones} </h3>
                </div>

                <ReactApexChart series={seriesTwo} options={optionTwo} type="area" width={480} height={195} className="apex-chart-ten mb-4" />

                <ListGroup as="ul" className="list-group-one">
                  {DataTotalMenciones.map((item, index) => (
                    <ListGroup.Item key={index} as="li" className="px-0 d-flex align-items-center gap-2">
                      <div className="avatar bg-gray-300 text-secondary">
                      </div>
                      <div>
                        <h6 className="mb-0">{item.RESPUESTA}</h6>
                      </div>
                      <div className="ms-auto text-end">
                        <h6 className="ff-numerals mb-0">{item.PORCENTAJE}%</h6>
                        <small className="text-secondary">{item.TOTAL_OPTION}</small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>

          <Col md="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{ListLabels?.marcaPreferencia}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="chartjs-one">
                  {MarcaFavoReady &&
                    <ReactApexChart series={DataMarcaFavo} options={OptionsMarcaFavo} type="pie" width="100%" height={300} />
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md="5">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{ListLabels?.razonPreferencia}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                <h2 className="performance-value mb-0">{PorquePrefiereTop.TOTAL_OPTION} <h4 style={{ marginLeft: '5%' }} className="text-success d-flex align-items-center"> {PorquePrefiereTop.PORCENTAJE}%</h4></h2>

                <h5 className="card-title fs-md fw-medium">{PorquePrefiereTop['RESPUESTA']}</h5>


                <ProgressBar className="progress-one ht-8 mt-2 mb-4">
                  {PorquePrefiereAll.map((item, index) => (
                    <ProgressBar now={parseInt(item.PORCENTAJE)} style={{ backgroundColor: item.color }} />
                  ))}
                </ProgressBar>

                <Table className="table-three">
                  <tbody>
                    {PorquePrefiere.map((item, index) => (
                      <tr key={index}>
                        <td><div className={"badge-dot"} style={{ backgroundColor: item.color }}></div></td>
                        <td>{item.RESPUESTA}</td>
                        <td>{item.TOTAL_OPTION}</td>
                        <td>{item.PORCENTAJE}%</td>
                        {!dashFilters?.isFilter &&
                        <td onClick={() => SelectOption(item)}><a style={{ cursor: 'pointer' }} ><i class="ri-grid-line"></i></a></td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table>

              </Card.Body>
            </Card>
          </Col>

          <Col md="3">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{ListLabels?.disponibilidad}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>

              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col style={{ margin: 'auto', marginTop: '20%' }}>
                    <div className="apex-donut-one">
                      {DisponibleStoreReady &&
                        <ReactApexChart series={ValoresDisponibles} options={OptionsDisponible} width={350} height={200} type="donut" />
                      }
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">{DisponibleStore[0]?.PORCENTAJE}%</h4>
                        <h5 className="text-secondary">{DisponibleStore[0]?.RESPUESTA}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col style={{ marginTop: '25%' }}>
                    <h4 className="card-value mb-6">Base: {DisponibleStore[0]?.TOTAL}</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md="3">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">¿Qué suele hacer?</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                <label className="d-block fs-sm mb-2">
                  <span className="ff-numerals">{ListLabels?.disponibilidadContingencia}</span>
                </label>
                <ProgressBar className="ht-15 mb-4">
                  {QueHacer.map((item, index) => (
                    <ProgressBar now={parseFloat(item.PORCENTAJE)} />
                  ))}
                </ProgressBar>
                {QueHacer.map((item, index) => (
                  <div className="storage-item" key={index}>
                    <div className="flex-fill">
                      {/* <div className="d-flex justify-content-between"> */}
                        <div className="d-flex justify-content-between mb-1">
                          <span className="fw-medium">{item.RESPUESTA}</span>
                          <span className="ff-numerals">{item.PORCENTAJE}% 
                          {!dashFilters?.isFilter &&  <a onClick={() => SelectOption(item)} style={{ cursor: 'pointer' }} ><i class="ri-grid-line"></i></a> }
                          </span>
                        </div>
                      <ProgressBar now={parseFloat(item.PORCENTAJE)} className="ht-5 mb-1" />
                      <div className="d-flex justify-content-between ff-numerals fs-xs text-secondary">
                        <span>{item.TOTAL_OPTION}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col>

          <Col md="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{ListLabels?.frecuenciaCompra}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="chartjs-three mt-1">
                  {DataBarReady &&
                    <Bar ref={chartRef} data={DataBar} options={OptionsFrecuenciaCompra} onClick={onClick} />
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md="3">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{ListLabels?.ultimaCompra}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Table className="table-one" responsive>
                  <thead>
                    <tr>
                      <th>Respuesta</th>
                      <th>Cantidad</th>
                      <th>%</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {UltimaCompra.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center fw-medium">
                            {item.RESPUESTA}
                          </div>
                        </td>
                        <td>{item.TOTAL_OPTION}</td>
                        <td>{item.PORCENTAJE}%</td>
                        {!dashFilters?.isFilter &&
                        <td onClick={() => SelectOption(item)}><a style={{ cursor: 'pointer' }} ><i class="ri-grid-line"></i></a></td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

        </Row>

        <Footer />
        </div>
      </div>
    </React.Fragment>
  )
}