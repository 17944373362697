import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isFilter: false,
    fomularios: null,
    desde: null,
    hasta: null,
    regions: null,
    usuarios: null,
    establecimientos: null,
    etiquetas: 'TODOS'
}

const gridSincronizacionesSlice = createSlice({
    name: "grid_sincronizaciones",
    initialState,
    reducers: {
        SET_DATA_FILTER: (state, action) => {
            const {fomularios, desde, hasta, regions, usuarios, establecimientos, etiquetas} = action.payload;
            state.fomularios = fomularios;
            state.desde = desde;
            state.hasta = hasta;
            state.regions = regions;
            state.usuarios = usuarios;
            state.establecimientos = establecimientos;
            state.etiquetas = 'TODOS';
            state.isFilter = true;
        },
        REMOVE_DATA_FILTER: (state, action) => {
            state.isFilter = false
            state.fomularios = null
            state.desde = null
            state.hasta = null
            state.regions = null
            state.usuarios = null
            state.establecimientos = null
            state.etiquetas = null
        }
    }
});

export const { SET_DATA_FILTER, REMOVE_DATA_FILTER } = gridSincronizacionesSlice.actions;

export const selectFiltersGrid = (state) => state.grid_sincronizaciones;

export default gridSincronizacionesSlice.reducer;