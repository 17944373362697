import React, { useCallback, useRef, useEffect, useState } from "react";
import Header from "../layouts/Header";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
import Button2 from 'devextreme-react/button';
import { selectUser } from '../redux/slice/userSlice'
import { selectFiltersGrid } from '../redux/slice/gridEncuestasSlice'
import { selectFilters } from '../redux/slice/configSlice'
import moment from "moment";
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import { DataGrid, GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Export, Toolbar, Item, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';

import * as QuizDashboardService from '../services/quiz_dashboard'

import Select from "react-select";

import { SET_DATA_FILTER, REMOVE_DATA_FILTER } from '../redux/slice/dashEncuestas';
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from "../redux/slice/dateSlice";

export default function GridEncuestas() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(selectUser)
  const clientSelect = ''
  const [isLoading, setisLoading] = useState(false)
  const [dataGrid, SetDataGrid] = useState([])
  const dataGridRef = useRef(null);
  const filters = useSelector(selectFiltersGrid)
  const filtersSelect = useSelector(selectFilters)
  const globalDate = useSelector(selectDate);
  const [startDate, setStartDate] = useState(new Date(globalDate.startDate));
  const [endDate, setEndDate] = useState(new Date(globalDate.endDate));
  const [groupColumn, setGroupColumn] = useState('');
  const [expandAll, setExpandAll] = useState(true);
  const [filtersGrid, SetFiltersGrid] = useState([])
  const groupingValues = [{
    value: 'REGION',
    text: 'Agrupar por Region',
  }, {
    value: 'ESTABLECIMIENTO',
    text: 'Agrupar por Establecimiento',
  }];
  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  
  
  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    let filters_ = []
    SetFiltersGrid(filters_)
    
    GetDataGridSku()

    switchSkin(skin);
  }, [filters]);

  const GetDataGridSku = () => {
    QuizDashboardService.DataGridByTagAndAnswer(filters).then(resp => {
      let data = resp.data
      SetDataGrid(data)
    })
  }

  const toggleGroupColumn = useCallback((e) => {
    const newGrouping = e.value;

    dataGridRef.current.instance.clearGrouping();
    dataGridRef.current.instance.columnOption(newGrouping, 'groupIndex', 0);

    setGroupColumn(newGrouping);
  }, []);

  const toggleExpandAll = useCallback(() => {
    setExpandAll(!expandAll);
  }, [expandAll]);

 function onExporting(e) {
  console.log(e);  
  }

  const SetPeriodoFecha = (type) => {
    switch (type) {
      case 1:

        setStartDate(new Date())
        setEndDate(new Date())

        break;
      case 2:
        
        let fecha = moment(new Date())
        let semana = fecha.week()
        let ano = fecha.year()
        let inicio = moment().year(ano).week(semana).startOf('week')
        let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a')
        let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
        let fecha_newDate = new Date(anio, mes - 1, dia, hora % 12 + (ampm.toLowerCase() === "pm" ? 12 : 0), minuto);
        setStartDate(fecha_newDate)
        setEndDate(new Date())

        break;
      case 3:
        
        let fecha2 = moment(new Date())
        let mes2 = fecha2.month()
        let ano2 = fecha2.year()
        let inicio2 = moment().year(ano2).month(mes2).startOf('month')
        let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
        let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
        let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
        setStartDate(fecha_newDate2)
        setEndDate(new Date())

        break;
      default:
         
        setStartDate(new Date())
        setEndDate(new Date())

        break;
    }
    GetDataGridSku()
  }

  const GoToDashboard = () => {
    console.log(dataGrid)

    let ids = dataGrid.map(x => { return x.ID})
    console.log("🚀 ~ GoToDashboard ~ ids:", ids)

    let info = {
      isFilter: true,
      ids_trans: ids
    }

    console.log("🚀 ~ GoToDashboard ~ info:", info)
    
    dispatch(SET_DATA_FILTER(info))

    navigate(`/dashboard/quizDashboard`)

  }

  return (
    <>
    {/* {isLoading && <Loader />} */}
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">{filters?.pregunta}</h4>
          </div>
          
        </div>
        
        <div className="height-grid-75"> 
        <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={dataGrid}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            showRowLines={true}
            showBorders={true}
            rowAlternationEnabled={true}
            displayMode='full'
            style={{ height: '75vh', width: '100%' }}
            >
            {/* filterValue={filtersGrid} */}
            {/* <Selection mode="multiple" /> */}
            <Grouping autoExpandAll={expandAll} />
            <GroupPanel visible={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <Scrolling rowRenderingMode='infinity'></Scrolling>
            <ColumnFixing enabled={true} />
            <HeaderFilter visible={true} />
            <Export enabled={true} />

            <Toolbar>
              <Item location="after">
                <SelectBox
                  width="225"
                  items={groupingValues}
                  displayExpr="text"
                  valueExpr="value"
                  value={groupColumn}
                  onValueChanged={toggleGroupColumn} />
              </Item>
              {/* <Item location="after">
                <Button2
                  text={expandAll ? 'Collapsar' : 'Expandir'}
                  width='136'
                  onClick={toggleExpandAll} />
              </Item> */}
              <Item location="after">
                <Button2
                  icon='ri-pie-chart-2-fill'
                  text={'Ir Al Dashboard'}
                  width='156'
                  onClick={GoToDashboard} />
              </Item>
              <Item location="after" name="test">
                <Button2
                  icon='refresh'
                  onClick={GetDataGridSku} />
              </Item>
              <Item name="columnChooserButton" />
            </Toolbar>
            <Column dataField= 'FECHA_FORMULARIO' caption= 'Fecha' width={250}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'REGION' caption= 'Región' width={250}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'ESTABLECIMIENTO' caption= 'Establecimiento' width={250}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'USUARIO' caption= 'Usuario' width={250}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'PREGUNTA' caption= 'Pregunta' width={450}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'RESPUESTA' caption= 'Respuesta' width={250}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            
            
          </DataGrid>
        </div>
        {/* <div style={{ height: 600, width: '100%' }}> */}
          {/* <DataGrid rows={dataGrid} columns={columns} localeText={{
            toolbarDensity: 'Size',
            toolbarDensityLabel: 'Size',
            toolbarDensityCompact: 'Small',
            toolbarDensityStandard: 'Medium',
            toolbarDensityComfortable: 'Large',
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          pagination={false} /> */}
        {/* </div> */}

        <Footer />
      </div>
    </React.Fragment>
    </>
  )
}