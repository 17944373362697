import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Loader from '../components/loader/Loader'
import { selectFilters } from '../redux/slice/configSlice'
import feather from "feather-icons/dist/feather";
import { DataGrid, GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging,Export, Selection } from 'devextreme-react/data-grid';

import * as GridMainService from '../services/grid_main_dashboard'

import Select from "react-select";

export default function GridSKU() {

  const userData = useSelector(selectUser)
  const filtersSelect = useSelector(selectFilters)
  const [isLoading, setisLoading] = useState(false)
  const [dataGrid, SetDataGrid] = useState([])
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    GetDataGridSKU()

    switchSkin(skin);
  }, [skin, filtersSelect]);

  const GetDataGridSKU = () => {
    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client

    let info = { client_name: client }

    GridMainService.GetSKU(info).then(res =>{
      let data = res.data
      console.log("🚀 ~ file: StoreNoLlegados.js:82 ~ GridMainService.GetDataGridSKU ~ data:", data)
      
      SetDataGrid(data)
    }) 
  }

 function onExporting(e) {
  console.log(e)
  }

  return (
    <>
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Listado de SKU</h4>
          </div>
        </div>

        <div className="height-grid-75"> 
          <DataGrid 
            dataSource={dataGrid}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            showRowLines={false}
            showBorders={true}
            rowAlternationEnabled={true}
            displayMode='full'
            style={{ height: '75vh', width: '100%' }}
            >
            <GroupPanel visible={true} />
            <ColumnChooser enabled={true} />
            <Scrolling rowRenderingMode='infinity'></Scrolling>
            <ColumnFixing enabled={true} />
            <HeaderFilter visible={true} />
            <Export enabled={true} />

            <Column dataField= 'fecha' caption= 'Fecha de Inventario' width={150}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'region' caption= 'Región' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'establecimiento' caption= 'Establecimiento' width={250}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'categoria' caption= 'Categoria' width={250}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'producto' caption= 'SKU' width={300} >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'precio' caption= 'Ult. Precio' width={75}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'total' caption= 'Inventario' width={75}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
          </DataGrid>
        </div>
        <Footer />
      </div>
    </React.Fragment>
    </>
  )
}