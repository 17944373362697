import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../redux/slice/authSlice';

export default function Main() {

  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  const isValidate = useSelector(selectIsLoggedIn);

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  if(isValidate === false){
        return <Navigate replace to={'/login'}/>
    } else {
      return (
        <React.Fragment>
          <Sidebar />
          <Outlet />
        </React.Fragment>
      )
    }

}