import { Carousel, Col, Row, Button, Card, ProgressBar, Nav, FormCheck } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Select from "react-select";
import feather from "feather-icons/dist/feather";
import Prism from "prismjs";
import Loader from '../components/loader/Loader'
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { selectFilters } from '../redux/slice/configSlice'

//llamada a la api
import * as DinamicQueries from '../services/DinamicsQuery';

// esto hay que instalarlo
import { saveAs } from 'file-saver';
import pptxgen from "pptxgenjs";
import Moment from "moment";
import jsPDF from 'jspdf';
import JSZip, { filter } from 'jszip';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'

export default function Seguimiento() {

  const user = useSelector(selectUser)
  const filtersHeader = useSelector(selectFilters)
  const [isLoading, setisLoading] = useState(false)

  const selectOptions = [
    { value: 'ZIP', label: 'ZIP' },
    { value: 'PDF', label: 'PDF' },
  ];

  const [Establecimito, setEstablecimito] = useState([]);
  const [Formularios, setFormularios] = useState([]);
  const [tags, setTags] = useState([]);
  const [Clientes, setClientes] = useState([]);
  const [Regiones, setRegiones] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [ImagesSelected, setImagesSelected] = useState([]);
  const [resgistros, setRegistros] = useState([]);
  const [LoadPanelBoolean, setLoadPanelBoolean] = useState(false);
  const [selectedAll, setselectedAll] = useState(false);
  const [fechaInicial, setfechaInicial] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );

  const [FechaFinal, setFechaFinal] = useState(new Date());
  const now = new Date();

  const InitialStateFilters = {
    cliente: filtersHeader.client,
    formulario: '',
    region: filtersHeader.region,
    fechaInicial: new Date(new Date().setDate(new Date().getDate() - 7)),
    fechaFinal: new Date(),
    usuarios: '',
    Establecimientos: '',
    tag: "TODOS"
  };
  const [filters, setFilters] = useState(InitialStateFilters);
  const [infoSelected, setinfoSelected] = useState(false);

  const [selectedOptionCliente, setSelectedOptionCliente] = useState(null);
  const [selectedOptionForms, setselectedOptionForms] = useState(null);
  const [selectedOptionRegion, setselectedOptionRegion] = useState(null);
  const [selectedOptionEstablecimientos, setselectedOptionEstablecimientos] = useState(null);
  const [selectedOptionUsuarios, setselectedOptionUsuarios] = useState(null);
  const [selectedOptionTags, setselectedOptionTags] = useState(null);
  const [CoordenadasData, setCoordenadasData] = useState([]);
  const [PositionCentral, setPositionCentral] = useState([]);
  const [Coordenadas, setCoordenadas] = useState([]);
  const [points, setPoints] = useState(true);
  const [center, setCenter] = useState({ lat: 10.47915, lng: -66.90618 });
  const [map, setMap] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(false);

  const [toastConfig, setToastConfig] = React.useState(
    {
      isVisible: false,
      type: "success",
      message: "Busqueda Exitosa",
      displayTime: 2000,
    },
    []
  );

  const options = ["Zip", "PDF"];
  // "Power Point"

  const styleContador = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#506fd9',
    padding: '5px',
    borderRadius: '5px',
    height: "30px"
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("userData"));
    console.log(user)

    SearchFiltersHeader()

    const clientes = user.cliente.map(x => {
      return { value: x, label: x }
    })

    const regiones = user.region.map(x => {
      return { value: x, label: x }
    })
    // setClientes(user.cliente);
    setClientes(clientes);
    // setRegiones(user.region);
    setRegiones(regiones);
    setFilters({ ...filters, region: user.region[0] })
    SearchTag()
    console.log("Aqui")
    localStorage.removeItem("photosSelected")

    Prism.highlightAll();

    feather.replace();

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);

      setPositionCentral([position.coords.latitude, position.coords.longitude]);
    })

  }, [filtersHeader])

  const SearchTag = () => {
    // DinamicQueries.getData('getTag', 'formularios/').then((resp) => {
    //   console.log(resp.data.data[0].tags)
    //   resp.data.data[0].tags.unshift("TODOS")
    //   const tagsFinal = resp.data.data[0].tags.map(x => {
    //     return { value: x, label: x }
    //   })
    //   setTags(resp.data.data[0].tags);
    //   setTags(tagsFinal);
    // });
  }


  const searchFormulario = (e) => {
    console.log(e)
    setSelectedOptionCliente(e);
    if (e != null) {
      if (e.value != "") {
        setFilters({ ...filters, cliente: e.value });
        DinamicQueries.getDataWithParameters('getFormsWeb', 'formularios/', { cliente: e.value }).then((resp) => {
          console.log(resp)
          if (resp?.data?.data != undefined) {
            const fomrularios = resp?.data?.data.map(x => {
              return { value: x.nombre, label: x.nombre }
            })
            // setFormularios(resp.data.data);
            setFormularios(fomrularios);

          }
        });
      }
    }
  };

  const clearFilters = () => {
    setFilters(InitialStateFilters);
    localStorage.removeItem("photosSelected")
    setSelectedOptionCliente(null);
    setselectedOptionForms(null)
    setselectedOptionRegion(null)
    setselectedOptionEstablecimientos(null)
    setselectedOptionUsuarios(null)
    setselectedOptionTags(null)
    setStartDate(new Date())
    setEndDate(new Date())
    setRegistros([])
    setPositionCentral([])
    setCoordenadasData([])
  };

  const handleLoadMap = (map) => {
    setMap(map);
  };

  const serachPictures = () => {
    // setisLoading(true)

    // console.log(filters)
    // console.log(startDate)
    // console.log(endDate)

    // const fechaInicialFormat = Moment(startDate).format(
    //   "YYYY-MM-DD"
    // );
    // const fechaFinalFormat = Moment(endDate).format(
    //   "YYYY-MM-DD"
    // );

    // console.log(fechaInicialFormat)
    // console.log(fechaFinalFormat)
    // setRegistros([])
    // localStorage.removeItem("photosSelected")
    // DinamicQueries.getDataWithParameters('getImages', 'galeria/', { cliente: filters.cliente, nombre: filters.formulario, region: filters.region, fechaInicio: fechaInicialFormat, fechaFin: fechaFinalFormat, promotor: filters.usuarios, establecimiento: filters.Establecimientos }).then((resp) => {
    //   let registrosConFotos = [];
    //   console.log(resp)
    //   if (resp !== undefined) {
    //     resp.data.forEach(element => {
    //       let fotosTemporales = []
    //       if (element.fotos !== undefined) {
    //         if (filters.tag !== "TODOS") {
    //           element.fotos.forEach(x => {
    //             x.selected = false

    //             if (x.tag === filters.tag) {
    //               fotosTemporales.push(x)
    //             }
    //           })
    //           element.fotos = fotosTemporales
    //           registrosConFotos.push(element)
    //         }
    //         else {
    //           element.fotos.forEach(x => {
    //             x.selected = false
    //           })
    //           registrosConFotos.push(element)
    //         }
    //       }
    //     });
    //   }
    //   registrosConFotos.sort((a, b) => {
    //     if (a.establecimiento < b.establecimiento) {
    //       return -1;
    //     }
    //     if (a.establecimiento > b.establecimiento) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    //   console.log(registrosConFotos)
    //   setRegistros(registrosConFotos)
    //   setisLoading(false)
    //   setImagesSelected([])
    // });

    let fecha = Moment(startDate).format("YYYY-MM-DD");

    const searchCoo = {
      cliente: filtersHeader.client,
      region: filtersHeader.region,
      usuario: filters.usuarios,
      fecha: fecha,
    };

    console.log(searchCoo);

    // console.log(filters)
    DinamicQueries.getDataWithParameters(
      "getCoordenadasWithDay",
      "coordenadas/",
      searchCoo
    ).then((resp) => {
      console.log(resp.data);
      if (resp.data.msg) {
        //aqui va la data

        let dataCoordenadas = [];
        let CoordenadasTemporal = [];
        resp.data.data.forEach(function (elemento, indice, array) {
          console.log(elemento, indice);

          if (elemento.coordenadas != undefined && elemento.coordenadas != "") {
            const latlng = elemento.coordenadas.split(",");
            elemento.lat = Number(latlng[0]);
            elemento.lng = Number(latlng[1]);
            dataCoordenadas.push(elemento);
            CoordenadasTemporal.push({
              lat: Number(latlng[0]),
              lng: Number(latlng[1]),
            });
          }
        });
        setCoordenadasData(dataCoordenadas);
        console.log(
          "🚀 ~ file: gps.js ~ line 96 ~ .then ~ dataCoordenadas",
          dataCoordenadas
        );
        setCoordenadas(CoordenadasTemporal);
        // console.log("🚀 ~ file: gps.js ~ line 98 ~ .then ~ Coordenadas", CoordenadasTemporal)
        setPositionCentral([
          CoordenadasTemporal[0].lat,
          CoordenadasTemporal[0].lng,
        ]);
        setPoints(!points);
        setisLoading(false)
      } else {
        setisLoading(false)
        setToastConfig({
          ...toastConfig,
          type: "warning",
          message:
            "El usuario seleccionado no cuenta con coordenadas en el dia dado",
          isVisible: true,
        });
      }
    });
  };

  const SearchFiltersHeader = () => {

    setFilters({ ...filters, cliente: filtersHeader.client, region: filtersHeader.region });
    DinamicQueries.getDataWithParameters('getFormsWeb', 'formularios/', { cliente: filtersHeader.client }).then((resp) => {
      console.log(resp)
      if (resp?.data?.data != undefined) {
        const fomrularios = resp?.data?.data.map(x => {
          return { value: x.nombre, label: x.nombre }
        })
        // setFormularios(resp.data.data);
        setFormularios(fomrularios);

      }
    });


    DinamicQueries.getDataWithParameters(
      "getUserClientAndRegionAnalitycs",
      "usuarios/",
      { cliente: filtersHeader.client, region: filtersHeader.region }
    ).then((dataUserConnected) => {
      console.log(dataUserConnected);
      let dataUsuarioFinal = [];

      dataUsuarioFinal = dataUserConnected?.data
        .filter(
          (x) =>
            x.activado == "Activo" ||
            x.activado == true ||
            x.activado == "activo"
        );

      console.log(dataUsuarioFinal);

      // console.log(allUser)
      // console.log(dataUsuarioFinal)

      let infoFinal = dataUsuarioFinal.map(x => {
        const info = {
          label: x.nombre,
          value: x.uid
        }
        return info
      });
      const allUser = {
        label: "Todos los usuarios",
        vaue: "Todos los usuarios",
      };
      infoFinal.unshift(allUser);
      console.log(infoFinal)
      setUsuario(infoFinal)
      // setisLoading(false)
    });
  }

  const searchUsersAndStablishment = (e) => {
    let Establecimientos = [];
    let Usuarios = [];
    console.log(e)
    setisLoading(true)
    // if (e?.element?.innerText === 'Region') {
    //   if (e.value != "") {
    //     setFilters({ ...filters, region: e.value })
    //     DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: filters.cliente, region: e.value })
    //       .then(establecimientos => {
    //         Establecimientos = [{
    //           nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
    //         }, ...establecimientos.data]
    //         setEstablecimito(Establecimientos)
    //       })

    //     DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: filters.cliente, region: e.value })
    //       .then(usuarios => {
    //         console.log("🚀 ~ file: gallery.js ~ line 122 ~ searchUsersAndStablishment ~ usuarios", usuarios)
    //         Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]
    //         setUsuario(Usuarios)
    //       })
    //   }
    // } else if (e.element.innerText === 'Cliente') {
    if (e != null) {
      if (e.value != "") {
        setFilters({ ...filters, cliente: filtersHeader.client });

        DinamicQueries.getDataWithParameters(
          "getUserClientAndRegion",
          "usuarios/",
          { cliente: filtersHeader.client, region: filtersHeader.region }
        ).then((dataUserConnected) => {
          console.log(dataUserConnected);
          let dataUsuarioFinal = [];

          dataUsuarioFinal = dataUserConnected?.data
            .map((x) => {
              console.log("🚀 ~ file: Seguimiento.js:371 ~ .map ~ x:", x)
              x.establecimientos = [];
              x.cliente = [];
              dataUsuarioFinal.push(x);
              return x;
            })
            .filter(
              (x) =>
                x.estatus == "Activo" ||
                x.estatus == true ||
                x.estatus == "activo"
            );

          console.log(dataUsuarioFinal);

          // console.log(allUser)
          // console.log(dataUsuarioFinal)

          let infoFinal = dataUsuarioFinal.map(x => {
            const info = {
              label: x.nombre,
              value: x.uid
            }
            return info
          });
          const allUser = {
            label: "Todos los usuarios",
            vaue: "Todos los usuarios",
          };
          infoFinal.unshift(allUser);
          console.log(infoFinal)
          setUsuario(infoFinal)
          setisLoading(false)
        });



        // DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: e.value, region: filters.region })
        //   .then(establecimientos => {
        //     Establecimientos = [{
        //       nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
        //     }, ...establecimientos.data]
        //     const establecimientosFinal = Establecimientos.map(x => {
        //       return { value: x.nombre_establecimiento, label: x.nombre_establecimiento }
        //     })
        //     // setEstablecimito(Establecimientos)
        //     setUsuario(establecimientosFinal)
        //   })

        // DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: e.value, region: filters.region })
        //   .then(usuarios => {
        //     console.log("🚀 ~ file: gallery.js ~ line 122 ~ searchUsersAndStablishment ~ usuarios", usuarios)
        //     Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]
        //     const usuariosFinal = Usuarios.map(x => {
        //       return { value: x.uid, label: x.nombre }
        //     })
        //     // setUsuario(Usuarios)
        //     setUsuario(usuariosFinal)
        //   })
      }
    }
    // }
  };

  const searchUsersAndStablishmentRegion = (e) => {
    let Establecimientos = [];
    let Usuarios = [];
    console.log(e)
    setisLoading(true)
    // if (e?.element?.innerText === 'Region') {

    if (e != null) {
      if (e.value != "") {
        setFilters({ ...filters, region: filtersHeader.region })

        DinamicQueries.getDataWithParameters(
          "getUserClientAndRegion",
          "usuarios/",
          { cliente: filtersHeader.client, region: filtersHeader.region }
        ).then((dataUserConnected) => {
          console.log(dataUserConnected);
          let dataUsuarioFinal = [];

          dataUsuarioFinal = dataUserConnected?.data
            .map((x) => {
              x.establecimientos = [];
              x.cliente = [];
              dataUsuarioFinal.push(x);
              return x;
            })
            .filter(
              (x) =>
                x.estatus == "Activo" ||
                x.estatus == true ||
                x.estatus == "activo"
            );
          // console.log(dataUserConnected.data[0].asignados)
          // console.log(dataFields)
          // dataUserConnected.data[0].asignados.forEach(cadaUsuarioAsignado => {
          //     if (cadaUsuarioAsignado.cliente.includes(dataFields.cliente) == true && cadaUsuarioAsignado.region.includes(e.value) == true) {
          //         dataUsuarioFinal.push(cadaUsuarioAsignado)
          //     }
          // })
          console.log(dataUsuarioFinal);

          let infoFinal = dataUsuarioFinal.map(x => {
            const info = {
              label: x.nombre,
              value: x.uid
            }
            return info
          });
          const allUser = {
            label: "Todos los usuarios",
            value: "Todos los usuarios",
          };
          infoFinal.unshift(allUser);
          console.log(infoFinal)
          setUsuario(infoFinal)
          setisLoading(false)
        })



        // DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: filters.cliente, region: e.value })
        //   .then(establecimientos => {
        //     Establecimientos = [{
        //       nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
        //     }, ...establecimientos.data]
        //     console.log(Establecimientos)

        //     const establecimientosFinal = Establecimientos.map(x => {
        //       return { value: x.nombre_establecimiento, label: x.nombre_establecimiento }
        //     })
        //     // setEstablecimito(Establecimientos)
        //     setEstablecimito(establecimientosFinal)
        //   })

        // DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: filters.cliente, region: e.value })
        //   .then(usuarios => {
        //     console.log("🚀 ~ file: gallery.js ~ line 122 ~ searchUsersAndStablishment ~ usuarios", usuarios)
        //     Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]

        //     console.log(Usuarios)

        //     const usuariosFinal = Usuarios.map(x => {
        //       return { value: x.uid, label: x.nombre }
        //     })
        //     // setUsuario(Usuarios)
        //     setUsuario(usuariosFinal)
        //   })
      }
    }
    // } else if (e.element.innerText === 'Cliente') {
    // if (e.value != "") {
    //   setFilters({ ...filters, cliente: e.value });
    //   DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: e.value, region: filters.region })
    //     .then(establecimientos => {
    //       Establecimientos = [{
    //         nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
    //       }, ...establecimientos.data]
    //       setEstablecimito(Establecimientos)
    //     })

    //   DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: e.value, region: filters.region })
    //     .then(usuarios => {
    //       console.log("🚀 ~ file: gallery.js ~ line 122 ~ searchUsersAndStablishment ~ usuarios", usuarios)
    //       Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]
    //       setUsuario(Usuarios)
    //     })
    // }
    // }
  };

  const handleClick = async (url, registro) => {
    console.log(url)
    console.log(registro)
    const data = await fetch(url.foto);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        console.log(base64data)
        // resolve(base64data);
        var a = document.createElement("a"); //Create <a>
        // a.href = "data:image/png;base64," + ImageBase64; //Image Base64 Goes here
        a.href = base64data //Image Base64 Goes here

        let tipo = ""

        var data = base64data.substring(0, 5);

        switch (data.toUpperCase()) {
          case "IVBOR": tipo = "png";
            break
          case "/9J/4": tipo = "jpeg";
            break
          case "JVBER": tipo = "pdf";
            break
          case "UESDB":
            var data2 = base64data.substring(0, 19);
            switch (data2.toUpperCase()) {
              case "UEsDBBQABgAIAAAAIQB": tipo = "xlsx";
                break
              default:
                var data3 = base64data.substring(0, 18);

                switch (data3.toUpperCase()) {
                  case "UESDBBQABGAIAAAAIQ": tipo = "docx";
                    break
                  default: tipo = "zip";
                }
            }
            break
          case "UMFYI": tipo = "rar";
            break
          case "U1PKC": tipo = "txt";
            break
          default:
            console.log("")
            break
        }
        // registro.establecimiento +"-"+  url.tag + "-" + url.descripcion
        a.download = registro.establecimiento + "-" + url.tag + "-" + url.descripcion + "." + tipo //File name Here
        a.click(); //Downloaded file
      }
    });
  }

  const savePhoto = (photo) => {
    console.log(photo)
    if (localStorage.getItem('photosSelected') != undefined) {
      let photos = localStorage.getItem('photosSelected').split(',');
      console.log(photos)
      const found = photos.findIndex(element => element === photo.foto);
      if (found >= 0) { // existe, hay que eliminarlo
        photos.splice(found, 1)
      } else {
        photos.push(photo.foto)
      }

      localStorage.setItem('photosSelected', photos.toString())

    } else {
      let data = [photo.foto]
      localStorage.setItem('photosSelected', data.toString())
    }

    let info = localStorage.getItem('photosSelected').split(',')

    console.log(localStorage.getItem('photosSelected').split(','))
    let data = localStorage.getItem('photosSelected').split(',').filter(x => x !== '')
    setImagesSelected(data)

    if (info[0] === '' && info.length === 1) {
      setinfoSelected(false)
    } else {
      setinfoSelected(true)
    }
  }

  const DescargarSelectedZip = async () => {
    let photos = localStorage.getItem('photosSelected').split(',');
    console.log(photos)

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          photos.forEach(y => {
            if (y == cadaFoto.foto) {
              images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
            }
          })
        })
      }
    })

    // console.log(images)
    let dataFinal = images.filter(x => x.url !== '')
    // console.log(dataFinal)
    const zip = new JSZip();

    let contador = 0
    dataFinal.forEach((url, index) => {
      // Carga la imagen como un blob
      fetch(url.url)
        .then(res => res.blob())
        .then(blob => {
          // Agrega la imagen al archivo ZIP
          // zip.file(`image${index}.jpg`, blob);
          contador++
          zip.file(contador + "- " + `${url.name}.jpg`, blob);
          if (contador === dataFinal.length) {
            // Cuando se hayan agregado todas las imágenes, genera el archivo ZIP
            zip.generateAsync({ type: 'blob' }).then(content => {
              // Descarga el archivo ZIP
              console.log("Aquiiii")
              console.log(content)
              setLoadPanelBoolean(false)
              saveAs(content, 'RegistroFotografico.zip');
            });
          }
        });
    });
  }

  const DescargarAllZip = () => {

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
        })
      }
    })
    const zip = new JSZip();
    let contador = 0
    images.forEach((url, index) => {
      // Carga la imagen como un blob
      fetch(url.url)
        .then(res => res.blob())
        .then(blob => {
          // Agrega la imagen al archivo ZIP
          contador++
          zip.file(contador + "- " + `${url.name}.jpg`, blob);
          if (contador === images.length) {
            // Cuando se hayan agregado todas las imágenes, genera el archivo ZIP
            zip.generateAsync({ type: 'blob' }).then(content => {
              // Descarga el archivo ZIP
              setLoadPanelBoolean(false)
              saveAs(content, 'RegistroFotografico.zip');
            });
          }
        });
    });
  }

  const DescargarInfo = (e) => {
    console.log(e)
    setLoadPanelBoolean(true)
    if (ImagesSelected.length == 0) {
      if (e.value === "ZIP") {
        DescargarAllZip()
      } else if (e.value === "Power Point") {
        DescargarAllPowerPoint()
      } else if (e.value === "PDF") {
        DescargarAllPdf()
      }
    } else {
      if (e.value === "ZIP") {
        DescargarSelectedZip()
      } else if (e.value === "Power Point") {
        DescargarSelectedPowerPoint()
      } else if (e.value === "PDF") {
        DescargarSelectedPdf()
      }
    }
  }

  const DescargarAllPdf = () => {

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
        })
      }
    })
    console.log(images)
    const doc = new jsPDF();
    let contador = 0
    images.forEach((url, index) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        // Agrega la imagen al PDF

        contador++
        const width = doc.internal.pageSize.getWidth();
        const height = (img.height * width) / img.width;
        doc.addImage(img, 'JPEG', 0, 20, width, height);
        if (contador === images.length) {
          // Cuando se hayan agregado todas las imágenes, guarda el PDF
          console.log("Aquiiii")
          setLoadPanelBoolean(false)
          doc.save('RegistroFotografico.pdf');
        } else {
          // Agrega una nueva página antes de agregar la siguiente imagen
          console.log("Aquiiii")
          // doc.addFont(url.descripcion)
          // doc.setTextColor("black")
          // doc.text(url.descripcion, 10, 200);
          doc.text(url.name === "" || url.name === undefined || url.name === null ? "Image" : url.name, 10, 10);

          // Agregar el nuevo texto encima del texto original
          doc.setFontSize(12);
          doc.addPage();
        }
      };
      img.src = url.url;
    });
  }

  const DescargarSelectedPdf = async () => {
    let photos = localStorage.getItem('photosSelected').split(',');
    console.log(photos)



    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          photos.forEach(y => {
            if (y == cadaFoto.foto) {
              images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
            }
          })
        })
      }
    })

    console.log(images)

    let dataFinal = images.filter(x => x !== '')
    console.log(dataFinal)

    const doc = new jsPDF();
    let contador = 0
    dataFinal.forEach((url, index) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        // Agrega la imagen al PDF
        const width = doc.internal.pageSize.getWidth();
        const height = (img.height * width) / img.width;
        doc.text(url.name === "" || url.name === undefined || url.name === null ? "Image" : url.name, 10, 10);
        doc.setFontSize(12);
        doc.addImage(img, 'JPEG', 0, 20, width, height);
        contador++
        console.log(contador)
        if (contador === images.length) {
          console.log("Aquiiii")
          setLoadPanelBoolean(false)
          doc.save('RegistroFotografico.pdf');
        } else {
          console.log("Aquiiii")
          // doc.text(url.name === "" || url.name === undefined || url.name === null ? "Image" : url.name, 10, 10);
          // doc.setFontSize(12);
          doc.addPage();
        }
      };
      img.src = url.url;
    });
  }


  const DescargarAllPowerPoint = () => {

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          images.push(cadaFoto.foto)
        })
      }
    })
    // console.log(images)
    const pptx = new pptxgen();

    images.forEach((image) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = image;
      img.onload = () => {
        // Agrega una diapositiva a la presentación
        const slide = pptx.addSlide();

        // Agrega la imagen a la diapositiva
        slide.addImage({ data: img.src, x: 1, y: 2, w: 5, h: 3 });

        // Descarga la presentación de PowerPoint una vez que se hayan agregado todas las imágenes
        console.log(pptx.slides.length, "===", images.length)
        if (pptx.slides.length === images.length - 1) {
          console.log("Aqui")
          setLoadPanelBoolean(false)
          pptx.writeFile('images.pptx');
        }
      };
    });
  }

  const DescargarSelectedPowerPoint = async () => {
    let photos = localStorage.getItem('photosSelected').split(',');
    console.log(photos)
    // photos.forEach(async x => {
    //     if (x != '') {

    let dataFinal = photos.filter(x => x !== '')
    console.log(dataFinal)

    const pptx = new pptxgen();

    dataFinal.forEach((image) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = image;
      img.onload = () => {
        // Agrega una diapositiva a la presentación
        const slide = pptx.addSlide();

        console.log(img)
        // Agrega la imagen a la diapositiva
        slide.addImage({ data: img.src, x: 1, y: 2, w: 5, h: 3 });
        console.log(pptx.slides)
        console.log(dataFinal.length)

        // Descarga la presentación de PowerPoint una vez que se hayan agregado todas las imágenes
        console.log(pptx.slides.length, "===", dataFinal.length)
        if (pptx.slides.length === dataFinal.length) {
          console.log("Aqui")
          setLoadPanelBoolean(false)
          pptx.writeFile('images.pptx');
        }
      };
    });
  }

  const selectAll = () => {
    const newData = [...resgistros];
    newData.forEach(element => {
      if (element.fotos !== undefined) {
        element.fotos.forEach(x => {
          // if (x.foto == fotos.foto){
          x.selected = true
          // }
        })
      }
    });
    setRegistros(newData)
    setselectedAll(true)
  }

  const DeselectAll = () => {
    const newData = [...resgistros];
    newData.forEach(element => {
      if (element.fotos !== undefined) {
        element.fotos.forEach(x => {
          // if (x.foto == fotos.foto){
          x.selected = false
          // }
        })
      }
    });
    setRegistros(newData)
    setselectedAll(false)
  }

  return (
    <React.Fragment>
      {/* {isLoading && <Loader />} */}
      <Header />
      <div className="main main-app p-3 p-lg-4 p-xxl-5">
        <div className="main-label-group mb-3">
          <h2>Seguimiento</h2>
          {/* <Link to="">See All</Link> */}
        </div>

        <Row className="g-3 g-xl-4 mb-4" style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={2}>
            <Button className="btn btn-light mt-4 mr-2" style={{ width: "100%" }} onClick={clearFilters}>Limpiar Filtros</Button>
          </Col>
          {/* <Col xs={2}>
            <Select options={selectOptions} isSearchable={true} isClearable disabled={resgistros.length == 0} className="mt-4"
              onChange={(e) => {
                DescargarInfo(e)
              }} />
          </Col>
          <Col xs={2}>
            {selectedAll == true ? (
              <Button className="btn bg-color mt-4 mr-2" style={{ width: "100%" }} onClick={DeselectAll} disabled={resgistros.length == 0}>Deseleccionar</Button>
            ) : (
              <Button className="btn bg-color mt-4 mr-2" style={{ width: "100%" }} onClick={selectAll} disabled={resgistros.length == 0}>Seleccionar todos</Button>
            )}
          </Col> */}
          <Col xs={2}>
            <Button className="btn btn-primary bg-color mt-4 mr-2" style={{ width: "100%" }} onClick={serachPictures}>Buscar</Button>
          </Col>
        </Row>

        {/* <div style={{ display: "flex", width: "45%" }}>


        </div> */}

        <div className="row-wrapper mb-4 mb-xxl-5">
          <Row className="g-3 g-xl-4">
            {/* <Col>
              <span>Clientes</span>
              <Select options={Clientes} isSearchable={true} isClearable value={selectedOptionCliente}
                onChange={(e) => { searchFormulario(e);searchUsersAndStablishment(e) }} />
            </Col> */}
            {/* <Col>
            <span>Region</span>
              <Select options={Regiones} isSearchable={true} isClearable value={selectedOptionRegion}
                onChange={(e) => { searchUsersAndStablishmentRegion(e); setselectedOptionRegion(e) }} />
            </Col> */}
            <Col>
              <span>Usuarios</span>
              <Select options={usuario} isSearchable={true} isClearable value={selectedOptionUsuarios}
                disabled={
                  filters.region !== "" && filters.cliente !== ""
                    ? false
                    : true
                }

                onChange={(e) => {
                  if (e != null) {
                    setFilters({
                      ...filters,
                      usuarios: e.value,
                    })
                    setselectedOptionUsuarios(e)
                  }
                }}
              />
            </Col>
            <Col>
              <span>Fecha</span>
              {/* <Select options={selectOptions} isSearchable={true} isClearable /> */}
              <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
            </Col>

            <br></br>
            {/* <div className="row"> */}
            <Row className="g-3 g-xl-4">
              {PositionCentral.length > 0 ? (
                <GoogleMap
                  center={center}
                  zoom={10}
                  onLoad={handleLoadMap}
                  mapContainerStyle={{ height: "400px", width: "100%" }}
                // onClick={handleMapClick}
                >
                  {CoordenadasData.length > 0
                    ? CoordenadasData.map((element, index) => {
                      return (
                        <Marker
                          position={{ lat: element.lat, lng: element.lng }}
                          key={index}
                          onClick={() => setSelectedIndex(index)}
                        >
                          {selectedIndex === index && (
                            <InfoWindow
                              onCloseClick={() => setSelectedIndex(null)}
                            >
                              {/* Contenido del infoWindow */}
                              <div>
                                <p>
                                  <strong>Usuario: {element.promotor}</strong>
                                </p>
                                <p>
                                  Formularios cargados:{" "}
                                  {element.nombreFormulario}
                                </p>
                                <p>
                                  Establecimiento: {element.establecimiento}
                                </p>
                                <p> Fecha: {element.fecha_Formulario}</p>
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      );
                    })
                    : null}
                </GoogleMap>
              ) : null}
            </Row>

          </Row>

        </div>





      </div>
    </React.Fragment>
  );
}