import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import Button2 from 'devextreme-react/button';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ReactDatePicker from "react-datepicker";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

export default function CardsComparePrice() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const d1 = [[0, 38], [1, 32], [2, 31], [3, 33], [4, 34], [5, 35], [6, 38], [7, 37], [8, 39], [9, 34], [10, 33], [11, 32], [12, 34], [13, 38], [14, 42], [15, 43], [16, 45], [17, 43], [18, 45], [19, 48], [20, 45], [21, 46], [22, 44], [23, 42], [24, 46], [25, 48], [26, 55], [27, 54], [28, 58], [29, 69]];

    // Tickets Sold
    const seriesThree = [{
      name: 'series1',
      data: d1
    }];
  
    const optionThree = {
      chart: {
        parentHeightOffset: 0,
        toolbar: { show: false },
        sparkline: { enabled: true }
      },
      colors: ['#506fd9'],
      stroke: {
        curve: 'straight',
        width: 2
      },
      xaxis: { max: 20 },
      yaxis: {
        min: 0,
        max: 160,
        show: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.5,
          opacityTo: 0,
        }
      },
      tooltip: { enabled: false }
    };

    const optionFour = {
      chart: {
        parentHeightOffset: 0,
        toolbar: { show: false },
        sparkline: { enabled: true }
      },
      colors: ['#85b6ff'],
      stroke: {
        curve: 'straight',
        width: 2
      },
      xaxis: {
        min: 3,
        max: 25
      },
      yaxis: {
        min: 0,
        max: 160,
        show: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.5,
          opacityTo: 0,
        }
      },
      tooltip: { enabled: false }
    };


  useEffect(() => {

  }, []);


  return (
    <div>
      <Row className="g-3">
        <Col sm="4">
          <Card className="card-one card-ticket-sold">
            <Card.Body className="p-4">
              <div className="d-flex flex-column align-items-center">
                <h1 className="card-value fs-32 mb-2 ls--1">11,716</h1>
                <label className="card-label fw-semibold text-dark mb-1">Tickets Sold</label>
                <p className="text-secondary text-center fs-sm mb-0">Ullam corper ultricies cura bitur nisi nam eget dui etia.</p>
              </div>
            </Card.Body>
            <ReactApexChart series={seriesThree} options={optionThree} type="area" height={210} className="apex-chart" />
          </Card>
        </Col>
        <Col sm="4">
          <Card className="card-one card-ticket-sold">
            <Card.Body className="p-4">
              <div className="d-flex flex-column align-items-center">
                <h1 className="card-value fs-32 mb-2 ls--1">12,864</h1>
                <label className="card-label fw-semibold text-dark mb-1">Tickets Unsold</label>
                <p className="text-secondary text-center fs-sm mb-0">Nam libero tempore, cum soluta nobis est eligendi.</p>
              </div>
            </Card.Body>
            <ReactApexChart series={seriesThree} options={optionFour} type="area" height={210} className="apex-chart" />
          </Card>
        </Col>
        <Col sm="4">
          <Card className="card-one card-ticket-sold">
            <Card.Body className="p-4">
              <div className="d-flex flex-column align-items-center">
                <h1 className="card-value fs-32 mb-2 ls--1">12,864</h1>
                <label className="card-label fw-semibold text-dark mb-1">Tickets Unsold</label>
                <p className="text-secondary text-center fs-sm mb-0">Nam libero tempore, cum soluta nobis est eligendi.</p>
              </div>
            </Card.Body>
            <ReactApexChart series={seriesThree} options={optionFour} type="area" height={210} className="apex-chart" />
          </Card>
        </Col>
      </Row>
    </div>
  )
}