import { configureStore, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import authReducer from './slice/authSlice';
import userReducer from './slice/userSlice';
import dateReducer from './slice/dateSlice';
import configSlice from './slice/configSlice';
import storage from 'redux-persist/lib/storage';
import gridDetallePrecioSlice from './slice/gridDetallePrecioSlice';
import gridSincronizacionesSlice from './slice/gridSincronizacionesSlice';
import gridEncuestasSlice from './slice/gridEncuestasSlice';
import dashEncuestasSlice from './slice/dashEncuestas';
import reportsAutoSlice from './slice/reportsAutoSlice';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const logger = {};

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    date: dateReducer,
    config: configSlice,
    grid_detalle_price: gridDetallePrecioSlice,
    grid_sincronizaciones: gridSincronizacionesSlice,
    grid_encuestas: gridEncuestasSlice,
    dashEncuestas: dashEncuestasSlice,
    reportsAuto: reportsAutoSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

const Persistor = persistStore(store);

export { Persistor };
export default store;