import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        SET_USER: (state, action) => {
            state.user = action.payload.user;
        },
        REMOVE_USER: (state, action) => {
            state.user = null;
        }
    }
});

export const { SET_USER, REMOVE_USER } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;