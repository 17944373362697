import React, { useCallback, useRef, useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import Button2 from 'devextreme-react/button';
import { Link } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import { selectFiltersGridDP } from '../redux/slice/gridDetallePrecioSlice'
import { selectFilters } from '../redux/slice/configSlice'
// import { selectClient } from '../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker"; 
import moment from "moment";
import Loader from '../components/loader/Loader'
import feather from "feather-icons/dist/feather";
// import { DataGrid, GridRowsProp, GridColDef, GridToolbar  } from '@mui/x-data-grid';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import { DataGrid, GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging,Export, Selection, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo, Toolbar, Item, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';

import * as GridMainService from '../services/grid_main_dashboard'

import Select from "react-select";
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from "../redux/slice/dateSlice";
export default function GridCompetenceByCategory() {

  const userData = useSelector(selectUser)
  const clientSelect = ''
  const [isLoading, setisLoading] = useState(false)
  const [dataGrid, SetDataGrid] = useState([])
  const dataGridRef = useRef(null);
  const filters = useSelector(selectFiltersGridDP)
  const filtersSelect = useSelector(selectFilters)
  const globalDate = useSelector(selectDate);
  const [startDate, setStartDate] = useState(new Date(globalDate.startDate));
  const [endDate, setEndDate] = useState(new Date(globalDate.endDate));
  const [groupColumn, setGroupColumn] = useState('SKU');
  const [expandAll, setExpandAll] = useState(true);
  const [filtersGrid, SetFiltersGrid] = useState([])
  const groupingValues = [{
    value: 'NAME_REGION',
    text: 'Agrupar por Region',
  }, {
    value: 'CATEGORY',
    text: 'Agrupar por Categorias',
  }];
  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  
  
  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  // this.onExporting = this.onExporting.bind(this);

  switchSkin(skin);

  useEffect(() => {
    let filters_ = []
    SetFiltersGrid(filters_)
    
    GetDataGridCompetenceByCategory()

    switchSkin(skin);
  }, [skin, clientSelect]);

  const GetDataGridCompetenceByCategory = () => {
    console.log('en el get')
    setisLoading(true)

    //let value = clientSelect == null ? userData.cliente[0] : clientSelect

    //let info = { client_name: value }
    let value = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: value,
      region_name: region,
      fecha_ini : moment(startDate).format('YYYY/MM/DD'),
      fecha_fin : moment(endDate).format('YYYY/MM/DD'),      
    }
    
    GridMainService.GetGridCompetenceByCategory(info).then(res =>{
      let data = res.data
      console.log("🚀 ~ file: GridCompetenceByCategory.js:101 ~ GridMainService.GetGridCompetenceByCategory ~ data:", data)
      
      SetDataGrid(data)
      setisLoading(false)

    }) 
  }
  const toggleGroupColumn = useCallback((e) => {
    const newGrouping = e.value;

    dataGridRef.current.instance.clearGrouping();
    dataGridRef.current.instance.columnOption(newGrouping, 'groupIndex', 0);

    setGroupColumn(newGrouping);
  }, []);

  const toggleExpandAll = useCallback(() => {
    setExpandAll(!expandAll);
  }, [expandAll]);
 function onExporting(e) {
  console.log(e);  
  }
  const SetPeriodoFecha = (type) => {
    console.log(type+" djwejwejoweo");
    switch (type) {
      case 1:
         
        setStartDate(new Date())
        setEndDate(new Date())
        break;
      case 2:
        
        let fecha = moment(new Date())
        let semana = fecha.week()
        let ano = fecha.year()
        let inicio = moment().year(ano).week(semana).startOf('week')
        let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a')
        let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
        let fecha_newDate = new Date(anio, mes - 1, dia, hora % 12 + (ampm.toLowerCase() === "pm" ? 12 : 0), minuto);
        setStartDate(fecha_newDate)
        setEndDate(new Date())
        break;
      case 3:
        
        let fecha2 = moment(new Date())
        let mes2 = fecha2.month()
        let ano2 = fecha2.year()
        let inicio2 = moment().year(ano2).month(mes2).startOf('month')
        let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
        let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
        let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
        setStartDate(fecha_newDate2)
        setEndDate(new Date())
        break;
      default:
         
        setStartDate(new Date())
        setEndDate(new Date())
        break;
    }
    GetDataGridCompetenceByCategory()
  }
  return (
    <>
    {/* {isLoading && <Loader />} */}
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Participación por Categoría</h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control calenderInput" />
            <ReactDatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="form-control calenderInput" />
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => GetDataGridCompetenceByCategory()}>
              <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="primary">
                Filtro Fecha
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Header>Rango de Fecha</Dropdown.Header>
                <Dropdown.Item onClick={(e) => SetPeriodoFecha(1)}>Hoy</Dropdown.Item>
                <Dropdown.Item onClick={(e) => SetPeriodoFecha(2)}>Ultima Semana</Dropdown.Item>
                <Dropdown.Item onClick={(e) => SetPeriodoFecha(3)}>Ultimo Mes</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        
        <div className="height-grid-75"> 
        <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={dataGrid}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            filterValue={filtersGrid}
            showRowLines={false}
            showBorders={true}
            rowAlternationEnabled={true}
            displayMode='full'
            style={{ height: '75vh', width: '100%' }}
          >
            {/* <Selection mode="multiple" /> */}
            <Grouping autoExpandAll={expandAll} />
            <GroupPanel visible={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <Scrolling rowRenderingMode='infinity'></Scrolling>
            <ColumnFixing enabled={true} />
            <HeaderFilter visible={true} />
            <Export enabled={true} />

            <Toolbar>
              <Item location="after">
                <SelectBox
                  width="225"
                  items={groupingValues}
                  displayExpr="text"
                  valueExpr="value"
                  value={groupColumn}
                  onValueChanged={toggleGroupColumn} />
              </Item>
              <Item location="after">
                <Button2
                  text={expandAll ? 'Collapsar' : 'Expandir'}
                  width='136'
                  onClick={toggleExpandAll} />
              </Item>
              <Item location="after">
                <Button2
                  icon='refresh'
                  onClick={GetDataGridCompetenceByCategory} />
              </Item>
              <Item name="columnChooserButton" />
            </Toolbar>
            <Column dataField= 'NAME_REGION' caption= 'Region' width={250}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'CATEGORY' caption= 'Categoria' width={450}  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'CARAS' caption= 'Caras' width={400}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField= 'COMPETENCIA' caption= 'Competencia    ' width={150}  >
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            
            
          </DataGrid>
        </div>
        {/* <div style={{ height: 600, width: '100%' }}> */}
          {/* <DataGrid rows={dataGrid} columns={columns} localeText={{
            toolbarDensity: 'Size',
            toolbarDensityLabel: 'Size',
            toolbarDensityCompact: 'Small',
            toolbarDensityStandard: 'Medium',
            toolbarDensityComfortable: 'Large',
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          pagination={false} /> */}
        {/* </div> */}

        <Footer />
      </div>
    </React.Fragment>
    </>
  )
}