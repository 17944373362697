import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isFilter: false,
    sku: null,
    fecha_ini: null,
    fecha_fin: null,
    tipo_act: null
}

const gridDetallePriceSlice = createSlice({
    name: "grid_detalle_price",
    initialState,
    reducers: {
        SET_DATA_FILTER_DP: (state, action) => {
            const { sku, fecha_ini, fecha_fin, tipo_act } = action.payload;
            state.sku = sku;
            state.fecha_ini = fecha_ini;
            state.fecha_fin = fecha_fin;
            state.isFilter = true;
            state.tipo_act= tipo_act
        },
        REMOVE_DATA_FILTER_DP: (state, action) => {
            state.isFilter = false
            state.sku = null
            state.fecha_ini = null
            state.fecha_fin = null
            state.tipo_act= null
        }
    }
});

export const { SET_DATA_FILTER_DP, REMOVE_DATA_FILTER_DP } = gridDetallePriceSlice.actions;

export const selectFiltersGridDP = (state) => state.grid_detalle_price;

export default gridDetallePriceSlice.reducer;