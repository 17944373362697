import settings from "./enviroment";
import Axios from "axios";

const urlAnalytics = settings.API_URL + "analytics/";

export const GetStoreNoLlegados = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridStoreNoLlegados", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetSKU = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridSKU", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetSincronizaciones = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridSincronizaciones", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetDetallePrecios = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridDetallePrecio", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetDetalleActivacion = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridDetalleActivacion", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetDetallePlantilla = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridDetallePlantilla", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetDetalleInventarioCategoria = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridDetalleInventarioCategoria", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const callMethodDynamic = (info) => {
  let req = info
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => {  return response; });
  return dataPromise;
};

export const GetActivacionesMensuales = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridActivacionesMensuales", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetGridSkuNoAlcanzados = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridSkuNoAlcanzados", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetGridCompetenceByCategory = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridCompetenceByCategory", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetGridSkuMenosTrafico = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridSkuMenosTrafico", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetGridSkus = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridSkus", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetIncidences = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridIncidences", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetDetalleTasa = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "grids/getGridDetalleTasa", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};
