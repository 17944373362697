import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isReports: false,
    id: null,
}

const reportsAutoSlice = createSlice({
    name: "reportsAuto",
    initialState,
    reducers: {
        SET_FILTERS_REPORTS_AUTO: (state, action) => {
            state.id = action.payload.id;
            state.isReports = true;
        },
        REMOVE_FILTERS_REPORTS_AUTO: (state, action) => {
            state.id = null;
            state.isReports = false;
        }
    }
});

export const { SET_FILTERS_REPORTS_AUTO, REMOVE_FILTERS_REPORTS_AUTO } = reportsAutoSlice.actions;

export const selectReportsAuto = (state) => state.reportsAuto.id;

export default reportsAutoSlice.reducer;