import React, { useCallback, useRef, useState, useEffect } from 'react';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import Button2 from 'devextreme-react/button';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../redux/slice/userSlice'
import { selectFiltersGridDP } from '../../redux/slice/gridDetallePrecioSlice'
import { selectFilters } from '../../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import DataGrid, { GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging, Export, Selection, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo, Toolbar, Item, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import List, { ListTypes } from 'devextreme-react/list';
import TreeView, { TreeViewTypes } from 'devextreme-react/tree-view';
import DropDownBox, { DropDownBoxTypes } from 'devextreme-react/drop-down-box';
import 'jspdf-autotable';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";

import CardsComparePrice from './Cards'

import Chart from 'react-apexcharts';

import * as ExtrasService from '../../services/extras'

import Select from "react-select";
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from '../../redux/slice/dateSlice';
import { REMOVE_DATA_FILTER_DP } from '../../redux/slice/gridDetallePrecioSlice';

export default function MainComparePrice() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  // switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="height-grid-75">
        <FormWizard
          onComplete={handleComplete}
          nextButtonText={'Siguiente'}
          backButtonText={'Atrás'}
          finishButtonText={'Analizar'}
          startIndex={1}
        >
          <FormWizard.TabContent title="Tipo de Comparación" icon="ri-questionnaire-line">

            <CardsComparePrice />

          </FormWizard.TabContent>
          <FormWizard.TabContent title="Additional Info" icon="ti-settings">
            <h3>Second Tab</h3>
            <p>Some content for the second tab</p>
          </FormWizard.TabContent>
          <FormWizard.TabContent title="Last step" icon="ti-check">
            <h3>Last Tab</h3>
            <p>Some content for the last tab</p>
          </FormWizard.TabContent>
        </FormWizard>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}