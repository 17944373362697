import { Oval } from 'react-loader-spinner';
import ReactDOM from 'react-dom';

const Loader = () => {
    return ReactDOM.createPortal (
        <Oval
            height="80"
            width="80"
            radius="20"
            color="#4f46e5"
            ariaLabel="three-dots-loading"
            wrapperStyle={{  
                position: 'fixed',
                left: '50%',
                top: '50%',
                width: '100vw',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                zIndex: 999}}
            wrapperClassName=""
            visible={true}
        />,
        document.getElementById("loader")
    )
}

export default Loader