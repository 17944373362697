import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice';
import { selectFilters } from '../redux/slice/configSlice';
import { selectReportsAuto } from '../redux/slice/reportsAutoSlice';
import { SET_DATA_FILTER_DP } from '../redux/slice/gridDetallePrecioSlice';
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from "../redux/slice/dateSlice";
import { SET_FILTERS } from '../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Loader from '../components/loader/Loader'
import feather from "feather-icons/dist/feather";
import { DataGrid } from '@mui/x-data-grid';
import html2canvas from 'html2canvas';

import * as DashboardService from '../services/main_dashboard'

import prod1 from "../assets/img/prod1.jpg";
import prod2 from "../assets/img/prod2.jpg";
import prod3 from "../assets/img/prod3.jpg";
import prod4 from "../assets/img/prod4.jpg";
import prod5 from "../assets/img/prod5.jpg";
// import "./style.scss";
import Select from "react-select";

export default function MainDashboard() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector(selectUser);
  const globalDate = useSelector(selectDate);
  const paramsReportsAuto = useSelector(selectReportsAuto); 
  const filtersSelect = useSelector(selectFilters);
  const [isLoading, setisLoading] = useState(false)
  const [showDropdownCategory, setShowDropdownCategory] = useState(false)

  const [valuePDV_reached, SetValuePDV] = useState(0)
  const [valueAVG_time, SetValueAVG_Time_Visit] = useState(0)
  const [valueRate, SetValueRate] = useState(0)
  const [valueCountPromoter, SetValueCountPromoter] = useState(0)
  const [valueCountSupervisor, SetValueCountSupervisor] = useState(0)
  const [valueStoreNoLlegados, SetValueStoreNoLlegados] = useState(0)
  const [valueSKUNoLlegados, SetValueSKUNoLlegados] = useState(0)
  const [valueAllSku, setValueAllSku] = useState(0);
  const [valueCountActivation, SetValueCountActivation] = useState({ dia: 0, sem: 0, mes: 0 })
  const [data_chart, SetDataChart] = useState({ options: {}, data: [] })
  const [dias_final, SetDias] = useState([]);
  const [listCategory, SetListCategory] = useState([]);
  const [listRegion, SetListRegion] = useState([]);
  const [dataCategory, SetDataCategory] = useState([]);
  const [dataPieCategory, SetDataPieCategory] = useState({ labels: ['Cliente', 'Competencia'], datasets: [{ data: [0, 0], backgroundColor: ['#506fd9', '#85b6ff'] }] });
  const [labelPieCategory, SetLabelPieCategory] = useState('')
  const [filtrosCategory, SetFiltrosCategory] = useState({ region: 'TODAS', category: 'TODAS' })

  // Variables de grafico de inventario
  const [dataAllInventory, SetDataAllInventory] = useState([]);
  const [filtrosInventory, SetFiltrosInventory] = useState({ region: 'TODAS', category: 'TODAS', cadena: 'TODAS', establecimiento: 'TODOS' })
  const [listRegionInventory, SetListRegionInventory] = useState([]);
  const [listCategoryInventory, SetListCategoryInventory] = useState([]);
  const [listCadenaInventory, SetListCadenaInventory] = useState([]);
  const [listEstablecimientoInventory, SetListEstablecimientoInventory] = useState([]);
  const [dataTop5Plantilla, SetDataTop5Plantilla] = useState([]);
  const [dataAllTop5Plantilla, SetDataAllTop5Plantilla] = useState([]);
  const [listRegionPlantilla, SetListRegionPlantilla] = useState([]);
  const [filtrosTopPlantilla, SetFiltrosTopPlantilla] = useState({ region: 'TODAS' })


  // Top SKU
  const [dataTop5SKU, SetDataTop5SKU] = useState([]);
  const [dataAllTopSKU, SetDataAllTopSKU] = useState([]);
  const [listCategorySKU, SetListCategorySKU] = useState([]);
  const [filtrosTopSKU, SetFiltrosTopSKU] = useState({ category: 'TODAS' })

  //Incidencias
  const [dataIncidencias, setDataIncidencias] = useState([]);
  const [dataIncidenciasFilter, setdataIncidenciasFilter] = useState([]);
  const [listGravedadIncidencias, setListGravedadIncidencias] = useState([]);
  const [listPromotorIncidencias, setListPromotorIncidencias] = useState([]);
  const [listRegionIncidencias, setListRegionIncidencias] = useState([]);
  const [listTipoIncidencias, setListTipoIncidencias] = useState([]);

  // Chart SKU Lass Trafic
  const [dataLassTrafic, SetDataLassTrafic] = useState({ labels: [], datasets: [] });

  const [chartInventoryReady, SetChartInventoryReady] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [valueReports, setValueReports] = useState(false);
  const [maxChart, SetMaxChart] = useState(0)


  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  const schema = {
    options: {
      annotations: {

      },
      chart: {
        height: 450,
        type: "line",
        id: "areachart-2"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        // dashArray: [5, 5],
        width: 1.5,
        curve: 'smooth'
      },
      grid: {
        padding: {
          right: 30,
          left: 20
        }
      },
      title: {
        align: "left"
      },
      labels: dias_final,
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        type: 'numeric',
        labels: {
          formatter: (value) => {
            return parseInt(value, 10)
          }
        }
      }
    },
    data: []
  }
  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState('dark');

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    const updateStartDate = () => {
      console.log("🚀 ~ updateStartDate ~ globalDate:", globalDate)
      if (globalDate.startDate !== null && globalDate.endDate !== null) {
        setStartDate(new Date(globalDate.startDate));
        setEndDate(new Date(globalDate.endDate));

        setInitialLoadComplete(true);
      } else {
        let fecha = moment(startDate);
        let semana = fecha.week();
        let ano = fecha.year();
        let inicio = moment().year(ano).week(semana).startOf('week');
        let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a');
        let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
        let fecha_newDate = new Date(
          anio,
          mes - 1,
          dia,
          hora % 12 + (ampm.toLowerCase() === 'pm' ? 12 : 0),
          minuto
        );

        console.log("🚀 ~ updateStartDate ~ fecha_newDate:", fecha_newDate)
        // const fecha_newDateStr = fecha_newDate.toISOString();
        console.log('end date ', endDate)
        dispatch(SET_DATE({
          startDate: fecha_newDate.toISOString(),
          endDate: endDate.toISOString()
        }));

        setStartDate(new Date(fecha_newDate));

        setInitialLoadComplete(true);
      }
    };

    if (!initialLoadComplete) {
      updateStartDate();
    }
  }, [startDate, initialLoadComplete]);

  useEffect(() => {
    if (initialLoadComplete) {
      if(paramsReportsAuto == null){
        SearchDate();
        switchSkin(skin);
      } else {
          SelectAndSetClient()
      }
    }
  }, [skin, filtersSelect, startDate, initialLoadComplete]);

  useEffect(() => {
    if(valueReports){

      SearchDate();

      setTimeout(() => {
        handleCaptureClick()
      }, 20000);
    }
  }, [valueReports]);

  const SelectAndSetClient = () => {
    let info = {
      method: "sp_reports_list_clients",
      params: {
        client_name: ''
      },
    };


    DashboardService.callMethodDynamic(info).then(res => {
      let list = res.data
      console.log(list)
      list.forEach(x => {
        if(x.value == paramsReportsAuto && filtersSelect.client == null){
          let info = {
            client: x.text,
            region: filtersSelect.region
          }
          console.log("🚀 ~ DashboardService.callMethodDynamic ~ info:", info)
          setValueReports(true)
          dispatch(SET_FILTERS(info))
        }
      })
    })
  }

  const GetValuePDV_Client = () => {

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: client,
      region_name: region,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
    }

    DashboardService.getPDV_Client(info).then(res => {
      let data = res.data[0]
      SetValuePDV(parseInt(data.pdv_reached))
    })
  }

  const GetCountActivation = () => {

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: client,
      region_name: region,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
    }


    DashboardService.GetCountActivation(info).then(res => {
      SetValueCountActivation(res.data)
    })
  }

  const GetValueRate_Client = () => {

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: client,
      region_name: region,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD')
    }

    DashboardService.getRate_Client(info).then(res => {
      let data
      if (res.data.length > 0) data = res.data[0].rate != null ? res.data[0].rate : 0
      SetValueRate(parseFloat(data))
    })
  }

  const GetValueCountSupervisorAndPromotor = () => {

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      method: 'sp_get_supervidor_client',
      params: {
        client_name: client,
        region_name: region,
      }
    }
    DashboardService.callMethodDynamic(info).then(res => {
      let data = { PROMOTOR: 0, SUPERVISOR: 0 }
      if (res.data.length > 0) data = res.data[0]
      SetValueCountPromoter(parseInt(data.PROMOTOR))
      SetValueCountSupervisor(parseInt(data.SUPERVISOR))
    })
  }

  const GetAVG_visit_time = () => {
    // console.clear()
    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      method: 'sp_get_avg_visit_time',
      params: {
        client_name: client,
        region_name: region,
        fecha_ini: moment(startDate).format('YYYY/MM/DD'),
        fecha_fin: moment(endDate).format('YYYY/MM/DD')
      }
    }
    // console.log("🚀 ~ MainDashboard ~ info:", info)

    let data = []

    DashboardService.callMethodDynamic(info).then(res => {
      data = res.data
      console.log("🚀 ~ DashboardService.callMethodDynamic ~ data:", data)
      let cantidadMinutos = 0
      data.forEach(element => {
        if (element.ENTRADA != null && element.SALIDA != null)
          element.fechaEntradaFormat = moment(element.DATE_CREATE).format('YYYY-MM-DD')
        element.fechaSalidaFormat = moment(element.DATE_COMPLETE).format('YYYY-MM-DD')
        element.entradaFormat = `${element.fechaEntradaFormat} ${element.ENTRADA}`
        element.entradaFormat = moment(element.entradaFormat)
        element.salidaFormat = `${element.fechaSalidaFormat} ${element.SALIDA}`
        element.salidaFormat = moment(element.salidaFormat)
        element.fechaDiff = element.salidaFormat.diff(element.entradaFormat, 'minutes');
        cantidadMinutos = cantidadMinutos + element.fechaDiff
      });
      let avgMinutos = cantidadMinutos / (data.length - 1)
      let avg = moment.duration(avgMinutos, 'minutes')
      let avgHoras = parseFloat(avg.asHours().toFixed(2))

      SetValueAVG_Time_Visit(avgHoras)

    })
  }

  const dataBar1 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      label: 'test 1',
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: '#506fd9',
      barPercentage: 0.5
    }, {
      label: 'test 2',
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: '#85b6ff',
      barPercentage: 0.5
    }, {
      label: 'test 3',
      data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      // console.log("🚀 ~ file: MainDashboard.js:360 ~ handleBarClick ~ elements:", elements)
      const clickedDatasetIndex = elements[0].datasetIndex;
      const clickedElementIndex = elements[0].index;
      // console.log('Elemento clicado:', clickedDatasetIndex, clickedElementIndex);
      // console.log(dataLassTrafic)
      // console.log(dataLassTrafic.datasets[clickedDatasetIndex])
    }
  };

  const optionBar1 = {
    onClick: handleBarClick,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: maxChart,
        ticks: {
          color: '#a1aab3',
          font: {
            size: 14
          }
        },
        grid: {
          borderColor: '#e2e5ec',
          borderWidth: 1.5,
          color: 'rgba(65,80,95,.08)'
        }
      },
      x: {
        ticks: {
          color: '#313c47'
        },
        grid: {
          color: 'rgba(65,80,95,.08)'
        }
      }
    }
  };

  let dataInventario

  const GetInventory = () => {
    console.clear()
    SetChartInventoryReady(false)

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      params: {
        client_name: client,
        region_name: region,
        fecha_ini: moment(startDate).format('YYYY/MM/DD'),
        fecha_fin: moment(endDate).format('YYYY/MM/DD')
      }
    }

    DashboardService.getInventory(info).then(res => {
      let data = res.data
      if (data?.data?.length > 0) {

        let categorias = []
        categorias = data.categorias
        categorias.unshift({ value: 'TODAS', label: 'TODAS' })

        let regiones = []
        regiones = data.regiones
        regiones.unshift({ value: 'TODAS', label: 'TODAS' })

        let establecimiento = []
        establecimiento = data.establecimiento
        establecimiento.unshift({ value: 'TODOS', label: 'TODOS' })

        let tipo_establecimiento = []
        tipo_establecimiento = data.tipo_establecimiento
        tipo_establecimiento.unshift({ value: 'TODAS', label: 'TODAS' })

        SetDataAllInventory(data)
        SetListRegionInventory(regiones)
        SetListCategoryInventory(categorias)
        SetListCadenaInventory(tipo_establecimiento)
        SetListEstablecimientoInventory(establecimiento)

        DataBuild(data.data)

      } else {
        SetChartInventoryReady(true)
        SetDataChart({ options: {}, data: [] })
      }
    })
  }

  const CalcularDias = async (fecha_ini, fecha_fin) => {
    const dias = [];
    let fecha = new Date(fecha_ini);
    while (fecha <= new Date(fecha_fin)) {
      dias.push(moment(fecha).format('MM/DD/YYYY'));
      fecha.setDate(fecha.getDate() + 1);
    }
    return dias;
  }

  const OrdenarElementos = (data, callback) => {
    data.sort(function (a, b) {
      if (new Date(a.DATE_FORM) < new Date(b.DATE_FORM)) {
        return -1;
      }
      return 0;
    });
    if (typeof callback === "function") {
      callback();
    }
  }

  const DataBuild = (data) => {
    dataInventario = data
    OrdenarElementos(dataInventario, async function () {
      let dias = await CalcularDias(dataInventario[0].fechaNewDate, dataInventario[dataInventario.length - 1].fechaNewDate)
      console.log("🚀 ~ file: MainDashboard.js:464 ~ OrdenarElementos ~ dias:", dias)
      SetDias(dias)
      CalcularInventario(dias)
    })
  }

  const procesarDatos = (data) => {
    // Creamos un objeto para almacenar los datos agrupados por categoría
    const datosAgrupados = {};

    // Utilizamos un conjunto para almacenar nombres únicos
    const nombresUnicos = new Set();

    // Iteramos sobre los datos de entrada
    data.forEach(item => {
      const { CATEGORY, NAME, inventario } = item;

      // Si la categoría ya existe en datosAgrupados, agregamos el inventario a su array de datos
      // Si no existe, creamos una nueva entrada en datosAgrupados con la categoría y el inventario como primer valor en el array de datos
      if (datosAgrupados[CATEGORY]) {
        datosAgrupados[CATEGORY].data.push(inventario);
      } else {
        datosAgrupados[CATEGORY] = {
          name: CATEGORY,
          data: [inventario]
        };
      }

      // Agregamos el nombre al conjunto de nombres únicos
      nombresUnicos.add(NAME);
    });

    // Convertimos el conjunto de nombres únicos a un array
    const xaxis = Array.from(nombresUnicos);

    // Creamos el array de series a partir de los datos agrupados
    const series = Object.values(datosAgrupados);

    return { series, xaxis };
  }

  const CalcularInventario = (data) => {
    let dias_2 = [...data]
    // console.log("🚀 ~ CalcularInventario ~ dias_2:", dias_2)

    let groupedData = Object.values(dataInventario.reduce(function (result, currentValue) {
      const grupo = currentValue.CATEGORY;
      // console.log("🚀 ~ groupedData ~ grupo:", grupo)

      if (!result[grupo]) {
        result[grupo] = { grupo, valores: [] };
      }
      result[grupo].valores.push(currentValue);
      // console.log("🚀 ~ groupedData ~ result:", result)
      return result;
    }, {}));

    groupedData.forEach(item => {
      item.data = []
      dias_2.forEach(dia => {
        let cantidad = item.valores.filter((objeto) => objeto.fecha_format == dia)
        let x = cantidad.length > 0 ? cantidad.reduce((acumulador, objeto) => acumulador + objeto.inventario, 0) : 0
        item.data.push(x)
      })
      console.log("🚀 ~ CalcularInventario ~ item:", item)
    })

    schema.data = []
    schema.options.labels = data

    groupedData.forEach(item => {
      let data = { name: item.grupo, data: item.data }
      schema.data.push(data)
    })


    const { series, xaxis } = procesarDatos(dataInventario);

    const options = {

      data: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: "zoom"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
        },
        xaxis: {
          categories: xaxis,
        },
        fill: {
          opacity: 1
        },
      },


    };

    SetDataChart(schema)
    SetChartInventoryReady(true)
  }

  const GetCompetenceByCategory = () => {
    SetLabelPieCategory('')

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      params: {
        client_name: client,
        region_name: region,
        fecha_ini: moment(startDate).format('YYYY/MM/DD'),
        fecha_fin: moment(endDate).format('YYYY/MM/DD')
      }
    }

    DashboardService.getParticipacionXCategoria(info).then(res => {

      let data = res.data
      console.log("🚀 ~ DashboardService.getParticipacionXCategoria ~ data:", data)

      // if (data?.data[0]?.message != undefined) {
      //   SetLabelPieCategory('No se esta midiendo las caras de la competencia')

      //   return
      // }
      if (data?.data?.length > 0) {

        if (data?.data[0]?.message != undefined) {
          SetLabelPieCategory('No se esta midiendo las caras de la competencia')

          return
        }

        let categorias = []
        categorias = data.categorias
        categorias.unshift({ value: 'TODAS', label: 'TODAS' })

        let regiones = []
        regiones = data.regiones
        regiones.unshift({ value: 'TODAS', label: 'TODAS' })

        SetListCategory(categorias)
        SetListRegion(regiones)
        SetDataCategory(data.data)

        let client = 0
        let compe = 0
        data.data.forEach(item => {
          client = client + parseInt(item.CARAS)
          compe = compe + parseInt(item.COMPETENCIA)
        })

        let compe2 = ((compe * 100) / (client + compe)).toFixed(2)
        let client2 = ((client * 100) / (client + compe)).toFixed(2)

        let dataPie = {
          labels: [client, 'Competencia'],
          datasets: [{
            data: [client2, compe2],
            backgroundColor: ['#506fd9', '#85b6ff']
          }]
        };

        SetDataPieCategory(dataPie)

      } else {
        let dataPie = {
          labels: [client, 'Competencia'],
          datasets: [{
            data: [100, 0],
            backgroundColor: ['#506fd9', '#85b6ff']
          }]
        };
        SetLabelPieCategory('No hay información en este rango de fecha')
        SetDataPieCategory(dataPie)
      }
    })
  }

  const ChangeCategory = (clear = false) => {

    let data = dataCategory
    if (filtrosCategory.category != 'TODAS') {
      data = data.filter((x) => x.CATEGORY == filtrosCategory.category)
    }
    if (filtrosCategory.region != 'TODAS') {
      data = data.filter((x) => x.NAME_REGION == filtrosCategory.region)
    }

    let client = 0
    let compe = 0
    data.forEach(item => {
      client = client + parseInt(item.CARAS)
      compe = compe + parseInt(item.COMPETENCIA)
    })

    let compe2 = ((compe * 100) / (client + compe)).toFixed(2)
    let client2 = ((client * 100) / (client + compe)).toFixed(2)

    let dataPie = {
      labels: [filtersSelect.client, 'Competencia'],
      datasets: [{
        data: [client2, compe2],
        backgroundColor: ['#506fd9', '#85b6ff']
      }]
    };

    SetDataPieCategory(dataPie)
  }

  const ChangeFiltroInventory = (clear = false) => {

    let data = []

    if (!clear) {
      data = dataAllInventory.data

      if (filtrosInventory.region != 'TODAS') {
        data = data.filter((x) => x.NAME_REGION == filtrosInventory.region)
      }
      if (filtrosInventory.category != 'TODAS') {
        data = data.filter((x) => x.CATEGORY == filtrosInventory.category)
      }
      // if(filtrosInventory.cadena != 'TODAS'){
      //   data = data.filter((x) => x.CATEGORY == filtrosInventory.cadena)
      // }
      if (filtrosInventory.establecimiento != 'TODOS') {
        data = data.filter((x) => x.NAME == filtrosInventory.establecimiento)
      }
      console.log("🚀 ~ ChangeFiltroInventory ~ data:", data)
      DataBuild(data)

    } else {

      SetFiltrosInventory({ region: 'TODAS', category: 'TODAS', cadena: 'TODAS', establecimiento: 'TODOS' })
      data = dataAllInventory.data
      console.log("🚀 ~ ChangeFiltroInventory ~ data:", data)

      DataBuild(data)
    }


  }

  const SearchDate = () => {
    GetValuePDV_Client()
    GetValueRate_Client()
    GetAVG_visit_time()
    GetValueCountSupervisorAndPromotor()
    GetInventory()
    GetCountActivation()
    GetCompetenceByCategory()
    GetTopPlantilla()
    GetTopSKU()
    GetStoreNoLlegados()
    GetSKUNoLlegados()
    GetSKULessTrafic()
    GetTotalSKU()
    GetIncidencias()
  }

  const GetIncidencias = () => {
    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region;

    let info = {
      params: {
        client_name: client,
        region_name: region,
        fecha_ini: moment(startDate).format('YYYY/MM/DD'),
        fecha_fin: moment(endDate).format('YYYY/MM/DD')
      }
    };

    DashboardService.GetIncidences(info).then((res) => {
      setDataIncidencias(res.data)
      setdataIncidenciasFilter(res.data)

      const uniqueValues = {};
      res.data.forEach(item => {
        Object.keys(item).forEach(key => {
          if (key !== 'fecha_Reportado') {
            if (!uniqueValues[key]) {
              uniqueValues[key] = new Set();
            }
            uniqueValues[key].add(item[key]);
          }
        });
      });

      Object.keys(uniqueValues).forEach(key => {
        uniqueValues[key] = [...uniqueValues[key]].map(value => ({
          value: value,
          label: value
        }));
      });

      setListGravedadIncidencias(uniqueValues.gravedad)
      setListPromotorIncidencias(uniqueValues.promotor)
      setListRegionIncidencias(uniqueValues.region)
      setListTipoIncidencias(uniqueValues.tipo)
    });
  }

  const filterDataIncidencias = (key, value) => {
    if (!Array.isArray(dataIncidencias)) {
      console.error('dataIncidencias no es un array válido');
      return [];
    }

    const filter_data = dataIncidenciasFilter.filter(item => item[key] === value);
    setDataIncidencias(filter_data)
  };


  const GetTotalSKU = () => {
    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;

    let info = {
      client_name: client,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD')
    }
    console.log("🚀 ~ GetTotalSKU ~ info:", info)

    DashboardService.GetAllSku(info).then(res => {
      let data = res.data?.length > 0 ? res.data[0].conteo_registros : 0
      setValueAllSku(data)
    })
  }

  const SetPeriodoFecha = (type) => {
    switch (type) {
      case 1:
        setStartDate(new Date())
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: new Date(),
          endDate: new Date()
        }))
        break;
      case 2:
        let fecha = moment(new Date())
        let semana = fecha.week()
        let ano = fecha.year()
        let inicio = moment().year(ano).week(semana).startOf('week')
        let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a')
        let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
        let fecha_newDate = new Date(anio, mes - 1, dia, hora % 12 + (ampm.toLowerCase() === "pm" ? 12 : 0), minuto);
        setStartDate(fecha_newDate)
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: fecha_newDate,
          endDate: new Date()
        }))
        break;
      case 3:
        let fecha2 = moment(new Date())
        let mes2 = fecha2.month()
        let ano2 = fecha2.year()
        let inicio2 = moment().year(ano2).month(mes2).startOf('month')
        let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
        let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
        let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
        setStartDate(fecha_newDate2)
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: fecha_newDate2,
          endDate: new Date()
        }))
        break;
    }
    SearchDate()
  }

  const ChangeValueFiltro = (field, value) => {
    SetFiltrosInventory({ ...filtrosInventory, [field]: value })
    let dataAll = dataAllInventory.data
    if (field == 'region') {
      if (value == 'TODAS') {

        SetListCategoryInventory(dataAllInventory.categorias)
        SetListEstablecimientoInventory(dataAllInventory.establecimiento)

      } else {
        let data = dataAll.filter((x) => x.NAME_REGION == value)
        console.log("🚀 ~ ChangeValueFiltro ~ data:", data)
        let categorias = new Set()
        let establecimientos = new Set()

        data.forEach(item => {
          categorias.add(item.CATEGORY)
          establecimientos.add(item.NAME)
        })

        categorias = [...categorias]

        establecimientos = [...establecimientos]

        categorias = categorias.map(x => {
          let item = { label: x, value: x }
          return item
        })

        establecimientos = establecimientos.map(x => {
          let item = { label: x, value: x }
          return item
        })

        categorias.unshift({ value: 'TODAS', label: 'TODAS' })
        console.log("🚀 ~ ChangeValueFiltro ~ categorias:", categorias)
        establecimientos.unshift({ value: 'TODOS', label: 'TODOS' })
        console.log("🚀 ~ ChangeValueFiltro ~ establecimientos:", establecimientos)

        SetListCategoryInventory(categorias)
        SetListEstablecimientoInventory(establecimientos)
      }
    }
    if (field == 'category') {
      if (value == 'TODAS') {
        SetListEstablecimientoInventory(dataAllInventory.establecimiento)
      } else {
        let data = []
        if (filtrosInventory.region != 'TODAS') {
          data = dataAll.filter((x) => x.CATEGORY == value && x.NAME_REGION == filtrosInventory.region)
        } else {
          data = dataAll.filter((x) => x.CATEGORY == value)
        }
        // let region = new Set()  
        let establecimientos = new Set()

        data.forEach(item => {
          // region.add(item.NAME_REGION)
          establecimientos.add(item.NAME)
        })

        // region = [...region]
        establecimientos = [...establecimientos]

        // region = region.map(x => {
        //   let item = {label: x, value: x}
        //   return item
        // })

        establecimientos = establecimientos.map(x => {
          let item = { label: x, value: x }
          return item
        })

        // region.unshift({value: 'TODAS', label: 'TODAS'})
        establecimientos.unshift({ value: 'TODOS', label: 'TODOS' })

        // SetListRegionInventory(region)
        SetListEstablecimientoInventory(establecimientos)
      }
    }
  }

  const GetTopPlantilla = () => {
    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      params: {
        client_name: client,
        region_name: region,
        fecha_ini: moment(startDate).format('YYYY/MM/DD'),
        fecha_fin: moment(endDate).format('YYYY/MM/DD')
      }
    }
    DashboardService.getTopPlantilla(info).then(res => {
      // console.clear()
      let data = res.data
      let regiones = []
      regiones = data.regiones
      regiones.unshift({ value: 'TODAS', label: 'TODAS' })

      SetListRegionPlantilla(regiones)
      SetDataTop5Plantilla(data.data.slice(0, 10))
      SetDataAllTop5Plantilla(data.data)


    })
  }

  const ChangeFiltrosTopPlantilla = (clear = false) => {

    let data = dataAllTop5Plantilla
    if (filtrosTopPlantilla.region != 'TODAS') {
      data = data.filter((x) => x.REGION == filtrosTopPlantilla.region)
    }

    SetDataTop5Plantilla(data.slice(0, 10))
  }

  const GetTopSKU = () => {
    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      params: {
        client_name: client,
        region_name: region,
        fecha_ini: moment(startDate).format('YYYY/MM/DD'),
        fecha_fin: moment(endDate).format('YYYY/MM/DD')
      }
    }
    DashboardService.getTopSKU(info).then(res => {
      let data = []
      data = res.data

      if (data?.data?.length > 0) {

        let primerTotal = data.data.length > 0 ? parseInt(data.data[0].TOTAL_PRODUCTOS) : 0

        data.data.map(item => {
          const totalProductos = parseInt(item.TOTAL_PRODUCTOS);
          const porcentaje = ((totalProductos / primerTotal) * 100).toFixed(0);
          item.porcentaje = porcentaje
          return item
        })


        let categorias = []
        categorias = data.categorias
        categorias.unshift({ value: 'TODAS', label: 'TODAS' })

        SetListCategorySKU(categorias)
        SetDataTop5SKU(data.data.slice(0, 5))
        SetDataAllTopSKU(data.data)

      }
    })
  }

  const ChangeFiltrosTopSKU = (clear = false) => {

    let data = dataAllTopSKU
    if (filtrosTopSKU.category != 'TODAS') {
      data = data.filter((x) => x.CATEGORIA == filtrosTopSKU.category)
    }

    SetDataTop5SKU(data.slice(0, 5))
  }

  const GetStoreNoLlegados = () => {

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let fecha2 = moment(new Date())
    let mes2 = fecha2.month()
    let inicio2 = moment().year(2023).month(mes2).startOf('month')
    let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
    let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
    let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);

    let info = {
      client_name: client,
      region_name: region,
      fecha_ini: moment(fecha_newDate2).format('YYYY/MM/DD'),
      fecha_fin: moment(new Date()).format('YYYY/MM/DD'),
    }

    DashboardService.GetStoreNoLlegados(info).then(res => {
      let data = res.data
      SetValueStoreNoLlegados(parseInt(data))

    })
  }

  const GetSKUNoLlegados = () => {

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let fecha2 = moment(new Date())
    let mes2 = fecha2.month()
    let inicio2 = moment().year(2023).month(mes2).startOf('month')
    let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
    let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
    let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);

    let info = {
      client_name: client,
      region_name: region,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD')
    }

    DashboardService.GetSKUNoLlegados(info).then(res => {
      let data = res.data
      SetValueSKUNoLlegados(parseInt(data))

    })
  }

  const GetSKULessTrafic = () => {

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: client,
      region_name: region,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD')
    }
    // console.log("🚀 ~ file: MainDashboard.js:938 ~ GetSKULessTrafic ~ info:", info)

    DashboardService.GetSKULessTrafic(info).then(res => {
      let data = res.data
      let max = data?.datasets?.length > 0 ? findMaxWithPercentage(data.datasets) : 0
      SetMaxChart(max)
      // es valor mas alto mas 15%
      SetDataLassTrafic(data)

    })
  }

  const findMaxWithPercentage = (array) => {
    const maxTotal = Math.max(...array.map(obj => obj.total));
    const increasedTotal = Math.ceil(maxTotal * 1.1);
    return increasedTotal;
  };

  const generarColorHexadecimal = (string) => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    const colorHex = `#${componenteHexadecimal(r)}${componenteHexadecimal(g)}${componenteHexadecimal(b)}`;

    return colorHex;
  }

  const componenteHexadecimal = (componente) => {
    const hexadecimal = componente.toString(16).toUpperCase();
    return hexadecimal.length === 1 ? "0" + hexadecimal : hexadecimal;
  }

  const GoGridStoreNoLlegados = () => {
    navigate('/Grids/EstablecimientosNoAlcanzados')
  }

  const GotoDetail = (url) => {
    if (url == 'totalpdv') {
      let info = {
        tipo_act: 1
      }
      url = 'DetalleActivacion'
      dispatch(SET_DATA_FILTER_DP(info))
    }
    if (url == 'tasadolar') {
      let info = {
        tipo_act: 2
      }
      url = 'DetalleActivacion'
      dispatch(SET_DATA_FILTER_DP(info))
    }

    navigate(`/Grids/${url}`)
  };

  const GoGridActivacionesMensuales = (tipo) => {
    let info = {
      tipo_act: tipo
    }

    dispatch(SET_DATA_FILTER_DP(info))
    navigate('/Grids/ActivacionesMensuales')
  }

  const GridDetallePrecios = () => {
    navigate('/Grids/DetallePrecios')
  }

  const GridDetallePreciosWithFilters = (item) => {
    let info = {
      sku: item.PRODUCTO,
      fecha_ini: startDate,
      fecha_fin: endDate
    }

    dispatch(SET_START_DATE({ startDate: startDate }))
    dispatch(SET_END_DATE({ endDate: endDate }))
    dispatch(SET_DATE({ startDate: startDate, endDate: endDate }))
    dispatch(SET_DATA_FILTER_DP(info))

    navigate('/Grids/DetallePrecios')
  }

  const GoGridSkuNoAlcanzados = () => {
    navigate('/Grids/SkuNoAlcanzados')
  }

  const GoGridCompetenceByCategory = () => {
    navigate('/Grids/CompetenceByCategory')
  }

  const GoGridSkuMenosTrafico = () => {
    navigate('/Grids/SkuMenosTrafico')
  }

  const handleCaptureClick = async () => {
    setValueReports(false)
    // const element = document.documentElement;
    const element = document.getElementById('element_main');
    const canvas = await html2canvas(element, {
      scrollY: -window.scrollY,
    });

    const dataUrl = canvas.toDataURL('image/png');
    console.log(dataUrl);

    let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client

    let fecha2 = moment(new Date())
    let mes2 = fecha2.month()
    let inicio2 = moment().year(fecha2.year()).month(mes2).startOf('month')
    let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
    let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
    let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);

    let info = {
      client_name: client,
      title: `Capture Analitycs Formulario Fundamental. Desde: ${moment(fecha_newDate2).format('YYYY/MM/DD')} hasta: ${moment(new Date()).format('YYYY/MM/DD')}`,
      fecha_send: moment().format('YYYY/MM/DD'),
      fecha_ini: moment(fecha_newDate2).format('YYYY/MM/DD'),
      fecha_fin: moment(new Date()).format('YYYY/MM/DD'),
      type: 'Main',
      base64: dataUrl
    }

    DashboardService.SaveBase64(info).then(res => {
      console.log(res)
    })

  };

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <React.Fragment>
        <Header onSkin={setSkin} />
        <div className="main main-app p-3 p-lg-4">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Main Dasboard</li>
              </ol>
              <h4 className="main-title mb-0">Bienvenido al Dashboard Principal</h4>
            </div>

            <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
              <ReactDatePicker selected={startDate} onChange={(date) => { setStartDate(date); dispatch(SET_START_DATE({ startDate: date })) }} className="form-control" />
              <ReactDatePicker selected={endDate} onChange={(date) => { setEndDate(date); dispatch(SET_END_DATE({ endDate: date })) }} className="form-control" />
              {/* onClick={(e) => SearchDate()} */}
              <Button id="capture_button" variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => SearchDate()}>
                <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
              </Button>
              <Dropdown>
                <Dropdown.Toggle variant="primary">
                  Filtro Fecha
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Header>Rango de Fecha</Dropdown.Header>
                  <Dropdown.Item onClick={(e) => SetPeriodoFecha(1)}>Hoy</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => SetPeriodoFecha(2)}>Ultima Semana</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => SetPeriodoFecha(3)}>Ultimo Mes</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {/* <div id="element_main"> */}
            <Row className="g-3" id="element_main">
              {/* <div id="element_1"> */}
                <Col id="element_a" xl="5">
                  <Row className="g-3">
                    {[
                      {
                        "icon": "ri-store-3-fill",
                        "percent": {
                          "color": "success",
                          "amount": "+28.5%"
                        },
                        "value": `${valuePDV_reached}`,
                        "ifvalue2": `${false}`,
                        "label": "Total PDV Alcanzados",
                        "last": {
                          "color": "success",
                          "amount": "2.3%"
                        },
                        "url": "totalpdv"
                      }, {
                        "icon": "ri-wallet-3-fill",
                        "percent": {
                          "color": "danger",
                          "amount": "-3.8%"
                        },
                        "value": `${valueRate} Bs.`,
                        "ifvalue2": `${false}`,
                        "label": "Tasa de dolar Promedio",
                        "last": {
                          "color": "danger",
                          "amount": "0.5%"
                        },
                        "url": "Tasa"
                      },
                      // {
                      //   "icon": "ri-timer-fill",
                      //   "percent": {
                      //     "color": "danger",
                      //     "amount": "-8.4%"
                      //   },
                      //   "value": `${valueAVG_time} Horas`,
                      //   "ifvalue2": `${false}`,
                      //   "label": "Tiempo Promedio en cada Visita",
                      //   "last": {
                      //     "color": "danger",
                      //     "amount": "0.2%"
                      //   },
                      //   "url": "DetalleActivacion"
                      // }
                      {
                        "icon": "ri-timer-fill",
                        "percent": {
                          "color": "danger",
                          "amount": "-8.4%"
                        },
                        "value": `${valueAllSku} productos`,
                        "ifvalue2": `${false}`,
                        "label": "total de productos",
                        "last": {
                          "color": "danger",
                          "amount": "0.2%"
                        },
                        "url": "Skus"
                      }
                      , {
                        "icon": "ri-team-fill",
                        "percent": {
                          "color": "success",
                          "amount": `Total: ${valueCountPromoter + valueCountSupervisor}`
                        },
                        "value": `${valueCountSupervisor} Supervisores`,
                        "value2": `${valueCountPromoter} Promotores`,
                        "ifvalue2": `${true}`,
                        "label": `Hoy ${moment().format('DD/MM/YYYY')}`,
                        "last": {
                          "color": "success",
                          "amount": "5.8%"
                        },
                        "url": "DetallePlantilla"
                      }
                    ].map((item, index) => (
                      <Col xs="6" md="3" xl="6" key={index}>
                        <Card className="card-one card-product">
                          <Card.Body className="p-3">
                            <div className="d-flex icon-store fs-40 lh-1 text-ui-02 mb-5">
                              < div className="card-icon"><i className={item.icon}></i></div>
                              <i className="ri-eye-line icon-store-more" onClick={() => { GotoDetail(item.url) }}></i>
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-between mb-5">
                            <div className="card-icon"><i className={item.icon}></i></div>
                            <h6 className={"fw-normal ff-numerals mb-0 text-" + item.percent.color}>{item.percent.amount}</h6>
                          </div> */}
                            <h2 className="card-value ls--1">{item.value}</h2>
                            {item.value2 = !undefined &&
                              <h2 className="card-value ls--1">{item.value2}</h2>
                            }
                            <label className="card-label fw-medium text-dark">{item.label}</label>
                            <span className="d-flex gap-1 fs-xs">
                              <span className={"d-flex align-items-center text-" + item.last.color}>
                                <span className="ff-numerals">{item.last.amount}</span><i className={(item.last.color === 'success') ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
                              </span>
                              <span className="text-secondary">than last week</span>
                            </span>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col id="element_b" xl="7">
                  <Card className="card-one card-product-inventory">
                    <Card.Header>
                      <Card.Title as="h6">Inventario por Categoría</Card.Title>
                      <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link onClick={e => GotoDetail('InventarioCategoria')} className="icon-eye"><i className="ri-eye-line icon-store-more"></i></Nav.Link>
                        <Nav.Link onClick={e => GetInventory()} className="icon-refresh"><i className="ri-refresh-line"></i></Nav.Link>
                        <Dropdown className="btn-filter-dash filter-iventory-skin" align="end">
                          <Dropdown.Toggle>
                            <i className="ri-filter-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="mt-10-f">
                            <label>Filtros Disponibles</label>
                            <Row>
                              <Col xl="6">
                                <Card className="card-region">
                                  <Card.Body>
                                    <Select options={listRegionInventory} onChange={e => ChangeValueFiltro('region', e.value)} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xl="6">
                                <Card className="card-category">
                                  <Card.Body>
                                    <Select options={listCategoryInventory} onChange={(e) => ChangeValueFiltro('category', e.value)} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <Card className="card-cadena">
                                  <Card.Body>
                                    <Select options={listCadenaInventory} onChange={(e) => SetFiltrosInventory({ ...filtrosInventory, cadena: e.value })} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xl="6">
                                <Card className="card-establecimiento">
                                  <Card.Body>
                                    <Select options={listEstablecimientoInventory} onChange={(e) => ChangeValueFiltro('establecimiento', e.value)} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            <Row className="mt-4 row d-flex justify-content-end">
                              <Col xl="6" className="col-xl-6 d-sm-flex align-items-center justify-content-between">
                                <Button variant="secondary" className="d-flex align-items-center gap-2" onClick={e => ChangeFiltroInventory(true)}>
                                  Limpiar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-delete-bin-line fs-18 lh-1"></i>
                                </Button>
                                <Button variant="primary" className="d-flex align-items-center gap-2" onClick={e => ChangeFiltroInventory()}>
                                  Aplicar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-check-fill fs-18 lh-1"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                    </Card.Header>
                    <Card.Body className="p-3">
                      {chartInventoryReady == true &&
                        <ReactApexChart options={data_chart.options}
                          series={data_chart.data} type="bar" height={310} className="apex-chart-twelve" />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              {/* </div> */}

              {/* <div id="element_2"> */}
                <Col id="element_c" xl="7">
                  <Card className="card-one">
                    <Card.Header>
                      <Card.Title as="h6">Top 10 Promotores</Card.Title>
                      <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link onClick={e => GetTopPlantilla()} className="icon-refresh"><i className="ri-refresh-line"></i></Nav.Link>
                        <Dropdown className="btn-filter-dash filter-skin" align="end">
                          <Dropdown.Toggle>
                            <i className="ri-filter-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="mt-10-f">
                            <label>Filtros Disponibles</label>
                            {/* <Row>
                            <Card className="card-category">
                              <Card.Body>
                                <Select options={listCategory} onChange={(e) => SetFiltrosCategory({...filtrosCategory, category: e.value})} isSearchable={true} />
                              </Card.Body>
                            </Card>
                          </Row> */}
                            <Row>
                              <Card className="card-region">
                                <Card.Body>
                                  <Select options={listRegionPlantilla} onChange={(e) => SetFiltrosTopPlantilla({ ...filtrosTopPlantilla, region: e.value })} isSearchable={true} />
                                </Card.Body>
                              </Card>
                            </Row>
                            <Row className="mt-4 row d-flex justify-content-end">
                              <Col xl="6" className="col-xl-12 d-sm-flex align-items-center justify-content-between">
                                <Button variant="secondary" className="d-flex align-items-center gap-2" onClick={e => ChangeFiltrosTopPlantilla(true)}>
                                  Limpiar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-delete-bin-line fs-18 lh-1"></i>
                                </Button>
                                <Button variant="primary" className="d-flex align-items-center gap-2" onClick={e => ChangeFiltrosTopPlantilla()}>
                                  Aplicar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-check-fill fs-18 lh-1"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                    </Card.Header>
                    <Card.Body className="p-3">
                      <Table className="table-agent mb-0 table_top_plantilla" responsive>
                        <thead>
                          <tr>
                            <th>Promotor</th>
                            <th>Región</th>
                            <th className="th_status">Status</th>
                            <th className="th_store">Establecimientos</th>
                            <th className="th_store">Establecimientos Promedio</th>
                            {/* <th>Formularios</th>
                          <th>Productos</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataTop5Plantilla.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <h6 className="mb-0 text-uppercase">{item.NOMBRE_COMPLETO}</h6>
                              </td>
                              <td className="text-center text-uppercase"><span>{item.REGION}</span></td>
                              <td><span className="badge bg-primary">Activo</span></td>
                              <td className="text-center"><span>{item.TOTAL_ESTABLECIMIENTOS}</span></td>
                              <td className="text-center"><span>{item.pro_estable}</span></td>
                              {/* <td className="text-center"><span>{item.TOTAL_FORMULARIOS}</span></td> */}
                              {/* <td className="text-center"><span>{item.TOTAL_PRODUCTOS}</span></td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
                <Col id="element_d" xl="5">
                  <Card className="card-one">
                    <Card.Header>
                      <Card.Title as="h6">Top 5 SKU</Card.Title>
                      <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link onClick={e => GetTopSKU()} className="icon-refresh"><i className="ri-refresh-line"></i></Nav.Link>
                        <Dropdown className="btn-filter-dash filter-skin" align="end">
                          <Dropdown.Toggle >
                            <i className="ri-filter-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="mt-10-f">
                            <label>Filtros Disponibles</label>
                            <Row>
                              <Card className="card-category">
                                <Card.Body>
                                  <Select options={listCategorySKU} onChange={(e) => SetFiltrosTopSKU({ ...filtrosTopSKU, category: e.value })} isSearchable={true} />
                                </Card.Body>
                              </Card>
                            </Row>
                            <Row className="mt-4 row d-flex justify-content-end">
                              <Col xl="6" className="col-xl-12 d-sm-flex align-items-center justify-content-between">
                                <Button variant="secondary" className="d-flex align-items-center gap-2" onClick={e => ChangeFiltrosTopSKU(true)}>
                                  Limpiar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-delete-bin-line fs-18 lh-1"></i>
                                </Button>
                                <Button variant="primary" className="d-flex align-items-center gap-2" onClick={e => ChangeFiltrosTopSKU()}>
                                  Aplicar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-check-fill fs-18 lh-1"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                    </Card.Header>
                    <Card.Body className="p-3">
                      {dataTop5SKU.map((item, index) => (
                        index < 5 &&
                        <div className="revenue-item" key={index}>
                          <div className="revenue-item-body">
                            <span style={{ cursor: 'pointer' }} onClick={(e) => GridDetallePreciosWithFilters(item)} className="text-uppercase max-w-sku">{item.PRODUCTO}</span>
                            {/* <span className="col-5 text-uppercase">{item.CATEGORIA}</span> */}
                            {/* </div> */}
                            {/* <div className="revenue-item"> */}
                            <span className="span_sku_precio_dolar min-w-sku">${item.PRECIO_DOLAR}</span>
                            <span className="span_sku_precio_dolar min-w-sku">Bs. {item.PRECIO_BS}</span>
                          </div>
                          <ProgressBar className="progress_top_sku " now={item.porcentaje} label={'Cantidad: ' + item.TOTAL_PRODUCTOS} />
                        </div>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              {/* </div> */}

              {/* <div id="element_3"> */}
                <Col id="element_e" xl="12">
                  <Row className="g-3">
                    <Col xs="6" sm>
                      <Card className="card-one">
                        <Card.Body className="p-3">
                          <div className="d-flex icon-store fs-40 lh-1 text-ui-02 mb-1">
                            {/* <div className="d-flex"> */}
                            <i className="ri-store-3-line"></i>
                            {/* </div>
                          <div> */}
                            <i className="ri-eye-line icon-store-more" onClick={e => GoGridActivacionesMensuales(3)}></i>
                            {/* </div> */}
                          </div>
                          <h1 className="card-value mb-0 ls--1 fs-32">{valueCountActivation.mes}</h1>
                          <label className="d-block mb-1 fw-medium text-dark">Activaciones Mensual</label>
                          <small>
                            <span className="d-inline-flex text-danger">0.7% <i className="ri-arrow-down-line"></i></span> than last week
                          </small>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs="6" sm>
                      <Card className="card-one">
                        <Card.Body className="p-3">
                          <div className="d-flex icon-store fs-40 lh-1 text-ui-02 mb-1">
                            {/* <div className="d-flex"> */}
                            <i className="ri-store-3-line"></i>
                            {/* </div>
                          <div> */}
                            <i className="ri-eye-line icon-store-more" onClick={e => GoGridActivacionesMensuales(2)}></i>
                            {/* </div> */}
                          </div>
                          <h1 className="card-value mb-0 fs-32 ls--1">{valueCountActivation.sem}</h1>
                          <label className="d-block mb-1 fw-medium text-dark">Activaciones Semanal</label>
                          <small><span className="d-inline-flex text-success">1.2% <i className="ri-arrow-up-line"></i></span> than last week</small>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm>
                      <Card className="card-one">
                        <Card.Body className="p-3">
                          <div className="d-flex icon-store fs-40 lh-1 text-ui-02 mb-1">
                            {/* <div className="d-flex"> */}
                            <i className="ri-store-3-line"></i>
                            {/* </div>
                          <div> */}
                            <i className="ri-eye-line icon-store-more" onClick={e => GoGridActivacionesMensuales(1)}></i>
                            {/* </div> */}
                          </div>
                          <h1 className="card-value mb-0 fs-32 ls--1">{valueCountActivation.dia}</h1>
                          <label className="d-block mb-1 fw-medium text-dark">Activaciones Diaria</label>
                          <small><span className="d-inline-flex text-success">0.6% <i className="ri-arrow-up-line"></i></span> than last week</small>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs="6" sm>
                      <Card className="card-one">
                        <Card.Body className="p-3">
                          <div className="d-flex icon-store fs-40 lh-1 text-ui-02 mb-1">
                            {/* <div className="d-flex"> */}
                            <i className="ri-store-3-line"></i>
                            {/* </div>
                          <div> */}
                            <i className="ri-eye-line icon-store-more" onClick={e => GoGridSkuNoAlcanzados()}></i>
                            {/* </div> */}
                          </div>
                          <h1 className="card-value mb-0 fs-32 ls--1">{valueSKUNoLlegados}</h1>
                          <label className="d-block mb-1 fw-medium text-dark">SKU No Alcanzados</label>
                          <small><span className="d-inline-flex text-success">1.2% <i className="ri-arrow-up-line"></i></span> than last week</small>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs="6" sm>
                      <Card className="card-one">
                        <Card.Body className="p-3">
                          <div className="d-flex icon-store fs-40 lh-1 text-ui-02 mb-3">
                            {/* <div className="d-flex"> */}
                            <i className="ri-store-3-fill"></i>
                            {/* </div>
                          <div> */}
                            <i className="ri-eye-line icon-store-more" onClick={e => GoGridStoreNoLlegados()}></i>
                            {/* </div> */}
                          </div>
                          <h1 className="card-value mb-0 fs-32 ls--1">{valueStoreNoLlegados}</h1>
                          <label className="d-block mb-1 fw-medium text-dark">Establecimientos no Alcazados</label>
                          <small><span className="d-inline-flex text-success">1.2% <i className="ri-arrow-up-line"></i></span> than last week</small>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              {/* </div> */}

              {/* <div id="element_4"> */}
                <Col id="element_f" md="6" xl="4">
                  <Card className="card-one">
                    <Card.Header>
                      <Card.Title as="h6">Participación por Categoría</Card.Title>
                      <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link onClick={e => GoGridCompetenceByCategory()} className="icon-eye"><i className="ri-eye-line"></i></Nav.Link>
                        <Nav.Link onClick={e => GetCompetenceByCategory()} className="icon-refresh"><i className="ri-refresh-line"></i></Nav.Link>
                        <Dropdown className="btn-filter-dash filter-skin" align="end">
                          <Dropdown.Toggle>
                            <i className="ri-filter-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="mt-10-f">
                            <label>Filtros Disponibles</label>
                            <Row>
                              <Card className="card-category">
                                <Card.Body>
                                  <Select options={listCategory} onChange={(e) => SetFiltrosCategory({ ...filtrosCategory, category: e.value })} isSearchable={true} />
                                </Card.Body>
                              </Card>
                            </Row>
                            <Row>
                              <Card className="card-region">
                                <Card.Body>
                                  <Select options={listRegion} onChange={(e) => SetFiltrosCategory({ ...filtrosCategory, region: e.value })} isSearchable={true} />
                                </Card.Body>
                              </Card>
                            </Row>
                            <Row className="mt-4 row d-flex justify-content-end">
                              <Col xl="6" className="col-xl-12 d-sm-flex align-items-center justify-content-between">
                                <Button variant="secondary" className="d-flex align-items-center gap-2" onClick={e => ChangeCategory(true)}>
                                  Limpiar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-delete-bin-line fs-18 lh-1"></i>
                                </Button>
                                <Button variant="primary" className="d-flex align-items-center gap-2" onClick={e => ChangeCategory()}>
                                  Aplicar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-check-fill fs-18 lh-1"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>

                    </Card.Header>
                    <Card.Body className="p-3">

                      {labelPieCategory != '' &&
                        <h4 className="text-center">{labelPieCategory}</h4>
                      }

                      <div className="dash-category mb-4 mt-5">
                        <Doughnut data={dataPieCategory} options={optionPie} />
                      </div>

                      <Row className="g-4 mb-2">
                        <Col xs="6">
                          {/* <label className="fs-sm fw-medium mb-1">Región</label>
                        <h3 className="card-value mb-2">TODAS </h3> */}
                          <Card className="card-one">
                            <Card.Body>
                              <Card.Title as="label" className="fs-sm fw-medium mb-1">Región</Card.Title>
                              <h3 className="card-value mb-1"><i className="ri-road-map-fill"></i> {filtrosCategory.region}</h3>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs="6">
                          <Card className="card-one">
                            <Card.Body>
                              <Card.Title as="label" className="fs-sm fw-medium mb-1">Categoria</Card.Title>
                              <h3 className="card-value mb-1"><i className="ri-price-tag-3-fill"></i> {filtrosCategory.category}</h3>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col id="element_g" md="7" xl="8">
                  <Card className="card-one">
                    <Card.Header>
                      <Card.Title as="h6">Incidencias</Card.Title>
                      <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link onClick={e => GotoDetail('Incidencias')} className="icon-eye"><i className="ri-eye-line"></i></Nav.Link>
                        <Nav.Link onClick={e => GetIncidencias()} className="icon-refresh"><i className="ri-refresh-line"></i></Nav.Link>
                        <Dropdown className="btn-filter-dash filter-skin" align="end">
                          <Dropdown.Toggle>
                            <i className="ri-filter-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="mt-10-f">
                            <label>Filtros Disponibles</label>
                            <Row>
                              <Col xl="6">
                                <Card className="card-region">
                                  <Card.Body>
                                    <Select options={listRegionIncidencias} onChange={e => filterDataIncidencias('region', e.value)} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xl="6">
                                <Card className="card-promotor">
                                  <Card.Body>
                                    <Select options={listPromotorIncidencias} onChange={(e) => filterDataIncidencias('promotor', e.value)} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="6">
                                <Card className="card-gravedad">
                                  <Card.Body>
                                    <Select options={listGravedadIncidencias} onChange={(e) => filterDataIncidencias('gravedad', e.value)} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xl="6">
                                <Card className="card-tipo">
                                  <Card.Body>
                                    <Select options={listTipoIncidencias} onChange={(e) => filterDataIncidencias('tipo', e.value)} isSearchable={true} />
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            <Row className="mt-4 justify-content-end">
                              <Col xl="6">
                                <Button variant="secondary" onClick={e => GetIncidencias()}>
                                  Limpiar<span className="d-none d-sm-inline"> Filtros</span><i className="ri-delete-bin-line fs-18 lh-1"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                    </Card.Header>
                    <Card.Body className="p-3">
                      <Table className="table-agent mb-0 table_top_plantilla" responsive>
                        <thead>
                          <tr>
                            <th>Promotor</th>
                            <th className="text-center">Región</th>
                            <th className="th_store text-center">Tipo</th>
                            <th className="th_status text-center">Gravedad</th>
                            <th className="th_store text-center">fecha Incidencia</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataIncidencias.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <h6 className="mb-0 text-uppercase">{item.promotor}</h6>
                              </td>
                              <td className="text-center text-uppercase"><span>{item.region}</span></td>
                              <td><span className="text-center badge bg-primary text-uppercase">{item.tipo}</span></td>
                              <td><span className="text-center badge bg-primary text-uppercase">{item.gravedad}</span></td>
                              <td className="text-center"><span>{item.fecha_Reportado}</span></td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              {/* </div> */}
              
            </Row>
          {/* </div> */}

          <Footer />
        </div>
      </React.Fragment>
    </>
  )
}