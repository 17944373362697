import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import LoadPanel from 'devextreme-react/load-panel';
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { GlobalClose } from './components/GlobalClose';

import { authentication } from './firebase/firebase';
import { useDispatch } from "react-redux";
import { SET_ACTIVE_USER, REMOVE_ACTIVE_USER } from "./redux/slice/authSlice";
import axios from 'axios';

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const [carga, setcarga] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {

    axios.interceptors.request.use(
      (req) => {
        const token = "eyJhbGciOiJIUzI1NiJ9.amVyaXNtYXJ2QGdtYWlsLmNvbQ.ty-QB5b4NLvipLzhFRJBul_Ps5ZVhfkO4_GOevxQb5U"
        if (token) {
          req.headers['Authorization'] = 'Bearer ' + token
        }
        setcarga(true)
        return req;
      },
      (err) => {
        setcarga(false)
        return Promise.reject(err);
      }
    );

    axios.interceptors.response.use(
      (res) => {
        // Add configurations here

        if (res.status === 200) {
          setcarga(false)
          return res;
        }
        setcarga(false)
        return res;
      },
      (err) => {
        setcarga(false)
        return Promise.reject(err);
      }
    );

    authentication.onAuthStateChanged((user) => {
      if (user) {
        dispatch(SET_ACTIVE_USER({
          email: user.email,
          uid: user.uid,
        }))
      } else {
        dispatch(REMOVE_ACTIVE_USER())
      }
    });
  }, [dispatch])

  return (
      <HashRouter>
        <GlobalClose />
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={
                    <>
                      <LoadPanel visible={carga} loadPanelVisible={false} showIndicator={true} shading={true} showPane={true} hideOnOutsideClick={false} />
                      {route.element}
                    </>
                  }
                  key={index}
                />
              )
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={
                  <>
                    <LoadPanel visible={carga} loadPanelVisible={false} showIndicator={true} shading={true} showPane={true} hideOnOutsideClick={false} />
                    {route.element}
                  </>
                }
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
  );
}