import React, { useCallback, useRef, useState, useEffect } from 'react';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import Button2 from 'devextreme-react/button';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import { selectFiltersGridDP } from '../redux/slice/gridDetallePrecioSlice'
import { selectFilters } from '../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import DataGrid, { GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging, Export, Selection, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo, Toolbar, Item, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import List, { ListTypes } from 'devextreme-react/list';
import TreeView, { TreeViewTypes } from 'devextreme-react/tree-view';
import DropDownBox, { DropDownBoxTypes } from 'devextreme-react/drop-down-box';
import 'jspdf-autotable';

import Chart from 'react-apexcharts';

import * as ExtrasService from '../services/extras'

import Select from "react-select";
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from '../redux/slice/dateSlice';
import { REMOVE_DATA_FILTER_DP } from '../redux/slice/gridDetallePrecioSlice';

export default function ComparativaSKU() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(selectUser)
  const filters = useSelector(selectFiltersGridDP)
  const filtersSelect = useSelector(selectFilters)
  const globalDate = useSelector(selectDate);
  const [datos, SetDatos] = useState([]);
  const [dataCatCliente, SetDataCatCliente] = useState([])
  const [dataCatCompe, SetDataCatCompe] = useState([])
  const [dataCatCompe2, SetDataCatCompe2] = useState([])
  const [listStore, SetListStore] = useState([])
  
  const [filtersGrid, SetFiltersGrid] = useState([])  
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [treeViewReady, SetTreeView] = useState(false);
  const treeViewRef = useRef(null);
  const [treeBoxValue, setTreeBoxValue] = useState();
  const [valueFull, setValueFull] = useState();
  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
  
  const treeViewRef2 = useRef(null);
  const [treeBoxValue2, setTreeBoxValue2] = useState();
  const [value2Full, setValue2Full] = useState();
  const [isTreeBoxOpened2, setIsTreeBoxOpened2] = useState(false);
  
  const [chartReady, SetChartReady] = useState(false);
  const [seriesData, SetSeriesData] = useState([
    {
      id: 1,
      name: 'Cliente',
      data: [],
    },
    {
      id: 2,
      name: 'Competencia',
      data: [],
    },
  ])

  const [chartOptions, SetChartOptions] = useState({
    series: seriesData,
    options: {
      chart: {
        type: 'line',
      },
      xaxis: {
        categories: [],
      },
    },
  })

  const listCurrency = [
    { value: 'Dolares', label: 'Dolares' }, 
    { value: 'Bolivares', label: 'Bolivares' }
  ]

  const [currencySelected, setCurrencySelected] = useState(listCurrency[0]);
  const [storeSelected, setStoreSelected] = useState();
  const ownerLabel = { 'aria-label': 'Owner' };

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  // switchSkin(skin);

  useEffect(() => {
    // console.clear()
    // console.log('en este useeffect??')
    // console.log("🚀 ~ ComparativaSKU ~ filtersSelect:", filtersSelect)
    // console.log("🚀 ~ ComparativaSKU ~ skin:", skin)
    GetSKUs()


    switchSkin(skin);
  }, [skin, filtersSelect]);

  const test = () => {
    console.log('*******************************************')
    // console.log(dataCatCliente)
    // console.log(dataCatCompe2)
    // console.log(valueFull)
    // console.log(value2Full)
    // console.log(treeBoxValue2)
    // console.log(treeBoxValue)
    console.log('*******************************************')
  }

  useEffect(() => { 
    if(datos != undefined && storeSelected != undefined && currencySelected != undefined){
      AnalizarSKU() 
    }
  }, [datos, storeSelected, currencySelected]);

  useEffect(() => { 

    // console.log("🚀 ~ ComparativaSKU ~ treeBoxValue:", treeBoxValue)
    // console.log("🚀 ~ ComparativaSKU ~ valueFull:", valueFull)
    // console.log("🚀 ~ ComparativaSKU ~ treeBoxValue2:", treeBoxValue2)
    // console.log("🚀 ~ ComparativaSKU ~ value2Full:", value2Full)

    if(treeBoxValue != undefined && treeBoxValue != null){
      if(treeBoxValue[0] == valueFull.ID){
        if(valueFull.CATEGORY != value2Full?.CATEGORY){
          // console.log('hay que cambiar')
          let data = dataCatCompe.filter((x) => x.CATEGORY == valueFull.CATEGORY)
          // console.log("🚀 ~ useEffect ~ data:", data)
          SetDataCatCompe2(data)
        }
      }
    } else {
      SetDataCatCompe2([])
      setValue2Full(undefined)
    }

  }, [treeBoxValue, treeBoxValue2]);

  const GetSKUs = () => {
    SetTreeView(false)

    let value = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: value,
      region_name: region,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD')
    }
    // console.log("🚀 ~ GetDataGridDetallePrecios ~ info:", info)

    ExtrasService.GetListSKU(info).then(res => {
      let data = res.data

      // console.log("🚀 ~ ExtrasService.GetListSKU ~ data.categoria_competence:", data.categoria_competence)
      // console.log("🚀 ~ ExtrasService.GetListSKU ~ data.categoria_client:", data.categoria_client)
      // console.log("🚀 ~ file: StoreNoLlegados.js:82 ~ GridMainService.GetStoreNoLlegados ~ data:", data)
      SetDataCatCliente(data.categoria_client)
      SetDataCatCompe(data.categoria_competence)
      // console.log(dataCatCliente)
      // console.log(dataCatCompe)
      SetTreeView(true)
    })
  }

  const treeViewItemSelectionChanged = useCallback(
    (e) => {
      if(e.component.getSelectedNodes().length > 0){
        // console.log("🚀 ~ ComparativaSKU ~ e.component.getSelectedNodeKeys():", e.component.getSelectedNodeKeys())
        // console.log("🚀 ~ ComparativaSKU ~ e.component.getSelectedNodes()[0].itemData:", e.component.getSelectedNodes()[0].itemData)
        setTreeBoxValue(e.component.getSelectedNodeKeys());
        setValueFull(e.component.getSelectedNodes()[0].itemData);
      } },
    [],
  );

  const treeViewOnContentReady = useCallback(
    (e) => {
      e.component.selectItem(treeBoxValue);
    },
    [treeBoxValue],
  );

  const onTreeItemClick = useCallback(() => {
    setIsTreeBoxOpened(false);
  }, []);

  const syncTreeViewSelection = useCallback((e) => {
    setTreeBoxValue(e.value);
    if (!treeViewRef.current) return;

    if (!e.value) {
      treeViewRef.current.instance.unselectAll();
    } else {
      treeViewRef.current.instance.selectItem(e.value);
    }
  }, []);

  const onTreeBoxOpened = useCallback((e) => {
    if (e.name === 'opened') {
      setIsTreeBoxOpened(e.value);
    }
  }, []);

  const treeViewItemSelectionChanged2 = useCallback(
    (e) => {
      // console.log("🚀 ~ ComparativaSKU ~ e.component.getSelectedNodeKeys():", e.component.getSelectedNodes())
      setTreeBoxValue2(e.component.getSelectedNodeKeys());
      if(e.component.getSelectedNodes().length > 0){
        setValue2Full(e.component.getSelectedNodes()[0].itemData);
      }
    },
    [],
  );

  const treeViewOnContentReady2 = useCallback(
    (e) => {
      e.component.selectItem(treeBoxValue2);
    },
    [treeBoxValue2],
  );

  const onTreeItemClick2 = useCallback(() => {
    setIsTreeBoxOpened2(false);
  }, []);

  const syncTreeViewSelection2 = useCallback((e) => {
    setTreeBoxValue2(e.value);
    if (!treeViewRef2.current) return;

    if (!e.value) {
      treeViewRef2.current.instance.unselectAll();
    } else {
      treeViewRef2.current.instance.selectItem(e.value);
    }
  }, []);

  const onTreeBoxOpened2 = useCallback((e) => {
    if (e.name === 'opened') {
      setIsTreeBoxOpened2(e.value);
    }
  }, []);

  const treeViewRender2 = useCallback(
    () => (
      <TreeView
        dataSource={dataCatCompe2}
        ref={treeViewRef2}
        selectionMode="single"
        keyExpr="ID"
        parentIdExpr="PARENTID"
        dataStructure="plain"
        showCheckBoxesMode="normal"
        displayExpr="text"
        selectByClick={true}
        onContentReady={treeViewOnContentReady2}
        onItemClick={onTreeItemClick2}
        onItemSelectionChanged={treeViewItemSelectionChanged2}
      />
    ),
    [treeViewRef2, treeViewOnContentReady2, onTreeItemClick2, treeViewItemSelectionChanged2, dataCatCompe2],
  );

  const treeViewRender = useCallback(
    () => (
      <TreeView
        dataSource={dataCatCliente}
        ref={treeViewRef}
        keyExpr="ID"
        selectionMode="single"
        parentIdExpr="PARENTID"
        dataStructure="plain"
        showCheckBoxesMode="normal"
        displayExpr="text"
        selectByClick={true}
        onContentReady={treeViewOnContentReady}
        onItemClick={onTreeItemClick}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    ),
    [treeViewRef, treeViewOnContentReady, onTreeItemClick, treeViewItemSelectionChanged, dataCatCliente],
  );

  const BuscarSKUs = () => {
    // console.log(startDate)
    // console.log(endDate)
    GetSKUs()
  }

  const GetDataSKU = () => {
    let sku_client = valueFull?.PRODUCT
    // console.log("🚀 ~ GetDataSKU ~ valueFull:", valueFull)
    let sku_compe = value2Full?.PRODUCT
    let list_sku = [sku_client, sku_compe]

    let value = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: value,
      region_name: region,
      list_sku: list_sku,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD')
    }
    // console.log("🚀 ~ GetDataSKU ~ info:", info)

    ExtrasService.CompareSKU(info).then(resp => {
      let data = resp.data
      console.log('-----------------------')
      // console.log(data)
      SetDatos(data)
      SetListStore(data.data_stores)
      setStoreSelected(data.data_stores[0].items[0])
      console.log('-----------------------')
    })


  }

  function ItemTemplate(data) {
    return <div onClick={() => {setStoreSelected(data); }}>{data}</div>;
  }

  const SelectStore = (e) => {
    // console.log("🚀 ~ SelectStore ~ e:", e)
    setStoreSelected(e.itemData.text)
    // console.log(store)
    // console.log(datos)
    // console.log(currencySelected)
    // setStoreSelected(store)
    // let data1 = []
    // if(currencySelected.value == 'BS'){
    //   data1 = datos.data_bs.filter((x) => x.STORE_FORM == store)
    // } else {
    //   data1 = datos.data_ds.filter((x) => x.STORE_FORM == store)
    // }     
    // console.log("🚀 ~ SelectStore ~ data1:", data1)

    // let data_client = data1.filter((x) => x.TYPE == 'Client')
    // console.log("🚀 ~ SelectStore ~ data_client:", data_client)
    // let data_competence = data1.filter((x) => x.TYPE == 'Client')
    // console.log("🚀 ~ SelectStore ~ data_competence:", data_competence)
  }

  const SelectCurrency = (currency) => {
    // console.log(currency)
    setCurrencySelected(currency)
  }

  const AnalizarSKU = () => {
    SetChartReady(false)
    // console.log("🚀 ~ AnalizarSKU ~ currencySelected:", currencySelected)
    // console.log("🚀 ~ AnalizarSKU ~ storeSelected:", storeSelected)
    // console.log("🚀 ~ AnalizarSKU ~ datos:", datos)

    let data1 = []
    if(currencySelected.value == 'Bolivares'){
      data1 = datos.data_bs.filter((x) => x.STORE_FORM == storeSelected)
    } else {
      data1 = datos.data_ds.filter((x) => x.STORE_FORM == storeSelected)
    }     
    // console.log("🚀 ~ SelectStore ~ data1:", data1)

    let fechas = []
    let data_client = []
    let data_client2 = []
    let data_competence = []
    let data_competence2 = []
    data1.forEach(element => {
      fechas.push(element.DATE_FORM)
      if(element.TYPE == 'Client'){
        data_client2.push(Number(element.ANSWER))
        data_client.push(element)
      } 
      else if(element.TYPE == 'Competence') {
        data_competence.push(element)
        data_competence2.push(Number(element.ANSWER))
      }
    })

    fechas = [...new Set(fechas)]
    let options = chartOptions
    options.options.xaxis.categories = fechas

    let series = seriesData

    series[0].data = data_client2
    series[0].name = valueFull.PRODUCT

    series[1].data = data_competence2
    series[1].name = value2Full?.PRODUCT

    // console.log("🚀 ~ AnalizarSKU ~ series:", series)
    // console.log("🚀 ~ AnalizarSKU ~ options:", options)
    SetSeriesData(series)
    SetChartOptions(options)
    setTimeout(() => {
      SetChartReady(true)
    }, 500);
    // console.log("🚀 ~ AnalizarSKU ~ fechas:", fechas)
    // let data_client = data1.filter((x) => x.TYPE == 'Client')
    // console.log("🚀 ~ SelectStore ~ data_client:", data_client)
    // let data_competence = data1.filter((x) => x.TYPE != 'Client')
    // console.log("🚀 ~ SelectStore ~ data_competence:", data_competence)
  }

  const GroupTemplate = (item) => <div>Región: {item.key}</div>;

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0" onClick={test}>Detalle Precios</h4>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">

          </div>
        </div>

        <div className="height-grid-75">
          <div className='row '>
            <div className='col-2'>
              <ReactDatePicker selected={startDate} onChange={(date) => { setStartDate(date) }} className="form-control" />
            </div>
            <div className='col-2'>
              <ReactDatePicker selected={endDate} onChange={(date) => { setEndDate(date) }} className="form-control" />
            </div>
            <div className='col-1'>
              <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => BuscarSKUs()}>
                <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
              </Button>
            </div>

            <div className='col-3'>
              {/* {dataCatCliente.length > 0 && */}
                <DropDownBox
                  value={treeBoxValue}
                  valueExpr="ID"
                  opened={isTreeBoxOpened}
                  inputAttr={ownerLabel}
                  displayExpr="text"
                  placeholder="Seleccionar SKU..."
                  showClearButton={true}
                  dataSource={dataCatCliente}
                  onValueChanged={syncTreeViewSelection}
                  onOptionChanged={onTreeBoxOpened}
                  contentRender={treeViewRender}
                  />  
                  {/* placeholder="Seleccionar SKU..." */}
              {/* } */}
            </div>
            <div className='col-3'>
            {/* {dataCatCompe.length > 0 && */}
              <DropDownBox
                value={treeBoxValue2}
                opened={isTreeBoxOpened2}
                inputAttr={ownerLabel}
                valueExpr="ID"
                displayExpr="text"
                placeholder="Seleccionar SKU..."
                showClearButton={true}
                dataSource={dataCatCompe2}
                onValueChanged={syncTreeViewSelection2}
                onOptionChanged={onTreeBoxOpened2}
                contentRender={treeViewRender2}
                disabled={dataCatCompe.length == 0}
              />  
            {/* } */}
            </div>
            <div className='col-1'>
              <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => GetDataSKU()} 
              disabled={treeBoxValue == null || treeBoxValue == undefined }>
                <i className="ri-line-chart-line fs-18 lh-1"></i>Analizar<span className="d-none d-sm-inline"></span>
              </Button>
            </div>
          </div>

        </div>
        
        <div className='row'>
          <div className='col-md-4 mt-4'>
            <List
              dataSource={listStore}
              height={500}
              grouped={true}
              groupRender={GroupTemplate}
              collapsibleGroups={true}
              searchEnabled={true}
              onItemClick={SelectStore}
              searchMode='contains' />
          </div>
              {/* itemRender={ItemTemplate} */}
          

          <div className='col-md-8 mt-4'>
            <div className='row'>
              <div className='col-md-6' style={{margin: 'auto'}}>
                <span>
                  Establecimiento: {storeSelected}
                </span>
              </div>
              <div className='col-md-6 row'>
                <div className='col-md-4' style={{margin: 'auto'}}>
                  <span className='mr-2'>
                    Tipo de Moneda
                  </span>
                </div>
                <div className='col-md-8'>
                  <Select options={listCurrency} value={currencySelected} onChange={(e) => { setCurrencySelected(e); }} isSearchable={true} />
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              {/* <div> */}
                {chartReady == true &&
                  <Chart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type="line"
                    height={450}
                  />
                }
              {/* </div> */}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  )
}