import settings from "./enviroment";
import Axios from "axios";

const urlAnalytics = settings.API_URL + "analytics/";

export const getPDV_Client = (info) => {
  let req = {method: 'sp_get_pdv_reached_client', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetCountActivation = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "getActivation", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const getRate_Client = (info) => {
  let req = {method: 'sp_get_avg_rate', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const getAVG_visit_time = (info) => {
  let req = {method: 'sp_get_avg_visit_time', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};


export const getParticipacionXCategoria = (info) => {
  const promise = Axios.post(urlAnalytics + "getParticipacionXCategoria", info);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const getTopPlantilla = (info) => {
  const promise = Axios.post(urlAnalytics + "getTopPlantilla", info);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const getTopSKU = (info) => {
  const promise = Axios.post(urlAnalytics + "getTopSKU", info);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const getInventory = (info) => {
  const promise = Axios.post(urlAnalytics + "getInventory", info);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetStoreNoLlegados = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "getStoreNoLlegados", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetSKUNoLlegados = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "getSKUNoLlegados", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const GetSKULessTrafic = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "getSKULessTrafic", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const callMethodDynamic = (info) => {
  let req = info
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => {  return response; });
  return dataPromise;
};

export const GetAllSku = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "getAllSku", req);
  const dataPromise = promise.then((response) => {  return response; });
  return dataPromise;
};

export const GetIncidences = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "getIncidences", req);
  const dataPromise = promise.then((response) => {  return response; });
  return dataPromise;
};

export const SaveBase64 = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "saveBase64", req);
  const dataPromise = promise.then((response) => {  return response; });
  return dataPromise;
};



  