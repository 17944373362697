import React, { useCallback, useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import Button2 from 'devextreme-react/button';
import { selectFilters } from '../redux/slice/configSlice'
import { DataGrid, GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Export, Item, Toolbar, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import { saveAs } from "file-saver";
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import * as GridMainService from '../services/grid_main_dashboard'

import { SelectBox } from "devextreme-react";

export default function GridDetallePlantilla() {

    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [dataGrid, setDataGrid] = useState([]);
    const [skin, setSkin] = useState(currentSkin);
    const [expandAll, setExpandAll] = useState(true);
    const [groupColumn, setGroupColumn] = useState('NAME');

    const dataGridRef = useRef(null);
    const userData = useSelector(selectUser);
    const filtersSelect = useSelector(selectFilters);

    const groupingValues = [{
        value: 'NAME',
        text: 'Agrupar por Cargo',
    },
    {
        value: 'NAME_REGION',
        text: 'Agrupar por Region',
    }];

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    const GetDataGridPlantilla = () => {
        let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;
        let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region;

        let info = {
            client_name: client,
            region_name: region,
        }

        GridMainService.GetDetallePlantilla(info).then(res => {
            let data = res.data;
            console.log("🚀 ~ GridMainService.GetDetallePlantilla ~ data:", data)

            data.forEach(element => {
                element.NOMBRE_COMPLETO = element.FIRST_NAME.toUpperCase() + ' ' + element.LAST_NAME.toUpperCase()
            });

            setDataGrid(data)
        })
    }

    const toggleExpandAll = useCallback(() => {
        setExpandAll(!expandAll);
    }, [expandAll]);

    const toggleGroupColumn = useCallback((e) => {
        const newGrouping = e.value;

        dataGridRef.current.instance.clearGrouping();
        dataGridRef.current.instance.columnOption(newGrouping, 'groupIndex', 0);

        setGroupColumn(newGrouping);
    }, []);

    const onExportPDF = async () => {
        const dataGrid = dataGridRef.current.instance;

        const dataSource = dataGrid.option("dataSource");

        const pdf = new jsPDF();

        const headers = dataGrid.getVisibleColumns().map(column => column.caption);

        const columnStyles = {
            0: { cellWidth: 40 },
            1: { cellWidth: 100 },
            2: { cellWidth: 40 },
        };

        pdf.autoTable({
            head: [headers],
            body: [],
            columnStyles: columnStyles,
        });

        dataSource.forEach(item => {
            pdf.autoTable({
                body: [dataGrid.getVisibleColumns().map(column => item[column.dataField] || '')],
                columnStyles: columnStyles,
            });
        });

        pdf.save('Listado_de_Plantilla.pdf');
    };

    const onExportXLS = async () => {
        const dataGrid = dataGridRef.current.instance;

        const dataSource = dataGrid.option('dataSource');

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('DataGrid Data');

        const headers = dataGrid.getVisibleColumns().map(column => column.caption);
        worksheet.addRow(headers);

        dataSource.forEach(item => {
            const rowData = dataGrid.getVisibleColumns().map(column => item[column.dataField] || '');
            worksheet.addRow(rowData);
        });

        const blob = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([blob], { type: 'application/octet-stream' }), 'Listado_de_Plantilla.xlsx');
    };

    useEffect(() => {
        GetDataGridPlantilla();

        switchSkin(skin);
    }, [skin, filtersSelect]);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Listado de Plantilla</h4>
                    </div>
                </div>

                <div className="height-grid-75">
                    <DataGrid
                        ref={dataGridRef}
                        dataSource={dataGrid}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        columnAutoWidth={true}
                        showRowLines={false}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        displayMode='full'
                        style={{ height: '75vh', width: '100%' }}
                    >
                        <Grouping autoExpandAll={expandAll} />
                        <GroupPanel visible={true} />
                        <FilterRow visible={true} />
                        <FilterPanel visible={true} />
                        <ColumnChooser enabled={true} />
                        <Scrolling rowRenderingMode='infinity'></Scrolling>
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} />
                        <Export enabled={true} />

                        <Toolbar>
                            <Item location="after">
                                <SelectBox
                                    width="225"
                                    items={groupingValues}
                                    displayExpr="text"
                                    valueExpr="value"
                                    value={groupColumn}
                                    onValueChanged={toggleGroupColumn} />
                            </Item>
                            <Item location="after">
                                <Button2
                                    text={expandAll ? 'Collapsar' : 'Expandir'}
                                    width='136'
                                    onClick={toggleExpandAll} />
                            </Item>
                            <Item location="after">
                                <Button2
                                    icon='refresh'
                                    onClick={GetDataGridPlantilla}
                                />
                            </Item>
                            <Item location="after">
                                <Button2
                                    icon='pdffile'
                                    onClick={onExportPDF}
                                />
                            </Item>
                            <Item location="after">
                                <Button2
                                    icon='xlsfile'
                                    onClick={onExportXLS}
                                />
                            </Item>
                            <Item name="columnChooserButton" />
                        </Toolbar>

                        <Column dataField='NOMBRE_COMPLETO' caption='Nombre Promotor' >
                            <HeaderFilter allowSelectAll={true}>
                                <Search enabled={true} />
                            </HeaderFilter>
                        </Column>
                        <Column dataField='NAME_REGION' caption='Region' >
                            <HeaderFilter allowSelectAll={true}>
                                <Search enabled={true} />
                            </HeaderFilter>
                        </Column>
                        <Column dataField='NAME' caption='Cargo' groupIndex={0} >
                            <HeaderFilter allowSelectAll={true}>
                                <Search enabled={true} />
                            </HeaderFilter>
                        </Column>
                    </DataGrid>
                </div>
                <Footer />
            </div>
        </React.Fragment>
    )
}