import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    startDate: null,
    endDate: null,
    testDate: null
}

const dateSlice = createSlice({
    name: "date",
    initialState,
    reducers: {
        SET_START_DATE: (state, action) => {
            state.startDate = action.payload.startDate;
        },
        SET_END_DATE: (state, action) => {
            state.endDate = action.payload.endDate;
        },
        SET_DATE: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        REMOVE_DATE: (state, action) => {
            state.startDate = null;
            state.endDate = null;
        }
    }
});

export const { SET_START_DATE, SET_END_DATE, SET_DATE, REMOVE_DATE } = dateSlice.actions;

export const selectDate = (state) => state.date;

export default dateSlice.reducer;
