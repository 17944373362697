import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id_client: null,
    fecha_ini: null,
    fecha_fin: null,
    tag: null,
    pregunta: null,
    respuesta: null,
}

const gridEncuestasSlice = createSlice({
    name: "grid_encuestas",
    initialState,
    reducers: {
        SET_DATA_FILTER: (state, action) => {
            const { id_client, fecha_ini, fecha_fin, tag, pregunta, respuesta } = action.payload;
            state.id_client = id_client;
            state.fecha_ini = fecha_ini;
            state.fecha_fin = fecha_fin;
            state.tag = tag;
            state.pregunta = pregunta;
            state.respuesta = respuesta
        },
        REMOVE_DATA_FILTER: (state, action) => {
            state.id_client = false
            state.fecha_ini = null
            state.fecha_fin = null
            state.tag = null
            state.pregunta = null
            state.respuesta = null
        }
    }
});

export const { SET_DATA_FILTER, REMOVE_DATA_FILTER } = gridEncuestasSlice.actions;

export const selectFiltersGrid = (state) => state.grid_encuestas;

export default gridEncuestasSlice.reducer;