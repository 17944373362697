import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    client: null,
    region: 'TODAS LAS REGIONES'
}

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        SET_FILTERS: (state, action) => {
            console.log('estoy aqui', action)
            const {client, region} = action.payload;
            state.client = client
            state.region = region
        },
        REMOVE_FILTERS: (state, action) => {
            state.client = null;
            state.region = null;
        }
    }
});

export const { SET_FILTERS, REMOVE_FILTERS } = configSlice.actions;

// export const selectClient = (state) => state.config.client;

export const selectFilters = (state) => state.config;

export default configSlice.reducer;