import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Badge, Card, Col, Nav, ProgressBar, Row, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Avatar from "../components/Avatar";
import { Link } from "react-router-dom";

import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'

import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img13 from "../assets/img/img13.jpg";
import img15 from "../assets/img/img15.jpg";
import img18 from "../assets/img/img18.jpg";
import img19 from "../assets/img/img19.jpg";

export default function MainGrids() {

  const userData = useSelector(selectUser)
  
  useEffect(() => {
    // console.clear()
    console.log("🚀 ~ file: MainGrids.js:25 ~ MainGrids ~ userData:", userData)
    document.body.classList.add("app-task");
    return () => {
      document.body.classList.remove("app-task");
    };
  }, []);

  const CloneGrid = (grid) => {
    console.log(grid)
  }

  const DeleteGrid = (grid) => {
    console.log(grid)
  }

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4 p-5">
        <div className="cards-grids">
          <Row xs={2} className="g-2 div-card">
            {[
              {
                badge: [
                  {
                    bg: "success",
                    label: "Design",
                  },
                ],
                date: "Today",
                title: "Create illustrations",
                assignee: "Dyanne Rose",
                text: "Lorem ipsum dolor sit amet, consec tetur adi piscing elit folor itamet...",
                progress: 54,
                hour: "3 hours left",
                mutual: [img15, img12, img18, img19],
                views: 11,
                comments: 2,
              },
              {
                badge: [
                  {
                    bg: "info",
                    label: "Promotions",
                  },
                ],
                date: "Tomorrow",
                title: "Build promotion strategy",
                assignee: "Reynante Labares",
                progress: 74,
                hour: "1 day left",
                mutual: [img18, img19],
                views: 40,
                comments: 5,
              },
              {
                badge: [
                  {
                    bg: "pink",
                    label: "Marketing",
                  },
                ],
                date: "Aug 08",
                title: "Run market research",
                assignee: "Leah Chua",
                progress: 20,
                hour: "3 days left",
                mutual: [img18, img19, img10],
                views: 1,
                comments: 0,
              },
              {
                badge: [
                  {
                    bg: "success",
                    label: "Design",
                  },
                  {
                    bg: "primary",
                    label: "Updates",
                  },
                ],
                date: "Aug 10",
                title: "Architecto beatae vitae dicta",
                assignee: "Rolando Paloso",
                text: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...",
                progress: 54,
                hour: "5 days left",
                mutual: [img15, img12, img18, img19, img11, img10],
                views: 20,
                comments: 5,
              },
            ].map((task, index) => (
              <Col>
                <Card className="card-task" key={index}>
                  <Card.Body className="p-3 pb-1">
                    <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                      <span className="switch_active"><Form.Check type="switch"/></span>
                      <Card.Title as="h6">{task.title}</Card.Title>
                    </div>
                    {task.text && <p className="fs-sm">{task.text}</p>}
                  </Card.Body>
                  <Card.Footer className="p-3 pt-0">
                    <div className="d-flex gap-1">
                      {task.badge.map((badge, ind) => (
                        <Badge key={ind} bg={badge.bg}>
                          {badge.label}
                        </Badge>
                      ))}
                    </div>
                    <Nav as="nav">
                      <Nav.Link>
                        <div className="button_action_grid" onClick={() => CloneGrid(task)}>
                          <i className="ri-drag-drop-fill"></i>
                        </div>
                      </Nav.Link>
                      <Nav.Link>
                        <div className="button_action_grid" onClick={() => DeleteGrid(task)}>
                          <i className="ri-delete-bin-7-line"></i>
                        </div>
                      </Nav.Link>
                    </Nav>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
