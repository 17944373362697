import settings from "./enviroment";
import Axios from "axios";

const urlAnalytics = settings.API_URL + "analytics/";

export const GetListClients = () => {
  let req = {method: 'sp_quiz_list_clients', params: {}}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetQuizXRegion = (info) => {
  let req = {method: 'sp_quiz_trans_xregion', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetMarcaConoce = (info) => {
  let req = {method: 'sp_quiz_marca_conoce', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetOtrasMenciones = (info) => {
  let req = {method: 'sp_quiz_otras_menciones', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetPorquePrefiere = (info) => {
  let req = {method: 'sp_quiz_porque_prefiere', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetDisponibleStore = (info) => {
  let req = {method: 'sp_quiz_disponible_store', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetQueHacer = (info) => {
  let req = {method: 'sp_quiz_que_hacer', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetUltimaCompra = (info) => {
  let req = {method: 'sp_quiz_ultima_compra', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetFrecuenciaCompra = (info) => {
  let req = {method: 'sp_quiz_frecuencia_compra', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};  

export const GetMarcaFavo = (info) => {
  let req = {method: 'sp_quiz_marca_favo', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
}; 

export const DataGridByTagAndAnswer = (info) => {
  let req = {method: 'sp_quiz_grid_by_respuesta', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
}; 

export const GetLabels = (info) => {
  let req = {method: 'sp_quiz_get_labels', params: info}
  const promise = Axios.post(urlAnalytics + "callProcedure", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
}; 

export const SaveBase64 = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "saveBase64", req);
  const dataPromise = promise.then((response) => {  return response; });
  return dataPromise;
};





