import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import { selectFilters } from '../redux/slice/configSlice'
import { SET_DATA_FILTER } from '../redux/slice/gridSincronizacionesSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { DataGrid, GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging, Export, Selection } from 'devextreme-react/data-grid';
import ScrollView from "devextreme-react/scroll-view";
import { Popup, ToolbarItem } from "devextreme-react/popup";

import * as GridMainService from '../services/grid_main_dashboard'

import Select from "react-select";
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from "../redux/slice/dateSlice";

export default function GridSincronizaciones() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(selectUser)
  const globalDate = useSelector(selectDate);
  const filtersSelect = useSelector(selectFilters);
  const [isLoading, setisLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [dataGrid, SetDataGrid] = useState([]);
  const [showpictures, setShowpictures] = useState([]);
  const [startDate, setStartDate] = useState(new Date(globalDate.startDate));
  const [endDate, setEndDate] = useState(new Date(globalDate.endDate));

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const data = {
    "promotor": "GENESIS  ISTURIZ",
    "fecha_Sincronizado": "2023-10-23 11:21:37",
    "establecimiento": "PLAZA´S SANTA EDUVIGIS",
    "fecha_Formulario": "2023-10-23 08:23:52",
    "fecha_Reporte": "2023-10-23",
    "razon_social": "",
    "coordenadas": "10.5039453,-66.8397329",
    "uid_promotor": "iFdxdhhnFuOyFaKuzl3fKrLPRSF2",
    "version": "1.0.4",
    "nombre": "FUNDAMENTAL",
    "tasa": 34.98,
    "fecha_Completado": "2023-10-23 11:21:34",
    "cliente": "C.A RON SANTA TERESA S.A.C.A",
    "estatus": "Completo",
    "ciudad": "CARACAS",
    "moneda": "$",
    "preguntas": [
      {
        "hijos": [
          {
            "subcategoria": "RONES SUP. PREMIUM",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "ST 1796 SPEYSIDE",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "ST 1796 0,75",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "nombre": "COMPETENCIA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "32.14",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cacique Leyenda",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "36.32",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "2",
                          "Total": "4",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Diplomático Res. Exc.",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Aniversario Cuero",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "28.57",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Aniversario Estuche",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "46.85",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "2",
                          "Total": "2",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carupano 21",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "34.54",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carupano 18",
                    "competencia": true
                  }
                ]
              }
            ]
          }
        ],
        "categoria": "RONES SUP. PREMIUM",
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "categoria": "RONES PREMIUM",
        "hijos": [
          {
            "subcategoria": "RONES PREMIUM",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "17.03",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "20",
                          "Caras": "5"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Linaje 0,70L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Linaje 0,75L",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Diplomatico Mantuano",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Pampero Selección",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "22.01",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cacique 500",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "17.74",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carupano 12",
                    "competencia": true
                  }
                ],
                "nombre": "COMPETENCIA"
              }
            ]
          }
        ],
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "categoria": "RONES ESTANDAR",
        "hijos": [
          {
            "subcategoria": "RONES ESTANDAR",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "9.86",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "24",
                          "Caras": "6"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Gran Reserva 0,70L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Gran Reserva 0,75L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Gran Reserva 1L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Gran Reserva 1,75L",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "11.90",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "4",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cacique Añejo 0,75L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cacique Añejo 1L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cacique Añejo 1,75L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Pampero Especial",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "10.68",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carupano 6 años",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Calazan Ron Añejo",
                    "competencia": true
                  }
                ],
                "nombre": "COMPETENCIA"
              }
            ]
          }
        ],
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "hijos": [
          {
            "subcategoria": "RONES ESTANDAR BLANCO",
            "marcas": [
              {
                "nombre": "SANTA TERESA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "9.73",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "12",
                          "Caras": "3"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Santa Teresa Blanco",
                    "competencia": false
                  }
                ]
              },
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Diplomatico Planas",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Calazan Blanco",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Ocumare Blanco",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cinco Estrellas Blanco",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Canaima Blanco",
                    "competencia": true
                  }
                ],
                "nombre": "COMPETENCIA"
              }
            ]
          }
        ],
        "categoria": "RONES ESTANDAR BLANCO",
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "categoria": "LICORES DULCES",
        "hijos": [
          {
            "subcategoria": "LICORES DULCES",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "14.59",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Arakú",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Jägermeister",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "18.77",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Rhum Orange",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "nombre": "COMPETENCIA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "16.54",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Kahlua",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "21.81",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Frangelico",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "22.39",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "9",
                          "Caras": "3"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cointreau",
                    "competencia": true
                  }
                ]
              }
            ]
          }
        ],
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "categoria": "DESTILADOS",
        "hijos": [
          {
            "subcategoria": "DESTILADOS",
            "marcas": [
              {
                "nombre": "SANTA TERESA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carta Roja 0,70L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "5.45",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "2",
                          "Total": "8",
                          "Caras": "4"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carta Roja 1L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Superior 0,70L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "4.48",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "2",
                          "Total": "8",
                          "Caras": "4"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Superior 1L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "BES Dragon",
                    "competencia": false
                  }
                ]
              },
              {
                "nombre": "COMPETENCIA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cinco Estrellas 0,35L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cinco Estrellas 0,70L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "5.79",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "21",
                          "Caras": "7"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cinco Estrellas 1L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cinco Estrellas 1,75L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "5.96",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "20",
                          "Caras": "5"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cacique Moneda de Oro 0,70L ",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "7.13",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "12",
                          "Caras": "4"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Cacique Moneda de Oro 1L ",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Canaima",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "4.47",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carupano Extra",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "7.70",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "16",
                          "Caras": "4"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Carupano Black",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "4.61",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Rumbaron",
                    "competencia": true
                  }
                ]
              }
            ]
          }
        ],
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "hijos": [
          {
            "subcategoria": "WHISKY SECUNDARIOS",
            "marcas": [
              {
                "nombre": "SANTA TERESA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "10.29",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Clan Mac Gregor 0,75L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Clan Mac Gregor 1L",
                    "competencia": false
                  }
                ]
              },
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "10.92",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Williams Lawson's 0,75L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "13.92",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Williams Lawson's 1L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "13.34",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "100 Pipers",
                    "competencia": true
                  }
                ],
                "nombre": "COMPETENCIA"
              }
            ]
          }
        ],
        "categoria": "WHISKY SECUNDARIOS",
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "hijos": [
          {
            "subcategoria": "WHISKY 8 AÑOS",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "15.98",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "9",
                          "Caras": "3"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Grant's Triple Wood 0,75L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Grant's Triple Wood 1 L",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "nombre": "COMPETENCIA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "14.56",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "4",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Dewar's (White Label) 0,75L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Dewar's (White Label) 1L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "JW Red Label",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "20.59",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Something Special",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Black & White 0,75 L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "19.37",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Black & White 1L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Chequers 1L",
                    "competencia": true
                  }
                ]
              }
            ]
          }
        ],
        "categoria": "WHISKY 8 AÑOS",
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "categoria": "WHISKY 12 AÑOS",
        "hijos": [
          {
            "subcategoria": "WHISKY 12 AÑOS",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "27.39",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Grant's (12)",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "nombre": "COMPETENCIA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "27.09",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "4",
                          "Total": "8",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Dewar´s 12",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "36.06",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Buchanan´s 12",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "32.25",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "JW Black Label",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "33.21",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Old Parr 12 0,70L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Old Parr 12 1L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "31.80",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "3",
                          "Caras": "1"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Chivas Regal 12",
                    "competencia": true
                  }
                ]
              }
            ]
          }
        ],
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "categoria": "WHISKY 18 AÑOS",
        "hijos": [
          {
            "subcategoria": "WHISKY 18 AÑOS",
            "marcas": [
              {
                "nombre": "SANTA TERESA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Grant's (18)",
                    "competencia": false
                  }
                ]
              },
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Dewar's 18",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Buchanan's 18",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Old Parr Superior",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Chivas Regal 18",
                    "competencia": true
                  }
                ],
                "nombre": "COMPETENCIA"
              }
            ]
          }
        ],
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "hijos": [
          {
            "subcategoria": "SINGLE MALT",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Glenfiddich (18)",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Glenfiddich (15)",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Glenfiddich (12)",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "nombre": "COMPETENCIA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Balvenie 12 años",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "The Macallan 12 Double Cask",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Green Label",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "26.04",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Jack Daniels",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "JW Black Gold Label",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "The Glenlivet 0,75L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "The Glenlivet 1L",
                    "competencia": true
                  }
                ]
              }
            ]
          }
        ],
        "categoria": "SINGLE MALT",
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "categoria": "LICOR DE WHISKY",
        "hijos": [
          {
            "subcategoria": "LICOR DE WHISKY",
            "marcas": [
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Kings Club",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "5.29",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "2",
                          "Total": "8",
                          "Caras": "4"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Kings Club Litro",
                    "competencia": false
                  }
                ],
                "nombre": "SANTA TERESA"
              },
              {
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Country Club",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "City Club",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Kingdom",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Kelt's",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "President",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Managers 0,70L",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Managers 1L",
                    "competencia": true
                  }
                ],
                "nombre": "COMPETENCIA"
              }
            ]
          }
        ],
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      },
      {
        "hijos": [
          {
            "subcategoria": "GINEBRA PREMIUM",
            "marcas": [
              {
                "nombre": "SANTA TERESA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Hendrick's 0,70L",
                    "competencia": false
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Hendrick's  1L",
                    "competencia": false
                  }
                ]
              },
              {
                "nombre": "COMPETENCIA",
                "productos": [
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": true,
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "34.24",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": {
                          "Profundidad": "3",
                          "Total": "6",
                          "Caras": "2"
                        },
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Tenqueray",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Tenqueray Ten",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Bombay Sapphire",
                    "competencia": true
                  },
                  {
                    "preguntas": [
                      {
                        "tipo": "Simple",
                        "respuesta": "Sin Stock",
                        "pregunta": "¿ESTÁ EL PRODUCTO?"
                      },
                      {
                        "tipo": "Precio",
                        "respuesta": "Sin Stock",
                        "pregunta": "PRECIO"
                      },
                      {
                        "tipo": "Calculado",
                        "respuesta": "Sin Stock",
                        "campos": [
                          "Caras",
                          "Profundidad",
                          "Total"
                        ],
                        "pregunta": "INVENTARIO"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN PAGADA"
                      },
                      {
                        "tipo": "Cantidad",
                        "respuesta": "Sin Stock",
                        "pregunta": "EXHIBICIÓN GANADA"
                      }
                    ],
                    "nombre": "Bulldog",
                    "competencia": true
                  }
                ]
              }
            ]
          }
        ],
        "categoria": "GINEBRA PREMIUM",
        "preguntas": [
          {
            "tipo": "Cantidad",
            "pregunta": "CARAS TOTAL ANAQUEL/SEGMENTO"
          }
        ]
      }
    ],
    "fecha_Creado": "2023-10-23 08:23:45",
    "canal": "TRADICIONAL",
    "region": "CAPITAL",
    "cod_est": "ST047",
    "fotos": [
      {
        "foto": "https://firebasestorage.googleapis.com/v0/b/testapi-16c41.appspot.com/o/files%2Fformularios%2F2023-10%2F6J99yo9U31YKyMwabvXO%2Fimg_0.jpg?alt=media&token=c7fd58da-4326-46c6-9fac-0ed2acc0c858",
        "tag": "Antes"
      },
      {
        "foto": "https://firebasestorage.googleapis.com/v0/b/testapi-16c41.appspot.com/o/files%2Fformularios%2F2023-10%2F6J99yo9U31YKyMwabvXO%2Fimg_1.jpg?alt=media&token=e6be4ddd-1964-42f0-a712-7adc4c680172",
        "tag": "Después"
      }
    ]
  }


  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  // this.onExporting = this.onExporting.bind(this);

  switchSkin(skin);

  useEffect(() => {
    // let fecha2 = moment(new Date())
    // let mes2 = fecha2.month()
    // let inicio2 = moment().year(2023).month(mes2).startOf('month')
    // let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
    // let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
    // let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
    // setStartDate(fecha_newDate2)


    GetDataGridSincronizaciones()

    // Test()

    switchSkin(skin);
  }, [skin, filtersSelect]);

  const SetPeriodoFecha = (type) => {
    switch (type) {
      case 1:
        setStartDate(new Date())
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: new Date(),
          endDate: new Date()
        }))
        break;
      case 2:
        let fecha = moment(new Date())
        let semana = fecha.week()
        let ano = fecha.year()
        let inicio = moment().year(ano).week(semana).startOf('week')
        let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a')
        let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
        let fecha_newDate = new Date(anio, mes - 1, dia, hora % 12 + (ampm.toLowerCase() === "pm" ? 12 : 0), minuto);
        setStartDate(fecha_newDate)
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: fecha_newDate,
          endDate: new Date()
        }))
        break;
      case 3:
        let fecha2 = moment(new Date())
        let mes2 = fecha2.month()
        let ano2 = fecha2.year()
        let inicio2 = moment().year(ano2).month(mes2).startOf('month')
        let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
        let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
        let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
        setStartDate(fecha_newDate2)
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: fecha_newDate2,
          endDate: new Date()
        }))
        break;
    }
    GetDataGridSincronizaciones()
  }

  const GetDataGridSincronizaciones = () => {
    console.log('en el get')
    // setisLoading(true)

    let value = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client

    let info = {
      client_name: value,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD'),
    }

    GridMainService.GetSincronizaciones(info).then(res => {
      let data = res.data
      console.log("🚀 ~ file: StoreNoLlegados.js:82 ~ GridMainService.GetStoreNoLlegados ~ data:", data)

      SetDataGrid(data)
      // setisLoading(false)

    })
  }

  const Test = () => {
    let formulario = {
      header: {},
      details: [],
      photos: []
    }
    console.log(data)
    formulario.header = {
      tasa: data.tasa,

    }
    console.log(data.preguntas)
    data.preguntas.forEach(pregunta => {
      console.log(pregunta)
      console.log(pregunta.hijos)
    })
  }

  function cellRenderViewPhotos(data) {
    console.log(data)
    let valor = parseInt(data.data.total_fotos)

    let icon
    if (valor > 0) {
      icon = <div className="text-center click-icon" onClick={() => { openPopup(data.data.fotos) }}><i className="ri-gallery-line icon-view-photos c-green"></i></div>
    } else {
      icon = <div className="text-center"><i className="ri-camera-off-line icon-view-photos c-red"></i></div>
    }
    return icon
  }

  const ViewPhoto = (data) => {
    console.log(data)

    let info = {
      fomularios: 'FUNDAMENTAL',
      desde: data.fecha,
      hasta: data.fecha,
      regions: data.region,
      usuarios: data.promotor,
      establecimientos: data.establecimiento
    }

    dispatch(SET_DATA_FILTER(info))
    navigate('/apps/archivo-fotografico')

  }

  const openPopup = (fotos) => {
    setShowpictures(fotos)
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
  };

  function onExporting(e) {
    console.log(e)
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet('Main sheet');

    // exportDataGrid({
    //   component: e.component,
    //   worksheet,
    //   autoFilterEnabled: true,
    // }).then(() => {
    //   workbook.xlsx.writeBuffer().then((buffer) => {
    //     saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    //   });
    // });
  }

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <React.Fragment>
        <Header onSkin={setSkin} />
        <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Listado de Sincronizaciones</h4>
            </div>

            <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
              <ReactDatePicker selected={startDate} onChange={(date) => { setStartDate(date); dispatch(SET_START_DATE({ startDate: date })) }} className="form-control" />
              <ReactDatePicker selected={endDate} onChange={(date) => { setEndDate(date); dispatch(SET_END_DATE({ endDate: date })) }} className="form-control" />
              <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => GetDataGridSincronizaciones()}>
                <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
              </Button>
              <Dropdown>
                <Dropdown.Toggle variant="primary">
                  Filtro Fecha
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Header>Rango de Fecha</Dropdown.Header>
                  <Dropdown.Item onClick={(e) => SetPeriodoFecha(1)}>Hoy</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => SetPeriodoFecha(2)}>Ultima Semana</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => SetPeriodoFecha(3)}>Ultimo Mes</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="height-grid-75">
            <DataGrid
              dataSource={dataGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              columnAutoWidth={true}
              showRowLines={false}
              showBorders={true}
              rowAlternationEnabled={true}
              displayMode='full'
              style={{ height: '75vh', width: '100%' }}
            >
              {/* <Selection mode="multiple" /> */}
              <GroupPanel visible={true} />
              <ColumnChooser enabled={true} />
              <Scrolling rowRenderingMode='infinity'></Scrolling>
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} />
              <Export enabled={true} />

              <Column dataField='fecha' caption='Fecha Formulario'  >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField='region' caption='Región'  >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField='establecimiento' caption='Establecimiento'   >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField='promotor' caption='Promotor'  >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField='total_fotos' caption='Total De Fotos'  >
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column caption='Ver Fotos' width={75} cellRender={cellRenderViewPhotos}>
                <HeaderFilter allowSelectAll={true}>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>

          <Popup
            width={"75%"}
            height={"85%"}
            visible={showPopup}
            onHiding={hidePopup}
            showTitle={true}
            title={"Galería de Imágenes"}
            showCloseButton={true}
            fullScreen
          >
            <ScrollView width='100%' height='100%'>
              <Row className="g-3 g-xl-4">
                {
                  showpictures.map((cadaRegistro) => {
                    return (
                      showpictures.length != 0 ? (
                        <Col key={cadaRegistro.foto} xs={3} style={{ height: '45vh' }}>
                          <Card className="card-video-item">
                            <img src={cadaRegistro.foto} className="card-img-top" alt="" style={{ height: '35vh' }} />
                            <Card.Body className="text-center p-3">
                              <Card.Title as="h6" style={{ textAlign: "center", marginBottom: "10px" }}><span>{cadaRegistro.tag}</span></Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      ) : ''
                    )
                  })
                }
              </Row>
            </ScrollView>
          </Popup>

          <Footer />
        </div>
      </React.Fragment>
    </>
  )
}