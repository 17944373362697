import { Carousel, Col, Row, Button, Card, ProgressBar, Nav, FormCheck } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Select from "react-select";
import feather from "feather-icons/dist/feather";
import Prism from "prismjs";
import Loader from '../components/loader/Loader'

//llamada a la api
import * as DinamicQueries from '../services/DinamicsQuery';

// esto hay que instalarlo
import { saveAs } from 'file-saver';
import pptxgen from "pptxgenjs";
import Moment from "moment";
import jsPDF from 'jspdf';
import JSZip, { filter } from 'jszip';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import { selectFiltersGrid } from '../redux/slice/gridSincronizacionesSlice'
import { selectFilters } from '../redux/slice/configSlice'


export default function ArchivoFotografico() {

  const user = useSelector(selectUser)
  const filters_grid = useSelector(selectFiltersGrid)
  const filtersHeader = useSelector(selectFilters)
  const [isLoading, setisLoading] = useState(false)

  const selectOptions = [
    { value: 'ZIP', label: 'ZIP' },
    { value: 'PDF', label: 'PDF' },
  ];

  const [Establecimito, setEstablecimito] = useState([]);
  const [Formularios, setFormularios] = useState([]);
  const [tags, setTags] = useState([]);
  const [Clientes, setClientes] = useState([]);
  const [Regiones, setRegiones] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [cadenas, setCadenas] = useState([]);
  const [ImagesSelected, setImagesSelected] = useState([]);
  const [resgistros, setRegistros] = useState([]);
  const [LoadPanelBoolean, setLoadPanelBoolean] = useState(false);
  const [selectedAll, setselectedAll] = useState(false);
  const [typeDescarga, setTypeDescarga] = useState('')
  const [fechaInicial, setfechaInicial] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );

  const [FechaFinal, setFechaFinal] = useState(new Date());
  const now = new Date();

  const InitialStateFilters = {
    cliente: '',
    formulario: '',
    region: '',
    fechaInicial: new Date(new Date().setDate(new Date().getDate() - 7)),
    fechaFinal: new Date(),
    usuarios: '',
    Establecimientos: '',
    tag: "TODOS",
    cadenas: "TODAS"
  };
  const [filters, setFilters] = useState(InitialStateFilters);
  const [infoSelected, setinfoSelected] = useState(false);

  const [selectedOptionCliente, setSelectedOptionCliente] = useState(null);
  const [selectedOptionForms, setselectedOptionForms] = useState(null);
  const [selectedOptionRegion, setselectedOptionRegion] = useState(null);
  const [selectedOptionEstablecimientos, setselectedOptionEstablecimientos] = useState(null);
  const [selectedOptionUsuarios, setselectedOptionUsuarios] = useState(null);
  const [selectedOptionTags, setselectedOptionTags] = useState(null);
  const [selectedOptionCadenas, setselectedOptionCadenas] = useState(null);

  const [toastConfig, setToastConfig] = React.useState(
    {
      isVisible: false,
      type: "success",
      message: "Busqueda Exitosa",
      displayTime: 2000,
    },
    []
  );

  const options = ["Zip", "PDF"];
  // "Power Point"

  const styleContador = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#506fd9',
    padding: '5px',
    borderRadius: '5px',
    height: "30px"
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // useEffect(() => {
  //   // const user = JSON.parse(localStorage.getItem("userData"));
  //   console.log(user)

  //   // if(filtersHeader){
  //   console.log("🚀 ~ file: ArchivoFotografico.js:117 ~ useEffect ~ filtersHeader:", filtersHeader)

  //   let Establecimientos = [];
  //   let Usuarios = [];

  //   setFilters({ ...filters, cliente: filtersHeader.client, region: filtersHeader.region });
  //   console.log("🚀 ~ file: ArchivoFotografico.js:123 ~ useEffect ~ filters:", filters)

  //   DinamicQueries.getDataWithParameters('getFormsWeb', 'formularios/', { cliente: filtersHeader.client }).then((resp) => {
  //     if (resp?.data?.data != undefined) {
  //       const fomrularios = resp?.data?.data.map(x => {
  //         return { value: x.nombre, label: x.nombre }
  //       })
  //       // setFormularios(resp.data.data);
  //       setFormularios(fomrularios);

  //     }
  //   });

  //   DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: filtersHeader.client, region: filtersHeader.region })
  //     .then(establecimientos => {
  //       Establecimientos = [{
  //         nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
  //       }, ...establecimientos.data]
  //       const establecimientosFinal = Establecimientos.map(x => {
  //         return { value: x.nombre_establecimiento, label: x.nombre_establecimiento }
  //       })
  //       // setEstablecimito(Establecimientos)
  //       setEstablecimito(establecimientosFinal)
  //     })

  //   DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: filtersHeader.client, region: filtersHeader.region })
  //     .then(usuarios => {
  //       Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]
  //       const usuariosFinal = Usuarios.map(x => {
  //         return { value: x.uid, label: x.nombre }
  //       })
  //       // setUsuario(Usuarios)
  //       setUsuario(usuariosFinal)
  //     })
  //   // }

  //   // const clientes = user.cliente.map(x => {
  //   //   return { value: x, label: x }
  //   // })

  //   // const regiones = user.region.map(x => {
  //   //   return { value: x, label: x }
  //   // })
  //   // setClientes(user.cliente);
  //   // setClientes(clientes);
  //   // setRegiones(user.region);
  //   // setRegiones(regiones);
  //   // setFilters({ ...filters, region: filtersHeader.region })
  //   SearchTag()

  //   // if(filters_grid.isFilter == true){

  //   // }

  //   localStorage.removeItem("photosSelected")

  //   Prism.highlightAll();

  //   feather.replace();

  // }, [filtersHeader])

  useEffect(() => {
    setFilters({ ...filters, cliente: filtersHeader.client, region: filtersHeader.region });
    console.log("🚀 ~ file: ArchivoFotografico.js:123 ~ useEffect ~ filters:", filters)

    const fetchData = async () => {
      try {
        const [formsResp, establishmentsResp, usersResp, cadenaResp] = await Promise.all([
          DinamicQueries.getDataWithParameters('getFormsWeb', 'formularios/', { cliente: filtersHeader.client }),
          DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: filtersHeader.client, region: filtersHeader.region }),
          DinamicQueries.getDataWithParameters('getUserClientAndRegionAnalitycs', "usuarios/", { cliente: filtersHeader.client, region: filtersHeader.region }),
          DinamicQueries.getData('getCadenas', 'EstablecimientosNuevos/')
        ]);

        if (formsResp?.data?.data != undefined) {
          const formsOptions = formsResp.data.data.map(x => ({ value: x.nombre, label: x.nombre.toUpperCase() }));
          setFormularios(formsOptions);
        }

        if (establishmentsResp.data) {
          const establishmentsOptions = [{ nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS' }, ...establishmentsResp.data].map(x => ({ value: x.nombre_establecimiento, label: x.nombre_establecimiento }));
          setEstablecimito(establishmentsOptions);
        }

        if (usersResp.data) {
          const usersOptions = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usersResp.data].map(x => ({ value: x.uid, label: x.nombre.toUpperCase() }));
          setUsuario(usersOptions);
        }

        if(cadenaResp.data){
          const CadenasOptions = [{ nombre_cadena: "TODAS"}, ...cadenaResp.data].map(x => ({ value: x.nombre_cadena, label: x.nombre_cadena }));
          setCadenas(CadenasOptions)
        }

        localStorage.removeItem("photosSelected");
        Prism.highlightAll();
        feather.replace();
        SearchTag();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filtersHeader])

  // const 

  const SearchTag = () => {
    DinamicQueries.getData('getTag', 'formularios/').then((resp) => {
      resp.data.data[0].tags.unshift("TODOS")
      const tagsFinal = resp.data.data[0].tags.map(x => {
        return { value: x, label: x }
      })
      setTags(resp.data.data[0].tags);
      setTags(tagsFinal);
    });
  }

  const searchFormulario = (e) => {
    setSelectedOptionCliente(e);
    if (e.value != "") {
      setFilters({ ...filters, cliente: e.value });
      DinamicQueries.getDataWithParameters('getFormsWeb', 'formularios/', { cliente: e.value }).then((resp) => {
        if (resp?.data?.data != undefined) {
          const fomrularios = resp?.data?.data.map(x => {
            return { value: x.nombre, label: x.nombre }
          })
          // setFormularios(resp.data.data);
          setFormularios(fomrularios);

        }
      });
    }
  };

  const clearFilters = () => {
    setFilters(InitialStateFilters);
    localStorage.removeItem("photosSelected")
    setSelectedOptionCliente(null);
    setselectedOptionForms(null)
    setselectedOptionRegion(null)
    setselectedOptionEstablecimientos(null)
    setselectedOptionUsuarios(null)
    setselectedOptionTags(null)
    setStartDate(new Date())
    setEndDate(new Date())
    setRegistros([])
  };

  const serachPictures = () => {
    setisLoading(true)

    const fechaInicialFormat = Moment(startDate).format(
      "YYYY-MM-DD"
    );
    const fechaFinalFormat = Moment(endDate).format(
      "YYYY-MM-DD"
    );

    setRegistros([])
    localStorage.removeItem("photosSelected")
    console.log("🚀 ~ file: ArchivoFotografico.js:191 ~ serachPictures ~ filters_grid:", filters_grid)
    console.log({ cliente: filters.cliente, nombre: filters.formulario, region: filters.region, fechaInicio: fechaInicialFormat, fechaFin: fechaFinalFormat, promotor: filters.usuarios, establecimiento: filters.Establecimientos, cadenas: filters.cadenas })
    DinamicQueries.getDataWithParameters('getImages', 'galeria/', { cliente: filters.cliente, nombre: filters.formulario, region: filters.region, fechaInicio: fechaInicialFormat, fechaFin: fechaFinalFormat, promotor: filters.usuarios, establecimiento: filters.Establecimientos, cadena: filters.cadenas }).then((resp) => {
      let registrosConFotos = [];
      console.log(resp)
      if (resp !== undefined) {
        resp.data.forEach(element => {
          let fotosTemporales = []
          if (element.fotos !== undefined) {
            if (filters.tag !== "TODOS") {
              element.fotos.forEach(x => {
                x.selected = false

                if (x.tag === filters.tag) {
                  fotosTemporales.push(x)
                }
              })
              element.fotos = fotosTemporales
              registrosConFotos.push(element)
            }
            else {
              element.fotos.forEach(x => {
                x.selected = false
              })
              registrosConFotos.push(element)
            }
          }
        });
      }
      registrosConFotos.sort((a, b) => {
        if (a.establecimiento < b.establecimiento) {
          return -1;
        }
        if (a.establecimiento > b.establecimiento) {
          return 1;
        }
        return 0;
      });
      setRegistros(registrosConFotos)
      setisLoading(false)
      setImagesSelected([])
    });
  };

  const searchUsersAndStablishment = (e) => {
    let Establecimientos = [];
    let Usuarios = [];
    // if (e?.element?.innerText === 'Region') {
    //   if (e.value != "") {
    //     setFilters({ ...filters, region: e.value })
    //     DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: filters.cliente, region: e.value })
    //       .then(establecimientos => {
    //         Establecimientos = [{
    //           nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
    //         }, ...establecimientos.data]
    //         setEstablecimito(Establecimientos)
    //       })

    //     DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: filters.cliente, region: e.value })
    //       .then(usuarios => {
    //         console.log("🚀 ~ file: gallery.js ~ line 122 ~ searchUsersAndStablishment ~ usuarios", usuarios)
    //         Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]
    //         setUsuario(Usuarios)
    //       })
    //   }
    // } else if (e.element.innerText === 'Cliente') {
    if (e.value != "") {
      setFilters({ ...filters, cliente: e.value });
      DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: e.value, region: filters.region })
        .then(establecimientos => {
          Establecimientos = [{
            nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
          }, ...establecimientos.data]
          const establecimientosFinal = Establecimientos.map(x => {
            return { value: x.nombre_establecimiento, label: x.nombre_establecimiento }
          })
          // setEstablecimito(Establecimientos)
          setEstablecimito(establecimientosFinal)
        })

      DinamicQueries.getDataWithParameters('getUserClientAndRegionAnalitycs', "usuarios/", { cliente: e.value, region: filters.region })
        .then(usuarios => {
          Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]
          const usuariosFinal = Usuarios.map(x => {
            return { value: x.uid, label: x.nombre }
          })
          // setUsuario(Usuarios)
          setUsuario(usuariosFinal)
        })
    }
    // }
  };

  const searchUsersAndStablishmentRegion = (e) => {
    let Establecimientos = [];
    let Usuarios = [];
    // if (e?.element?.innerText === 'Region') {
    if (e.value != "") {
      setFilters({ ...filters, region: e.value })
      DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: filters.cliente, region: e.value })
        .then(establecimientos => {
          Establecimientos = [{
            nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
          }, ...establecimientos.data]

          const establecimientosFinal = Establecimientos.map(x => {
            return { value: x.nombre_establecimiento, label: x.nombre_establecimiento }
          })
          // setEstablecimito(Establecimientos)
          setEstablecimito(establecimientosFinal)
        })

      DinamicQueries.getDataWithParameters('getUserClientAndRegionAnalitycs', "usuarios/", { cliente: filters.cliente, region: e.value })
        .then(usuarios => {
          Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]


          const usuariosFinal = Usuarios.map(x => {
            return { value: x.uid, label: x.nombre }
          })
          // setUsuario(Usuarios)
          setUsuario(usuariosFinal)
        })
    }
    // } else if (e.element.innerText === 'Cliente') {
    // if (e.value != "") {
    //   setFilters({ ...filters, cliente: e.value });
    //   DinamicQueries.getDataWithParameters('getEstablecimientosFilter', "galeria/", { cliente: e.value, region: filters.region })
    //     .then(establecimientos => {
    //       Establecimientos = [{
    //         nombre_establecimiento: 'TODOS LOS ESTABLECIMIENTOS',
    //       }, ...establecimientos.data]
    //       setEstablecimito(Establecimientos)
    //     })

    //   DinamicQueries.getDataWithParameters('getUserClientAndRegion', "usuarios/", { cliente: e.value, region: filters.region })
    //     .then(usuarios => {
    //       console.log("🚀 ~ file: gallery.js ~ line 122 ~ searchUsersAndStablishment ~ usuarios", usuarios)
    //       Usuarios = [{ nombre: "TODOS LOS USUARIOS", uid: "TODOS LOS USUARIOS" }, ...usuarios.data]
    //       setUsuario(Usuarios)
    //     })
    // }
    // }
  };

  const handleClick = async (url, registro) => {
    const data = await fetch(url.foto);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        // resolve(base64data);
        var a = document.createElement("a"); //Create <a>
        // a.href = "data:image/png;base64," + ImageBase64; //Image Base64 Goes here
        a.href = base64data //Image Base64 Goes here

        let tipo = ""

        var data = base64data.substring(0, 5);

        switch (data.toUpperCase()) {
          case "IVBOR": tipo = "png";
            break
          case "/9J/4": tipo = "jpeg";
            break
          case "JVBER": tipo = "pdf";
            break
          case "UESDB":
            var data2 = base64data.substring(0, 19);
            switch (data2.toUpperCase()) {
              case "UEsDBBQABgAIAAAAIQB": tipo = "xlsx";
                break
              default:
                var data3 = base64data.substring(0, 18);

                switch (data3.toUpperCase()) {
                  case "UESDBBQABGAIAAAAIQ": tipo = "docx";
                    break
                  default: tipo = "zip";
                }
            }
            break
          case "UMFYI": tipo = "rar";
            break
          case "U1PKC": tipo = "txt";
            break
          default:
            break
        }
        // registro.establecimiento +"-"+  url.tag + "-" + url.descripcion
        a.download = registro.establecimiento + "-" + url.tag + "-" + url.descripcion + "." + tipo //File name Here
        a.click(); //Downloaded file
      }
    });
  }

  const savePhoto = (photo) => {
    if (localStorage.getItem('photosSelected') != undefined) {
      let photos = localStorage.getItem('photosSelected').split(',');
      const found = photos.findIndex(element => element === photo.foto);
      if (found >= 0) { // existe, hay que eliminarlo
        photos.splice(found, 1)
      } else {
        photos.push(photo.foto)
      }

      localStorage.setItem('photosSelected', photos.toString())

    } else {
      let data = [photo.foto]
      localStorage.setItem('photosSelected', data.toString())
    }

    let info = localStorage.getItem('photosSelected').split(',')

    let data = localStorage.getItem('photosSelected').split(',').filter(x => x !== '')
    setImagesSelected(data)

    if (info[0] === '' && info.length === 1) {
      setinfoSelected(false)
    } else {
      setinfoSelected(true)
    }
  }

  const DescargarSelectedZip = async () => {
    let photos = localStorage.getItem('photosSelected').split(',');

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          photos.forEach(y => {
            if (y == cadaFoto.foto) {
              images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
            }
          })
        })
      }
    })

    let dataFinal = images.filter(x => x.url !== '')
    const zip = new JSZip();

    let contador = 0
    dataFinal.forEach((url, index) => {
      // Carga la imagen como un blob
      fetch(url.url)
        .then(res => res.blob())
        .then(blob => {
          // Agrega la imagen al archivo ZIP
          // zip.file(`image${index}.jpg`, blob);
          contador++
          zip.file(contador + "- " + `${url.name}.jpg`, blob);
          if (contador === dataFinal.length) {
            // Cuando se hayan agregado todas las imágenes, genera el archivo ZIP
            zip.generateAsync({ type: 'blob' }).then(content => {
              // Descarga el archivo ZIP
              setLoadPanelBoolean(false)
              saveAs(content, 'RegistroFotografico.zip');
            });
          }
        });
    });
  }

  const DescargarAllZip = () => {

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
        })
      }
    })
    const zip = new JSZip();
    let contador = 0
    images.forEach((url, index) => {
      // Carga la imagen como un blob
      fetch(url.url)
        .then(res => res.blob())
        .then(blob => {
          // Agrega la imagen al archivo ZIP
          contador++
          zip.file(contador + "- " + `${url.name}.jpg`, blob);
          if (contador === images.length) {
            // Cuando se hayan agregado todas las imágenes, genera el archivo ZIP
            zip.generateAsync({ type: 'blob' }).then(content => {
              // Descarga el archivo ZIP
              setLoadPanelBoolean(false)
              saveAs(content, 'RegistroFotografico.zip');
            });
          }
        });
    });
  }

  const SetVariable = (e) => {
    setTypeDescarga(e.value)
  }

  const DescargarInfo = (e = null) => {
    // setTypeDescarga(e.value)
    setLoadPanelBoolean(true)
    if (ImagesSelected.length == 0) {
      if (e.value === "ZIP" || typeDescarga === "ZIP") {
        DescargarAllZip()
      } else if (e.value === "Power Point" || typeDescarga === "Power Point") {
        DescargarAllPowerPoint()
      } else if (e.value === "PDF" || typeDescarga === "PDF") {
        DescargarAllPdf()
      }
    } else {
      if (e.value === "ZIP" || typeDescarga === "ZIP") {
        DescargarSelectedZip()
      } else if (e.value === "Power Point" || typeDescarga === "Power Point") {
        DescargarSelectedPowerPoint()
      } else if (e.value === "PDF" || typeDescarga === "PDF") {
        DescargarSelectedPdf()
      }
    }
  }

  const DescargarAllPdf = () => {

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
        })
      }
    })
    const doc = new jsPDF();
    let contador = 0
    images.forEach((url, index) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        // Agrega la imagen al PDF

        contador++
        const width = doc.internal.pageSize.getWidth();
        const height = (img.height * width) / img.width;
        doc.addImage(img, 'JPEG', 0, 20, width, height);
        if (contador === images.length) {
          // Cuando se hayan agregado todas las imágenes, guarda el PDF
          setLoadPanelBoolean(false)
          doc.save('RegistroFotografico.pdf');
        } else {
          // Agrega una nueva página antes de agregar la siguiente imagen
          // doc.addFont(url.descripcion)
          // doc.setTextColor("black")
          // doc.text(url.descripcion, 10, 200);
          doc.text(url.name === "" || url.name === undefined || url.name === null ? "Image" : url.name, 10, 10);

          // Agregar el nuevo texto encima del texto original
          doc.setFontSize(12);
          doc.addPage();
        }
      };
      img.src = url.url;
    });
  }

  const DescargarSelectedPdf = async () => {
    let photos = localStorage.getItem('photosSelected').split(',');



    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          photos.forEach(y => {
            if (y == cadaFoto.foto) {
              images.push({ url: cadaFoto.foto, name: x.establecimiento + "-" + cadaFoto.tag + "-" + cadaFoto.descripcion })
            }
          })
        })
      }
    })


    let dataFinal = images.filter(x => x !== '')

    const doc = new jsPDF();
    let contador = 0
    dataFinal.forEach((url, index) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        // Agrega la imagen al PDF
        const width = doc.internal.pageSize.getWidth();
        const height = (img.height * width) / img.width;
        doc.text(url.name === "" || url.name === undefined || url.name === null ? "Image" : url.name, 10, 10);
        doc.setFontSize(12);
        doc.addImage(img, 'JPEG', 0, 20, width, height);
        contador++
        if (contador === images.length) {
          setLoadPanelBoolean(false)
          doc.save('RegistroFotografico.pdf');
        } else {
          // doc.text(url.name === "" || url.name === undefined || url.name === null ? "Image" : url.name, 10, 10);
          // doc.setFontSize(12);
          doc.addPage();
        }
      };
      img.src = url.url;
    });
  }

  const DescargarAllPowerPoint = () => {

    let images = []
    resgistros.forEach(x => {
      if (x.fotos.length > 0) {
        x.fotos.forEach(cadaFoto => {
          images.push(cadaFoto.foto)
        })
      }
    })
    // console.log(images)
    const pptx = new pptxgen();

    images.forEach((image) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = image;
      img.onload = () => {
        // Agrega una diapositiva a la presentación
        const slide = pptx.addSlide();

        // Agrega la imagen a la diapositiva
        slide.addImage({ data: img.src, x: 1, y: 2, w: 5, h: 3 });

        // Descarga la presentación de PowerPoint una vez que se hayan agregado todas las imágenes
        if (pptx.slides.length === images.length - 1) {
          setLoadPanelBoolean(false)
          pptx.writeFile('images.pptx');
        }
      };
    });
  }

  const DescargarSelectedPowerPoint = async () => {
    let photos = localStorage.getItem('photosSelected').split(',');
    // photos.forEach(async x => {
    //     if (x != '') {

    let dataFinal = photos.filter(x => x !== '')

    const pptx = new pptxgen();

    dataFinal.forEach((image) => {
      // Carga la imagen como un objeto Image de HTML
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = image;
      img.onload = () => {
        // Agrega una diapositiva a la presentación
        const slide = pptx.addSlide();

        // Agrega la imagen a la diapositiva
        slide.addImage({ data: img.src, x: 1, y: 2, w: 5, h: 3 });

        // Descarga la presentación de PowerPoint una vez que se hayan agregado todas las imágenes
        if (pptx.slides.length === dataFinal.length) {
          setLoadPanelBoolean(false)
          pptx.writeFile('images.pptx');
        }
      };
    });
  }

  const selectAll = () => {
    const newData = [...resgistros];
    newData.forEach(element => {
      if (element.fotos !== undefined) {
        element.fotos.forEach(x => {
          // if (x.foto == fotos.foto){
          x.selected = true
          // }
        })
      }
    });
    setRegistros(newData)
    setselectedAll(true)
  }

  const DeselectAll = () => {
    const newData = [...resgistros];
    newData.forEach(element => {
      if (element.fotos !== undefined) {
        element.fotos.forEach(x => {
          // if (x.foto == fotos.foto){
          x.selected = false
          // }
        })
      }
    });
    setRegistros(newData)
    setselectedAll(false)
  }

  return (
    <React.Fragment>
      {/* {isLoading && <Loader />} */}
      <Header />
      <div className="main main-app p-3 p-lg-4 p-xxl-5">
        <div className="main-label-group mb-3">
          <h2>Archivo Fotografico</h2>
          {/* <Link to="">See All</Link> */}
        </div>

        {/* <div style={{ display: "flex", width: "45%" }}>


        </div> */}

        <div className="row-wrapper mb-4 mb-xxl-5">
          <Row className="g-3 g-xl-4">
            {/* <Col>
              <span>Clientes</span>
              <Select options={Clientes} isSearchable={true} isClearable value={selectedOptionCliente}
                onChange={(e) => { searchFormulario(e); searchUsersAndStablishment(e) }} />
            </Col> */}
            <Col>
              <span>Formularios</span>
              <Select options={Formularios} isSearchable={true} isClearable value={selectedOptionForms}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    formulario: e.value,
                  })
                  setselectedOptionForms(e)
                }
                }
              />
            </Col>
            <Col>
              <span>Desde</span>
              {/* <Select options={selectOptions} isSearchable={true} isClearable /> */}
              <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
            </Col>
            <Col>
              <span>Hasta</span>
              {/* <Select options={selectOptions} isSearchable={true} isClearable /> */}
              <ReactDatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" />
            </Col>

          </Row>


          <Row className="g-3 g-xl-4 mt-2">
            {/* <Col>
              <span>Region</span>
              <Select options={Regiones} isSearchable={true} isClearable value={selectedOptionRegion}
                onChange={(e) => { searchUsersAndStablishmentRegion(e); setselectedOptionRegion(e) }} />
            </Col> */}
            <Col>
              <span>Usuarios</span>
              <Select options={usuario} isSearchable={true} isClearable value={selectedOptionUsuarios}
                disabled={filters.region !== '' && filters.cliente !== '' ? false : true}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    usuarios: e.value,
                  })
                  setselectedOptionUsuarios(e)
                }}
              />
            </Col>
            <Col>
              <span>Establecimientos</span>
              <Select options={Establecimito} isSearchable={true} isClearable value={selectedOptionEstablecimientos}
                disabled={filters.region !== '' && filters.cliente !== '' ? false : true}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    Establecimientos: e.value,
                  })
                  setselectedOptionEstablecimientos(e)
                }
                } />
            </Col>
            <Col>
              <span>Etiquetas</span>
              <Select options={tags} isSearchable={true} isClearable value={selectedOptionTags}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    tag: e.value,
                  })

                  setselectedOptionTags(e)
                }
                } />
            </Col>
            <Col>
              <span>Cadenas</span>
              <Select options={cadenas} isSearchable={true} isClearable value={selectedOptionCadenas}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    cadenas: e.value,
                  })

                  setselectedOptionCadenas(e)
                }
                } />
            </Col>
          </Row>

          <Row className="g-3 g-xl-4 mb-4" style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={2}>
            <Button className="btn btn-secondary mt-4 mr-2" style={{ width: "100%" }} onClick={clearFilters}>Limpiar Filtros</Button>
            {/* <Button className="btn btn-light mt-4 mr-2" style={{ width: "100%" }} onClick={() => DescargarInfo()}>Descargar</Button> */}
          </Col>
          <Col xs={2}>
            <Select options={selectOptions} isSearchable={true} isClearable disabled={resgistros.length == 0} className="mt-4"
              onChange={(e) => {
                SetVariable(e)
              }} />
          </Col>
          <Col xs={2}>
            {selectedAll == true ? (
              <Button className="btn bg-color mt-4 mr-2" style={{ width: "100%" }} onClick={DeselectAll} disabled={resgistros.length == 0}>Deseleccionar</Button>
            ) : (
              <Button className="btn bg-color mt-4 mr-2" style={{ width: "100%" }} onClick={selectAll} disabled={resgistros.length == 0}>Seleccionar todos</Button>
            )}
          </Col>
          <Col xs={2}>
            <Button className="btn btn-primary bg-color mt-4 mr-2" style={{ width: "100%" }} onClick={DescargarInfo}>Descargar</Button>
          </Col>
          <Col xs={2}>
            <Button className="btn btn-primary bg-color mt-4 mr-2" style={{ width: "100%" }} onClick={serachPictures}>Buscar</Button>
          </Col>
        </Row>

          <div className="col-md-12 mt-4">
            {ImagesSelected.length === 0 ? (
              resgistros.length > 0 && (
                <div style={styleContador}>Se descargaran todas las imágenes</div>
              )
            ) : (
              <div style={styleContador} >Se descargaran {ImagesSelected.length} imágenes</div>
            )}
          </div>

          <br></br>
          {/* <div className="row"> */}
          <Row className="g-3 g-xl-4">
            {
              resgistros.map((cadaRegistro) => {
                return (
                  cadaRegistro.length != 0 ? (
                    cadaRegistro.fotos.map((fotos) => {
                      return (
                        <Col key={fotos.foto} xs={3} style={{height: '65vh'}}>
                          <Card className="card-video-item">
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="p-2" >


                              {/* <div> */}
                              {/* <Row className="g-3 g-xl-4">
                              <Col> */}
                              <div >
                                <input type="checkbox" checked={fotos.selected} onChange={(e) => {
                                  console.log(e)
                                  const newData = [...resgistros];
                                  newData.forEach(element => {
                                    if (element.fotos !== undefined) {
                                      element.fotos.forEach(x => {
                                        if (x.foto == fotos.foto) {
                                          x.selected = e.value
                                        }
                                      })
                                    }
                                  });
                                  setRegistros(newData)
                                  savePhoto(fotos)

                                }

                                } />
                              </div>

                              <div style={{ cursor: "pointer"}}
                                onClick={() => {
                                  handleClick(fotos, cadaRegistro)
                                }}>

                                {/* <Button className="btn btn-light mt-4 mr-2" style={{ width: "100%" }} onClick={() => {
                                  handleClick(fotos, cadaRegistro)
                                }}> */}
                                <i data-feather="download-cloud"></i>
                                {/* <span style={{ display: "none" }}>prueba</span> */}

                                {/* </Button> */}
                              </div>


                            </div>
                            <img src={fotos.foto} className="card-img-top" alt="" style={{height: '35vh'}}/>
                            <Card.Body className="text-center p-3">
                              <Card.Title as="h4" style={{textAlign:"center", marginBottom:"10px"}}>{cadaRegistro.establecimiento}</Card.Title>
                              <Card.Title as="h6" style={{textAlign:"center", marginBottom:"10px"}}><span>{cadaRegistro.fecha_Sincronizado}</span></Card.Title>
                              <Card.Title as="h6" style={{textAlign:"center", marginBottom:"10px"}}><span>{fotos.descripcion}</span></Card.Title>
                              <Card.Title as="h6" style={{textAlign:"center", marginBottom:"10px"}}><span>{fotos.tag}</span></Card.Title>
                              <Card.Title as="h6" style={{textAlign:"center", marginBottom:"10px"}}><span>Coordenadas:</span></Card.Title>
                              <Card.Title as="h6" style={{textAlign:"center", marginBottom:"10px"}}><span>{cadaRegistro.coordenadas}</span></Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  ) : ''
                )
              })
            }
            {/* </div> */}
          </Row>


        </div>


      </div>
    </React.Fragment>
  );
}