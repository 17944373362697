import React, { useEffect } from 'react';

export const GlobalClose = () => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            localStorage.clear()
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return null;
}
