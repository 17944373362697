import React, { useCallback, useRef, useState, useEffect } from 'react';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import Button2 from 'devextreme-react/button';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import { selectFiltersGridDP } from '../redux/slice/gridDetallePrecioSlice'
import { selectFilters } from '../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import DataGrid, { GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging, Export, Selection, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo, Toolbar, Item, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import { saveAs } from "file-saver";
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import * as GridMainService from '../services/grid_main_dashboard'

import Select from "react-select";
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from '../redux/slice/dateSlice';
import { REMOVE_DATA_FILTER_DP } from '../redux/slice/gridDetallePrecioSlice';

export default function GridDetallePrecios() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataGridRef = useRef(null);

  const userData = useSelector(selectUser)
  const filters = useSelector(selectFiltersGridDP)
  const filtersSelect = useSelector(selectFilters)
  const globalDate = useSelector(selectDate);
  const [dataGrid, SetDataGrid] = useState([])
  const [filtersGrid, SetFiltersGrid] = useState([])
  const [startDate, setStartDate] = useState(new Date(globalDate.startDate));
  const [endDate, setEndDate] = useState(new Date(globalDate.endDate));

  const [expandAll, setExpandAll] = useState(true);
  const [groupColumn, setGroupColumn] = useState('SKU');

  const groupingValues = [{
    value: 'SKU',
    text: 'Agrupar por SKU',
  }, {
    value: 'Establecimiento',
    text: 'Agrupar por Establecimiento',
  }];

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {

    // console.clear()

    console.log(filters)
    console.log("🚀 ~ GridDetallePrecios ~ globalDate:", globalDate)

    if (filters.isFilter) {
      let filters_ = ['producto', '=', filters.sku]

      setStartDate(new Date(filters.fecha_ini))
      setEndDate(new Date(filters.fecha_fin))
      SetFiltersGrid(filters_)

      GetDataGridDetallePrecios(filters.fecha_ini, filters.fecha_fin)
  
      dispatch(REMOVE_DATA_FILTER_DP())

    } else {
      GetDataGridDetallePrecios()
    }

    switchSkin(skin);
  }, [skin, filtersSelect]);

  const SetPeriodoFecha = (type) => {
    switch (type) {
      case 1:
        setStartDate(new Date())
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: new Date(),
          endDate: new Date()
        }))
        break;
      case 2:
        let fecha = moment(new Date())
        let semana = fecha.week()
        let ano = fecha.year()
        let inicio = moment().year(ano).week(semana).startOf('week')
        let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a')
        let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
        let fecha_newDate = new Date(anio, mes - 1, dia, hora % 12 + (ampm.toLowerCase() === "pm" ? 12 : 0), minuto);
        setStartDate(fecha_newDate)
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: fecha_newDate,
          endDate: new Date()
        }))
        break;
      case 3:
        let fecha2 = moment(new Date())
        let mes2 = fecha2.month()
        let ano2 = fecha2.year()
        let inicio2 = moment().year(ano2).month(mes2).startOf('month')
        let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
        let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
        let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
        setStartDate(fecha_newDate2)
        setEndDate(new Date())
        dispatch(SET_DATE({
          startDate: fecha_newDate2,
          endDate: new Date()
        }))
        break;
    }
    GetDataGridDetallePrecios()
  }

  const GetDataGridDetallePrecios = (fecha_ini = null, fecha_fin = null) => {

    let value = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: value,
      region_name: region,
      fecha_ini: fecha_ini != null ? fecha_ini : moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: fecha_fin != null ? fecha_fin : moment(endDate).format('YYYY/MM/DD'),
    }
    console.log("🚀 ~ GetDataGridDetallePrecios ~ info:", info)

    GridMainService.GetDetallePrecios(info).then(res => {
      let data = res.data
      console.log("🚀 ~ file: GetDetallePrecios.js:160 ~ GridMainService.GetDetallePrecios ~ data:", data)
      SetDataGrid(data)
    })
  }

  // function cellRenderViewPhotos(data) {
  //   // console.log(data)
  //   let valor = parseInt(data.data.total_fotos)
  //   let icon
  //   if(valor > 0){
  //     icon = <div className="text-center click-icon" onClick={(e) => ViewPhoto(data.data)}><i className="ri-gallery-line icon-view-photos c-green"></i></div>
  //   } else {
  //     icon = <div className="text-center"><i className="ri-camera-off-line icon-view-photos c-red"></i></div>
  //   }
  //   return icon
  // }

  const toggleGroupColumn = useCallback((e) => {
    const newGrouping = e.value;

    dataGridRef.current.instance.clearGrouping();
    dataGridRef.current.instance.columnOption(newGrouping, 'groupIndex', 0);

    setGroupColumn(newGrouping);
  }, []);

  const toggleExpandAll = useCallback(() => {
    setExpandAll(!expandAll);
  }, [expandAll]);

  const onExportPDF = async () => {
    const dataGrid = dataGridRef.current.instance;

    const dataSource = dataGrid.option("dataSource");

    const pdf = new jsPDF();

    const headers = dataGrid.getVisibleColumns().map(column => column.caption);

    const columnStyles = {
      0: { cellWidth: 35 },
      1: { cellWidth: 22 },
      2: { cellWidth: 20 },
      3: { cellWidth: 35 },
      4: { cellWidth: 40 },
      5: { cellWidth: 17 },
    };

    pdf.autoTable({
      head: [headers],
      body: [],
      columnStyles: columnStyles,
    });

    dataSource.forEach(item => {
      pdf.autoTable({
        body: [dataGrid.getVisibleColumns().map(column => item[column.dataField] || '')],
        columnStyles: columnStyles,
      });
    });

    pdf.save('Detalle_Precios.pdf');
  };

  const onExportXLS = async () => {
    const dataGrid = dataGridRef.current.instance;

    const dataSource = dataGrid.option('dataSource');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('DataGrid Data');

    const headers = dataGrid.getVisibleColumns().map(column => column.caption);
    worksheet.addRow(headers);

    dataSource.forEach(item => {
      const rowData = dataGrid.getVisibleColumns().map(column => item[column.dataField] || '');
      worksheet.addRow(rowData);
    });

    const blob = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([blob], { type: 'application/octet-stream' }), 'Detalle_Precios.xlsx');
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Detalle Precios</h4>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <ReactDatePicker selected={startDate} onChange={(date) => { setStartDate(date); dispatch(SET_START_DATE({ startDate: date })) }} className="form-control calenderInput" />
            <ReactDatePicker selected={endDate} onChange={(date) => { setEndDate(date); dispatch(SET_END_DATE({ endDate: date })) }} className="form-control calenderInput" />
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => GetDataGridDetallePrecios()}>
              <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="primary">
                Filtro Fecha
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Header>Rango de Fecha</Dropdown.Header>
                <Dropdown.Item onClick={(e) => SetPeriodoFecha(1)}>Hoy</Dropdown.Item>
                <Dropdown.Item onClick={(e) => SetPeriodoFecha(2)}>Ultima Semana</Dropdown.Item>
                <Dropdown.Item onClick={(e) => SetPeriodoFecha(3)}>Ultimo Mes</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="height-grid-75">
          <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={dataGrid}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            filterValue={filtersGrid}
            showRowLines={false}
            showBorders={true}
            rowAlternationEnabled={true}
            displayMode='full'
            style={{ height: '75vh', width: '100%' }}
          >
            {/* <Selection mode="multiple" /> */}
            <Grouping autoExpandAll={expandAll} />
            <GroupPanel visible={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <Scrolling rowRenderingMode='infinity'></Scrolling>
            <ColumnFixing enabled={true} />
            <HeaderFilter visible={true} />
            <Export enabled={true} />

            <Toolbar>
              <Item location="after">
                <SelectBox
                  width="225"
                  items={groupingValues}
                  displayExpr="text"
                  valueExpr="value"
                  value={groupColumn}
                  onValueChanged={toggleGroupColumn} />
              </Item>
              <Item location="after">
                <Button2
                  text={expandAll ? 'Collapsar' : 'Expandir'}
                  width='136'
                  onClick={toggleExpandAll} />
              </Item>
              <Item location="after">
                <Button2
                  icon='refresh'
                  onClick={()=>{GetDataGridDetallePrecios()}} />
              </Item>
              <Item location="after">
                <Button2
                  icon='pdffile'
                  onClick={onExportPDF}
                />
              </Item>
              <Item location="after">
                <Button2
                  icon='xlsfile'
                  onClick={onExportXLS}
                />
              </Item>
              <Item name="columnChooserButton" />
            </Toolbar>

            <Column dataField='establecimiento' caption='Establecimiento' groupIndex={0}>
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField='categoria' caption='Categoria'  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField='producto' caption='SKU'>
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column allowGrouping={false} dataField='precio_dolar' caption='Precio en Dolares' format="currency" dataType="number">
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column allowGrouping={false} dataField='precio_bs' caption='Precio en Bolivares'  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField='total_productos' caption='Veces Levantado'  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField='tasa' caption='Tasa'  >
              <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            {/* <Summary>
              <GroupItem
                column="total_productos"
                summaryType="sum"
                showInGroupFooter={false}
                alignByColumn={true}
              />
            </Summary> */}
            {/* <Column caption= 'Ver Fotos' width={75}  cellRender={cellRenderViewPhotos}>
            <HeaderFilter allowSelectAll={true}>
                <Search enabled={true} />
              </HeaderFilter>
            </Column> */}
          </DataGrid>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}