
const dashboardMenu = [
  {
    "label": "Main",
    // "link": "/dashboard/mainDashboard",
    "icon": "ri-bar-chart-2-line",
    "submenu": [
      {
        "label": "Dashboard",
        "link": "/dashboard/mainDashboard",
        // "icon": "ri-bar-chart-2-line"
      },
      // {
      //   "label": "Grids",
      //   "link": "/Grids/MainGrids",
      //   // "icon": "ri-bar-chart-2-line"
      // },
      {
        "label": "Estable. No Alcanzados",
        "link": "/Grids/EstablecimientosNoAlcanzados",
        // "icon": "ri-bar-chart-2-line"
      },
      {
        "label": "SKU no Alcanzados",
        "link": "/Grids/SkuNoAlcanzados",
        // "icon": "ri-bar-chart-2-line"
      },
      {
        "label": "SKU Menos Trafico",
        "link": "/Grids/SkuMenosTrafico",
        // "icon": "ri-bar-chart-2-line"
      }
    ]
  },
  {
    "label": "Encuestas",
    // "link": "/dashboard/mainDashboard",
    "icon": "ri-user-voice-fill",
    "submenu": [
      {
        "label": "Dashboard",
        "link": "/dashboard/quizDashboard",
        // "icon": "ri-bar-chart-2-line"
      },
    ]
  },
  {
    "label": "Inventario",
    "icon": "ri-shopping-cart-fill",
    "submenu": [
      {
        "label": "Inv. por Categoria",
        "link": "/Grids/InventarioCategoria",
        // "icon": "ri-bar-chart-2-line"
      },
      {
        "label": "Inv. por Establecimiento",
        "link": "/Grids/SKU",
        // "icon": "ri-bar-chart-2-line"
      }
    ]
  },
  {
    "label": "Precios",
    "icon": "ri-exchange-dollar-line",
    "submenu": [
      {
        "label": "Detalle",
        "link": "/Grids/DetallePrecios"
      },
      {
        "label": "Tasa",
        "link": "/Grids/Tasa"
      },
      {
        "label": "Comparación entre SKU",
        "link": "/comparacionSKU"
      },
      {
        "label": "Comparación de Precios",
        "link": "/comparacionPrecios"
      },
    ]
  },
  {
    "label": "Operaciones",
    "icon": "ri-team-fill",
    "submenu": [
      {
        "label": "Detalle de Plantilla",
        "link": "/Grids/DetallePlantilla"
      },
      // {
      //   "label": "Detalle de Asistencia",
      //   "link": "/Grids/DetalleActivacion"
      // },
      {
        "label": "Sincronizaciones",
        "link": "/Grids/Sincronizaciones",
        // "icon": "ri-bar-chart-2-line"
      },
      {
        "label": "Activaciones",
        "link": "/Grids/ActivacionesMensuales",
        // "icon": "ri-bar-chart-2-line"
      },
      {
        "label": "Incidencias",
        "link": "/Grids/Incidencias",
        // "icon": "ri-bar-chart-2-line"
      }
    ]
  }
];

const applicationsMenu = [
  {
    "label": "Archivo Fotografico",
    "link": "/apps/archivo-fotografico",
    "icon": "ri-gallery-line"
  },
  {
    "label": "Seguimiento",
    "link": "/apps/seguimiento",
    "icon": "ri-map-pin-2-line"
  },
];

const pagesMenu = [
  {
    "label": "User Pages",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "User Profile",
        "link": "/pages/profile"
      },
      {
        "label": "People & Groups",
        "link": "/pages/people"
      },
      {
        "label": "Activity Log",
        "link": "/pages/activity"
      },
      {
        "label": "Events",
        "link": "/pages/events"
      },
      {
        "label": "Settings",
        "link": "/pages/settings"
      }
    ]
  },
  {
    "id": 27,
    "label": "Authentication",
    "icon": "ri-lock-2-line",
    "submenu": [
      {
        "label": "Sign In Basic",
        "link": "/pages/signin"
      },
      {
        "label": "Sign In Cover",
        "link": "/pages/signin2"
      },
      {
        "label": "Sign Up Basic",
        "link": "/pages/signup"
      },
      {
        "label": "Sign Up Cover",
        "link": "/pages/signup2"
      },
      {
        "label": "Verify Account",
        "link": "/pages/verify"
      },
      {
        "label": "Forgot Password",
        "link": "/pages/forgot"
      },
      {
        "label": "Lock Screen",
        "link": "/pages/lock"
      }
    ]
  },
  {
    "label": "Error Pages",
    "icon": "ri-error-warning-line",
    "submenu": [
      {
        "label": "Page Not Found",
        "link": "/pages/error-404"
      },
      {
        "label": "Internal Server Error",
        "link": "/pages/error-500"
      },
      {
        "label": "Service Unavailable",
        "link": "/pages/error-503"
      },
      {
        "label": "Forbidden",
        "link": "/pages/error-505"
      }
    ]
  },
  {
    "label": "Other Pages",
    "icon": "ri-file-text-line",
    "submenu": [
      {
        "label": "Pricing",
        "link": "/pages/pricing"
      },
      {
        "label": "FAQ",
        "link": "/pages/faq"
      }
    ]
  }
];

const uiElementsMenu = [
  {
    "label": "Getting Started",
    "icon": "ri-pencil-ruler-2-line",
    "submenu": [
      {
        "label": "Grid System",
        "link": "/docs/layout/grid"
      },
      {
        "label": "Columns",
        "link": "/docs/layout/columns"
      },
      {
        "label": "Gutters",
        "link": "/docs/layout/gutters"
      }
    ]
  },
  {
    "label": "Components",
    "icon": "ri-suitcase-line",
    "submenu": [
      {
        "label": "Accordion",
        "link": "/docs/com/accordions"
      },
      {
        "label": "Alerts",
        "link": "/docs/com/alerts"
      },
      {
        "label": "Avatars",
        "link": "/docs/com/avatars"
      },
      {
        "label": "Badge",
        "link": "/docs/com/badge"
      },
      {
        "label": "Breadcrumbs",
        "link": "/docs/com/breadcrumbs"
      },
      {
        "label": "Buttons",
        "link": "/docs/com/buttons"
      },
      {
        "label": "Cards",
        "link": "/docs/com/cards"
      },
      {
        "label": "Carousel",
        "link": "/docs/com/carousel"
      },
      {
        "label": "Dropdown",
        "link": "/docs/com/dropdown"
      },
      {
        "label": "Images",
        "link": "/docs/com/images"
      },
      {
        "label": "List Group",
        "link": "/docs/com/listgroup"
      },
      {
        "label": "Markers",
        "link": "/docs/com/markers"
      },
      {
        "label": "Modal",
        "link": "/docs/com/modal"
      },
      {
        "label": "Nav & Tabs",
        "link": "/docs/com/navtabs"
      },
      {
        "label": "Offcanvas",
        "link": "/docs/com/offcanvas"
      },
      {
        "label": "Pagination",
        "link": "/docs/com/pagination"
      },
      {
        "label": "Placeholders",
        "link": "/docs/com/placeholders"
      },
      {
        "label": "Popovers",
        "link": "/docs/com/popovers"
      },
      {
        "label": "Progress",
        "link": "/docs/com/progress"
      },
      {
        "label": "Spinners",
        "link": "/docs/com/spinners"
      },
      {
        "label": "Toast",
        "link": "/docs/com/toasts"
      },
      {
        "label": "Tooltips",
        "link": "/docs/com/tooltips"
      },
      {
        "label": "Tables",
        "link": "/docs/com/tables"
      }
    ]
  },
  {
    "label": "Forms",
    "icon": "ri-list-check-2",
    "submenu": [
      {
        "label": "Text Elements",
        "link": "/docs/form/elements"
      },
      {
        "label": "Selects",
        "link": "/docs/form/selects"
      },
      {
        "label": "Checks & Radios",
        "link": "/docs/form/checksradios"
      },
      {
        "label": "Range",
        "link": "/docs/form/range"
      },
      {
        "label": "Pickers",
        "link": "/docs/form/pickers"
      },
      {
        "label": "Layouts",
        "link": "/docs/form/layouts"
      }
    ]
  },
  {
    "label": "Charts & Graphs",
    "icon": "ri-bar-chart-2-line",
    "submenu": [
      {
        "label": "ApexCharts",
        "link": "/docs/chart/apex"
      },
      {
        "label": "Chartjs",
        "link": "/docs/chart/chartjs"
      }
    ]
  },
  {
    "label": "Maps & Icons",
    "icon": "ri-stack-line",
    "submenu": [
      {
        "label": "Leaflet Maps",
        "link": "/docs/map/leaflet"
      },
      {
        "label": "Vector Maps",
        "link": "/docs/map/vector"
      },
      {
        "label": "Remixicon",
        "link": "/docs/icon/remix"
      },
      {
        "label": "Feathericons",
        "link": "/docs/icon/feather"
      }
    ]
  },
  {
    "label": "Utilities",
    "icon": "ri-briefcase-4-line",
    "submenu": [
      {
        "label": "Background",
        "link": "/docs/util/background"
      },
      {
        "label": "Border",
        "link": "/docs/util/border"
      },
      {
        "label": "Colors",
        "link": "/docs/util/colors"
      },
      {
        "label": "Divider",
        "link": "/docs/util/divider"
      },
      {
        "label": "Flex",
        "link": "/docs/util/flex"
      },
      {
        "label": "Sizing",
        "link": "/docs/util/sizing"
      },
      {
        "label": "Spacing",
        "link": "/docs/util/spacing"
      },
      {
        "label": "Opacity",
        "link": "/docs/util/opacity"
      },
      {
        "label": "Position",
        "link": "/docs/util/position"
      },
      {
        "label": "Typography",
        "link": "/docs/util/typography"
      },
      {
        "label": "Shadows",
        "link": "/docs/util/shadows"
      },
      {
        "label": "Extras",
        "link": "/docs/util/extras"
      }
    ]
  }
];

export { dashboardMenu, applicationsMenu };