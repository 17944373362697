import React, { useCallback, useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import ReactDatePicker from "react-datepicker";
import Button2 from 'devextreme-react/button';
import moment from "moment";
import { selectFilters } from '../redux/slice/configSlice'
import { DataGrid, GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Export, Item, Toolbar, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import { saveAs } from "file-saver";
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import * as GridMainService from '../services/grid_main_dashboard'

import { SelectBox } from "devextreme-react";
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from "../redux/slice/dateSlice";

export default function GridTasaDetalle () {
    const globalDate = useSelector(selectDate);

    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [dataGrid, setDataGrid] = useState([]);
    const [skin, setSkin] = useState(currentSkin);
    const [expandAll, setExpandAll] = useState(true);
    const [startDate, setStartDate] = useState(new Date(globalDate.startDate));
    const [endDate, setEndDate] = useState(new Date(globalDate.endDate));
    const [groupColumn, setGroupColumn] = useState('NAME');

    const dispatch = useDispatch();
    const dataGridRef = useRef(null);
    const userData = useSelector(selectUser);
    const filtersSelect = useSelector(selectFilters);
    const groupingValues = [{
        value: 'NOMBRE_COMPLETO',
        text: 'Agrupar por Promotor',
    },
    {
        value: 'NAME',
        text: 'Agrupar por Establecimiento',
    }];

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    const SetPeriodoFecha = (type) => {
        switch (type) {
            case 1:
                setStartDate(new Date())
                setEndDate(new Date())
                dispatch(SET_DATE({
                    startDate: new Date(),
                    endDate: new Date()
                }))
                break;
            case 2:
                let fecha = moment(new Date())
                let semana = fecha.week()
                let ano = fecha.year()
                let inicio = moment().year(ano).week(semana).startOf('week')
                let fecha_inicio = moment(inicio).format('DD/MM/YYYY h:mm:ss a')
                let [dia, mes, anio, hora, minuto, ampm] = fecha_inicio.split(/[\s/:]+/);
                let fecha_newDate = new Date(anio, mes - 1, dia, hora % 12 + (ampm.toLowerCase() === "pm" ? 12 : 0), minuto);
                setStartDate(fecha_newDate)
                setEndDate(new Date())
                dispatch(SET_DATE({
                    startDate: fecha_newDate,
                    endDate: new Date()
                }))
                break;
            case 3:
                let fecha2 = moment(new Date())
                let mes2 = fecha2.month()
                let ano2 = fecha2.year()
                let inicio2 = moment().year(ano2).month(mes2).startOf('month')
                let fecha_inicio2 = moment(inicio2).format('DD/MM/YYYY h:mm:ss a')
                let [dia2, mes3, anio2, hora2, minuto2, ampm2] = fecha_inicio2.split(/[\s/:]+/);
                let fecha_newDate2 = new Date(anio2, mes3 - 1, dia2, hora2 % 12 + (ampm2.toLowerCase() === "pm" ? 12 : 0), minuto2);
                setStartDate(fecha_newDate2)
                setEndDate(new Date())
                dispatch(SET_DATE({
                    startDate: fecha_newDate2,
                    endDate: new Date()
                }))
                break;
        }
        GetDataGridTasa();
    }

    const GetDataGridTasa = () => {
        let client = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client;
        let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region;

        let info = {
            client_name: client,
            region_name: region,
            fecha_ini: moment(startDate).format('YYYY/MM/DD'),
            fecha_fin: moment(endDate).format('YYYY/MM/DD')
        }
        console.log("🚀 ~ GetDataGridTasa ~ info:", info)

        GridMainService.GetDetalleTasa(info).then(res => {
            console.log("🚀 ~ GridMainService.GetDetalleActivacion ~ res:", res)
            let data = res.data
            data.forEach(element => {
                element.NOMBRE_COMPLETO = element.FIRST_NAME.toUpperCase() + ' ' + element.LAST_NAME.toUpperCase();
                element.NAME = element.NAME.toUpperCase();
                element.DATE_FORM = moment(element.DATE_FORM).format('DD-MM-YYYY');
            });
            setDataGrid(data)
        })
    };

    const toggleExpandAll = useCallback(() => {
        setExpandAll(!expandAll);
    }, [expandAll]);

    const toggleGroupColumn = useCallback((e) => {
        const newGrouping = e.value;

        dataGridRef.current.instance.clearGrouping();
        dataGridRef.current.instance.columnOption(newGrouping, 'groupIndex', 0);

        setGroupColumn(newGrouping);
    }, []);

    const onExportPDF = async () => {
        const dataGrid = dataGridRef.current.instance;

        const dataSource = dataGrid.option("dataSource");

        const pdf = new jsPDF();

        const headers = dataGrid.getVisibleColumns().map(column => column.caption);

        const columnStyles = {
            0: { cellWidth: 35 },
            1: { cellWidth: 40 },
            2: { cellWidth: 20 },
        };

        pdf.autoTable({
            head: [headers],
            body: [],
            columnStyles: columnStyles,
        });

        dataSource.forEach(item => {
            pdf.autoTable({
                body: [dataGrid.getVisibleColumns().map(column => item[column.dataField] || '')],
                columnStyles: columnStyles,
            });
        });

        pdf.save('Detalle_de_Tasa.pdf');
    };

    const onExportXLS = async () => {
        const dataGrid = dataGridRef.current.instance;

        const dataSource = dataGrid.option('dataSource');

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('DataGrid Data');

        const headers = dataGrid.getVisibleColumns().map(column => column.caption);
        worksheet.addRow(headers);

        dataSource.forEach(item => {
            const rowData = dataGrid.getVisibleColumns().map(column => item[column.dataField] || '');
            worksheet.addRow(rowData);
        });

        const blob = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([blob], { type: 'application/octet-stream' }), 'Detalle_de_Tasa.xlsx');
    };

    useEffect(() => {
        GetDataGridTasa();
        switchSkin(skin);
    }, [skin, filtersSelect]);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Detalle de Tasa</h4>
                    </div>

                    <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                        <ReactDatePicker selected={startDate} onChange={(date) => { setStartDate(date); dispatch(SET_START_DATE({ startDate: date })) }} className="form-control" />
                        <ReactDatePicker selected={endDate} onChange={(date) => { setEndDate(date); dispatch(SET_END_DATE({ endDate: date })) }} className="form-control" />
                        <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => GetDataGridTasa()}>
                            <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary">
                                Filtro Fecha
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Header>Rango de Fecha</Dropdown.Header>
                                <Dropdown.Item onClick={(e) => SetPeriodoFecha(1)}>Hoy</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => SetPeriodoFecha(2)}>Ultima Semana</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => SetPeriodoFecha(3)}>Ultimo Mes</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                <div className="height-grid-75">
                    <DataGrid
                        ref={dataGridRef}
                        dataSource={dataGrid}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        columnAutoWidth={true}
                        showRowLines={false}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        displayMode='full'
                        style={{ height: '75vh', width: '100%' }}
                    >
                        <Grouping autoExpandAll={expandAll} />
                        <GroupPanel visible={true} />
                        <FilterRow visible={true} />
                        <FilterPanel visible={true} />
                        <ColumnChooser enabled={true} />
                        <Scrolling rowRenderingMode='infinity'></Scrolling>
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} />
                        <Export enabled={true} />

                        <Toolbar>
                            <Item location="after">
                                <SelectBox
                                    width="225"
                                    items={groupingValues}
                                    displayExpr="text"
                                    valueExpr="value"
                                    value={groupColumn}
                                    onValueChanged={toggleGroupColumn} />
                            </Item>
                            <Item location="after">
                                <Button2
                                    text={expandAll ? 'Collapsar' : 'Expandir'}
                                    width='136'
                                    onClick={toggleExpandAll} />
                            </Item>
                            <Item location="after">
                                <Button2
                                    icon='refresh'
                                    onClick={GetDataGridTasa} />
                            </Item>
                            <Item location="after">
                                <Button2
                                    icon='pdffile'
                                    onClick={onExportPDF}
                                />
                            </Item>
                            <Item location="after">
                                <Button2
                                    icon='xlsfile'
                                    onClick={onExportXLS}
                                />
                            </Item>
                            <Item name="columnChooserButton" />
                        </Toolbar>

                        <Column dataField='NOMBRE_COMPLETO' caption='Nombre Promotor' width={550} >
                            <HeaderFilter allowSelectAll={true}>
                                <Search enabled={true} />
                            </HeaderFilter>
                        </Column>
                        <Column dataField='NAME' caption='Establecimiento' width={500} groupIndex={0} >
                            <HeaderFilter allowSelectAll={true}>
                                <Search enabled={true} />
                            </HeaderFilter>
                        </Column>

                        <Column dataField='DATE_FORM' caption='Fecha' width={350} textAlign='Center'>
                            <HeaderFilter allowSelectAll={true}>
                                <Search enabled={true} />
                            </HeaderFilter>
                        </Column>

                        <Column dataField='RATE' width={150} caption='Tasa' alignment="right">
                            <HeaderFilter allowSelectAll={true}>
                                <Search enabled={true} />
                            </HeaderFilter>
                        </Column>
                    </DataGrid>
                </div>
                <Footer />
            </div>
        </React.Fragment>
    )
}
