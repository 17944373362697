import settings from "./enviroment";
import Axios from "axios";

const urlAnalytics = settings.API_URL + "analytics/";

export const GetListSKU = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "extras/getListSKU", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};

export const CompareSKU = (info) => {
  let req = {params: info}
  const promise = Axios.post(urlAnalytics + "extras/CompareSKU", req);
  const dataPromise = promise.then((response) => { return response; });
  return dataPromise;
};



  